@charset "UTF-8";
.icon, .form-group .styled-select:after, .form-group.calendar:after, .captcha #refresh-captcha > .icon-refresh {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-circle-phone:before {
  content: ""; }

.icon-envelope-open:before {
  content: ""; }

.icon-pin-linear:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-down--swallow-tail:before {
  content: ""; }

.icon-arrow-down-circle:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-left--swallow-tail:before {
  content: ""; }

.icon-arrow-left-circle:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-right--swallow-tail:before {
  content: ""; }

.icon-arrow-right-circle:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-arrow-up--swallow-tail:before {
  content: ""; }

.icon-arrow-up-circle:before {
  content: ""; }

.icon-whatsapp:before {
  content: ""; }

.icon-boat:before {
  content: ""; }

.icon-padlock:before {
  content: ""; }

.icon-eye:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-check:before {
  content: ""; }

.icon-bathroom:before {
  content: ""; }

.icon-breakfast:before {
  content: ""; }

.icon-circle-phone1:before {
  content: ""; }

.icon-hairdryer:before {
  content: ""; }

.icon-plane:before {
  content: ""; }

.icon-sea-view:before {
  content: ""; }

.icon-terrace:before {
  content: ""; }

.icon-tv-sat:before {
  content: ""; }

.icon-location:before {
  content: ""; }

.icon-arrow-down-scroll:before {
  content: ""; }

.icon-air-conditioning:before {
  content: ""; }

.icon-arrow-down1:before {
  content: ""; }

.icon-arrow-left1:before {
  content: ""; }

.icon-arrow-right1:before {
  content: ""; }

.icon-arrow-up1:before {
  content: ""; }

.icon-beach-umbrella:before {
  content: ""; }

.icon-bell-o:before {
  content: ""; }

.icon-bell-ring:before {
  content: ""; }

.icon-bell-ring-linear:before {
  content: ""; }

.icon-breakfast-milk:before {
  content: ""; }

.icon-business-meeting:before {
  content: ""; }

.icon-call-linear:before {
  content: ""; }

.icon-conference-hall:before {
  content: ""; }

.icon-cross:before {
  content: ""; }

.icon-defibrillator:before {
  content: ""; }

.icon-couple:before {
  content: ""; }

.icon-drink:before {
  content: ""; }

.icon-fax:before {
  content: ""; }

.icon-call:before {
  content: ""; }

.icon-group:before {
  content: ""; }

.icon-envelope:before {
  content: ""; }

.icon-calendar-checked:before {
  content: ""; }

.icon-facebook-logo-button:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-familiar-group-of-three:before {
  content: ""; }

.icon-kid:before {
  content: ""; }

.icon-envelope-linear:before {
  content: ""; }

.icon-calendar:before {
  content: ""; }

.icon-label:before {
  content: ""; }

.icon-family:before {
  content: ""; }

.icon-father-with-his-daughter-and-baby:before {
  content: ""; }

.icon-front-car:before {
  content: ""; }

.icon-link-external:before {
  content: ""; }

.icon-garage:before {
  content: ""; }

.icon-ironing-board:before {
  content: ""; }

.icon-laundry:before {
  content: ""; }

.icon-left-quote:before {
  content: ""; }

.icon-man:before {
  content: ""; }

.icon-minivan:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-money-box:before {
  content: ""; }

.icon-money-box-linear:before {
  content: ""; }

.icon-phone:before {
  content: ""; }

.icon-mother-with-son:before {
  content: ""; }

.icon-parking:before {
  content: ""; }

.icon-google-plus:before {
  content: ""; }

.icon-pin:before {
  content: ""; }

.icon-plus-square:before {
  content: ""; }

.icon-printer:before {
  content: ""; }

.icon-reception:before {
  content: ""; }

.icon-refresh:before {
  content: ""; }

.icon-right-quote:before {
  content: ""; }

.icon-restaurant:before {
  content: ""; }

.icon-sea:before {
  content: ""; }

.icon-road-map:before {
  content: ""; }

.icon-send:before {
  content: ""; }

.icon-technology:before {
  content: ""; }

.icon-telephone:before {
  content: ""; }

.icon-three-persons:before {
  content: ""; }

.icon-cradle:before {
  content: ""; }

.icon-towel:before {
  content: ""; }

.icon-train:before {
  content: ""; }

.icon-tripadvisor:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-user-circle:before {
  content: ""; }

.icon-uniE952:before {
  content: ""; }

.icon-uniE953:before {
  content: ""; }

.icon-uniE954:before {
  content: ""; }

.icon-wheelchair:before {
  content: ""; }

.icon-wifi:before {
  content: ""; }

.icon-legal-hammer:before {
  content: ""; }

.icon-courtesy-kit:before {
  content: ""; }

.icon-fridge:before {
  content: ""; }

.icon-safe-lock:before {
  content: ""; }

.icon-shower:before {
  content: ""; }

.icon-sundecks:before {
  content: ""; }

.icon-cleanings:before {
  content: ""; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important; }
  .label {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: 'QuattrocentoSans-Regular', sans-serif;
  line-height: 1.6;
  color: #706558;
  background-color: #F4F4F0; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

a {
  color: #5C84B0;
  text-decoration: none; }
  a:hover, a:focus {
    color: #3f5e81;
    text-decoration: none; }
  a:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }

figure {
  margin: 0; }

img {
  vertical-align: middle; }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.img-rounded {
  border-radius: 6px; }

.img-thumbnail {
  padding: 4px;
  line-height: 1.6;
  background-color: #F4F4F0;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto; }

.img-circle {
  border-radius: 50%; }

hr {
  margin-top: 27px;
  margin-bottom: 27px;
  border: 0;
  border-top: 1px solid #eeeeee; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto; }

[role="button"] {
  cursor: pointer; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'QuattrocentoSans-Regular', sans-serif;
  font-weight: 400;
  line-height: 1.1;
  color: #003F7F; }
  h1 small,
  h1 .small, h2 small,
  h2 .small, h3 small,
  h3 .small, h4 small,
  h4 .small, h5 small,
  h5 .small, h6 small,
  h6 .small,
  .h1 small,
  .h1 .small, .h2 small,
  .h2 .small, .h3 small,
  .h3 .small, .h4 small,
  .h4 .small, .h5 small,
  .h5 .small, .h6 small,
  .h6 .small {
    font-weight: normal;
    line-height: 1;
    color: #777777; }

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: 27px;
  margin-bottom: 13.5px; }
  h1 small,
  h1 .small, .h1 small,
  .h1 .small,
  h2 small,
  h2 .small, .h2 small,
  .h2 .small,
  h3 small,
  h3 .small, .h3 small,
  .h3 .small {
    font-size: 65%; }

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 13.5px;
  margin-bottom: 13.5px; }
  h4 small,
  h4 .small, .h4 small,
  .h4 .small,
  h5 small,
  h5 .small, .h5 small,
  .h5 .small,
  h6 small,
  h6 .small, .h6 small,
  .h6 .small {
    font-size: 75%; }

h1, .h1 {
  font-size: 54px; }

h2, .h2 {
  font-size: 40.4489777376px; }

h3, .h3 {
  font-size: 30.2985148151px; }

h4, .h4 {
  font-size: 22.6952583562px; }

h5, .h5 {
  font-size: 30.2985148151px; }

h6, .h6 {
  font-size: 12.7339374359px; }

p {
  margin: 0 0 13.5px; }

.lead {
  margin-bottom: 27px;
  font-size: 19px;
  font-weight: 300;
  line-height: 1.4; }
  @media (min-width: 768px) {
    .lead {
      font-size: 25.5px; } }

small,
.small {
  font-size: 88%; }

mark,
.mark {
  background-color: #fcf8e3;
  padding: .2em; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.text-nowrap {
  white-space: nowrap; }

.text-lowercase {
  text-transform: lowercase; }

.text-uppercase, .initialism {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.text-muted {
  color: #777777; }

.text-primary {
  color: #5C84B0; }

a.text-primary:hover,
a.text-primary:focus {
  color: #476b92; }

.text-success {
  color: #3c763d; }

a.text-success:hover,
a.text-success:focus {
  color: #2b542c; }

.text-info {
  color: #31708f; }

a.text-info:hover,
a.text-info:focus {
  color: #245269; }

.text-warning {
  color: #8a6d3b; }

a.text-warning:hover,
a.text-warning:focus {
  color: #66512c; }

.text-danger {
  color: #a94442; }

a.text-danger:hover,
a.text-danger:focus {
  color: #843534; }

.bg-primary {
  color: #fff; }

.bg-primary {
  background-color: #5C84B0; }

a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #476b92; }

.bg-success {
  background-color: #dff0d8; }

a.bg-success:hover,
a.bg-success:focus {
  background-color: #c1e2b3; }

.bg-info {
  background-color: #d9edf7; }

a.bg-info:hover,
a.bg-info:focus {
  background-color: #afd9ee; }

.bg-warning {
  background-color: #fcf8e3; }

a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #f7ecb5; }

.bg-danger {
  background-color: #f2dede; }

a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #e4b9b9; }

ul,
ol {
  margin-top: 0;
  margin-bottom: 13.5px; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px; }
  .list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px; }

dl {
  margin-top: 0;
  margin-bottom: 27px; }

dt,
dd {
  line-height: 1.6; }

dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

.dl-horizontal dd:before, .dl-horizontal dd:after {
  content: " ";
  display: table; }

.dl-horizontal dd:after {
  clear: both; }

@supports (display: flow-root) {
  .dl-horizontal dd {
    display: flow-root; }
    .dl-horizontal dd:before, .dl-horizontal dd:after {
      content: none; } }

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .dl-horizontal dd {
    margin-left: 180px; } }

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777777; }

.initialism {
  font-size: 90%; }

blockquote {
  padding: 13.5px 27px;
  margin: 0 0 27px;
  font-size: 21.25px;
  border-left: 5px solid #eeeeee; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer,
  blockquote small,
  blockquote .small {
    display: block;
    font-size: 80%;
    line-height: 1.6;
    color: #777777; }
    blockquote footer:before,
    blockquote small:before,
    blockquote .small:before {
      content: '\2014 \00A0'; }

.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right; }
  .blockquote-reverse footer:before,
  .blockquote-reverse small:before,
  .blockquote-reverse .small:before,
  blockquote.pull-right footer:before,
  blockquote.pull-right small:before,
  blockquote.pull-right .small:before {
    content: ''; }
  .blockquote-reverse footer:after,
  .blockquote-reverse small:after,
  .blockquote-reverse .small:after,
  blockquote.pull-right footer:after,
  blockquote.pull-right small:after,
  blockquote.pull-right .small:after {
    content: '\00A0 \2014'; }

address {
  margin-bottom: 27px;
  font-style: normal;
  line-height: 1.6; }

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace; }

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px; }

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25); }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold;
    box-shadow: none; }

pre {
  display: block;
  padding: 13px;
  margin: 0 0 13.5px;
  font-size: 16px;
  line-height: 1.6;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px; }
  pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

table {
  background-color: transparent; }

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left; }

th {
  text-align: left; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 27px; }
  .table > thead > tr > th,
  .table > thead > tr > td,
  .table > tbody > tr > th,
  .table > tbody > tr > td,
  .table > tfoot > tr > th,
  .table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.6;
    vertical-align: top;
    border-top: 1px solid #ddd; }
  .table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd; }
  .table > caption + thead > tr:first-child > th,
  .table > caption + thead > tr:first-child > td,
  .table > colgroup + thead > tr:first-child > th,
  .table > colgroup + thead > tr:first-child > td,
  .table > thead:first-child > tr:first-child > th,
  .table > thead:first-child > tr:first-child > td {
    border-top: 0; }
  .table > tbody + tbody {
    border-top: 2px solid #ddd; }
  .table .table {
    background-color: #F4F4F0; }

.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 5px; }

.table-bordered {
  border: 1px solid #ddd; }
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td,
  .table-bordered > tbody > tr > th,
  .table-bordered > tbody > tr > td,
  .table-bordered > tfoot > tr > th,
  .table-bordered > tfoot > tr > td {
    border: 1px solid #ddd; }
  .table-bordered > thead > tr > th,
  .table-bordered > thead > tr > td {
    border-bottom-width: 2px; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9; }

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5; }

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column; }

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell; }

.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5; }

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8; }

.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #dff0d8; }

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6; }

.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #d9edf7; }

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3; }

.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3; }

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc; }

.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #f2dede; }

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc; }

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; }
  @media screen and (max-width: 767px) {
    .table-responsive {
      width: 100%;
      margin-bottom: 20.25px;
      overflow-y: hidden;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #ddd; }
      .table-responsive > .table {
        margin-bottom: 0; }
        .table-responsive > .table > thead > tr > th,
        .table-responsive > .table > thead > tr > td,
        .table-responsive > .table > tbody > tr > th,
        .table-responsive > .table > tbody > tr > td,
        .table-responsive > .table > tfoot > tr > th,
        .table-responsive > .table > tfoot > tr > td {
          white-space: nowrap; }
      .table-responsive > .table-bordered {
        border: 0; }
        .table-responsive > .table-bordered > thead > tr > th:first-child,
        .table-responsive > .table-bordered > thead > tr > td:first-child,
        .table-responsive > .table-bordered > tbody > tr > th:first-child,
        .table-responsive > .table-bordered > tbody > tr > td:first-child,
        .table-responsive > .table-bordered > tfoot > tr > th:first-child,
        .table-responsive > .table-bordered > tfoot > tr > td:first-child {
          border-left: 0; }
        .table-responsive > .table-bordered > thead > tr > th:last-child,
        .table-responsive > .table-bordered > thead > tr > td:last-child,
        .table-responsive > .table-bordered > tbody > tr > th:last-child,
        .table-responsive > .table-bordered > tbody > tr > td:last-child,
        .table-responsive > .table-bordered > tfoot > tr > th:last-child,
        .table-responsive > .table-bordered > tfoot > tr > td:last-child {
          border-right: 0; }
        .table-responsive > .table-bordered > tbody > tr:last-child > th,
        .table-responsive > .table-bordered > tbody > tr:last-child > td,
        .table-responsive > .table-bordered > tfoot > tr:last-child > th,
        .table-responsive > .table-bordered > tfoot > tr:last-child > td {
          border-bottom: 0; } }

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0; }

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 27px;
  font-size: 25.5px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5; }

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold; }

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal; }

input[type="file"] {
  display: block; }

input[type="range"] {
  display: block;
  width: 100%; }

select[multiple],
select[size] {
  height: auto; }

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px; }

output {
  display: block;
  padding-top: 7px;
  font-size: 17px;
  line-height: 1.6;
  color: #555555; }

.form-control {
  display: block;
  width: 100%;
  height: 41px;
  padding: 6px 12px;
  font-size: 17px;
  line-height: 1.6;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e6e5e5;
  border-radius: 0;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .form-control:focus {
    border-color: #5C84B0;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(92, 132, 176, 0.6); }
  .form-control::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #999; }
  .form-control::-webkit-input-placeholder {
    color: #999; }
  .form-control[disabled], .form-control[readonly],
  fieldset[disabled] .form-control {
    background-color: #eeeeee;
    opacity: 1; }
  .form-control[disabled],
  fieldset[disabled] .form-control {
    cursor: not-allowed; }

textarea.form-control {
  height: auto; }

input[type="search"] {
  -webkit-appearance: none; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 41px; }
  input[type="date"].input-sm, .input-group-sm > input.form-control[type="date"],
  .input-group-sm > input.input-group-addon[type="date"],
  .input-group-sm > .input-group-btn > input.btn[type="date"],
  .input-group-sm input[type="date"],
  input[type="time"].input-sm,
  .input-group-sm > input.form-control[type="time"],
  .input-group-sm > input.input-group-addon[type="time"],
  .input-group-sm > .input-group-btn > input.btn[type="time"],
  .input-group-sm
  input[type="time"],
  input[type="datetime-local"].input-sm,
  .input-group-sm > input.form-control[type="datetime-local"],
  .input-group-sm > input.input-group-addon[type="datetime-local"],
  .input-group-sm > .input-group-btn > input.btn[type="datetime-local"],
  .input-group-sm
  input[type="datetime-local"],
  input[type="month"].input-sm,
  .input-group-sm > input.form-control[type="month"],
  .input-group-sm > input.input-group-addon[type="month"],
  .input-group-sm > .input-group-btn > input.btn[type="month"],
  .input-group-sm
  input[type="month"] {
    line-height: 34px; }
  input[type="date"].input-lg, .input-group-lg > input.form-control[type="date"],
  .input-group-lg > input.input-group-addon[type="date"],
  .input-group-lg > .input-group-btn > input.btn[type="date"],
  .input-group-lg input[type="date"],
  input[type="time"].input-lg,
  .input-group-lg > input.form-control[type="time"],
  .input-group-lg > input.input-group-addon[type="time"],
  .input-group-lg > .input-group-btn > input.btn[type="time"],
  .input-group-lg
  input[type="time"],
  input[type="datetime-local"].input-lg,
  .input-group-lg > input.form-control[type="datetime-local"],
  .input-group-lg > input.input-group-addon[type="datetime-local"],
  .input-group-lg > .input-group-btn > input.btn[type="datetime-local"],
  .input-group-lg
  input[type="datetime-local"],
  input[type="month"].input-lg,
  .input-group-lg > input.form-control[type="month"],
  .input-group-lg > input.input-group-addon[type="month"],
  .input-group-lg > .input-group-btn > input.btn[type="month"],
  .input-group-lg
  input[type="month"] {
    line-height: 52px; } }

.form-group {
  margin-bottom: 30px; }

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px; }
  .radio label,
  .checkbox label {
    min-height: 27px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer; }

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9; }

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px; }

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer; }

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px; }

input[type="radio"][disabled], input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled]
input[type="checkbox"] {
  cursor: not-allowed; }

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled]
.checkbox-inline {
  cursor: not-allowed; }

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled]
.checkbox label {
  cursor: not-allowed; }

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 44px; }
  .form-control-static.input-lg, .input-group-lg > .form-control-static.form-control,
  .input-group-lg > .form-control-static.input-group-addon,
  .input-group-lg > .input-group-btn > .form-control-static.btn, .form-control-static.input-sm, .input-group-sm > .form-control-static.form-control,
  .input-group-sm > .form-control-static.input-group-addon,
  .input-group-sm > .input-group-btn > .form-control-static.btn {
    padding-left: 0;
    padding-right: 0; }

.input-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 34px;
  padding: 5px 10px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 0; }

select.input-sm, .input-group-sm > select.form-control,
.input-group-sm > select.input-group-addon,
.input-group-sm > .input-group-btn > select.btn {
  height: 34px;
  line-height: 34px; }

textarea.input-sm, .input-group-sm > textarea.form-control,
.input-group-sm > textarea.input-group-addon,
.input-group-sm > .input-group-btn > textarea.btn,
select[multiple].input-sm,
.input-group-sm > select.form-control[multiple],
.input-group-sm > select.input-group-addon[multiple],
.input-group-sm > .input-group-btn > select.btn[multiple] {
  height: auto; }

.form-group-sm .form-control {
  height: 34px;
  padding: 5px 10px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 0; }

.form-group-sm select.form-control {
  height: 34px;
  line-height: 34px; }

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto; }

.form-group-sm .form-control-static {
  height: 34px;
  min-height: 42px;
  padding: 6px 10px;
  font-size: 15px;
  line-height: 1.5; }

.input-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 52px;
  padding: 10px 16px;
  font-size: 22px;
  line-height: 1.3333333;
  border-radius: 0; }

select.input-lg, .input-group-lg > select.form-control,
.input-group-lg > select.input-group-addon,
.input-group-lg > .input-group-btn > select.btn {
  height: 52px;
  line-height: 52px; }

textarea.input-lg, .input-group-lg > textarea.form-control,
.input-group-lg > textarea.input-group-addon,
.input-group-lg > .input-group-btn > textarea.btn,
select[multiple].input-lg,
.input-group-lg > select.form-control[multiple],
.input-group-lg > select.input-group-addon[multiple],
.input-group-lg > .input-group-btn > select.btn[multiple] {
  height: auto; }

.form-group-lg .form-control {
  height: 52px;
  padding: 10px 16px;
  font-size: 22px;
  line-height: 1.3333333;
  border-radius: 0; }

.form-group-lg select.form-control {
  height: 52px;
  line-height: 52px; }

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto; }

.form-group-lg .form-control-static {
  height: 52px;
  min-height: 49px;
  padding: 11px 16px;
  font-size: 22px;
  line-height: 1.3333333; }

.has-feedback {
  position: relative; }
  .has-feedback .form-control {
    padding-right: 51.25px; }

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 41px;
  height: 41px;
  line-height: 41px;
  text-align: center;
  pointer-events: none; }

.input-lg + .form-control-feedback, .input-group-lg > .form-control + .form-control-feedback, .input-group-lg > .input-group-addon + .form-control-feedback, .input-group-lg > .input-group-btn > .btn + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 52px;
  height: 52px;
  line-height: 52px; }

.input-sm + .form-control-feedback, .input-group-sm > .form-control + .form-control-feedback, .input-group-sm > .input-group-addon + .form-control-feedback, .input-group-sm > .input-group-btn > .btn + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 34px;
  height: 34px;
  line-height: 34px; }

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d; }

.has-success .form-control {
  border-color: #3c763d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-success .form-control:focus {
    border-color: #2b542c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168; }

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8; }

.has-success .form-control-feedback {
  color: #3c763d; }

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b; }

.has-warning .form-control {
  border-color: #8a6d3b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-warning .form-control:focus {
    border-color: #66512c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b; }

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3; }

.has-warning .form-control-feedback {
  color: #8a6d3b; }

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442; }

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
  .has-error .form-control:focus {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483; }

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede; }

.has-error .form-control-feedback {
  color: #a94442; }

.has-feedback label ~ .form-control-feedback {
  top: 32px; }

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0; }

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #afa599; }

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-static {
    display: inline-block; }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle; }
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .form-inline .input-group .form-control {
      width: auto; }
  .form-inline .input-group > .form-control {
    width: 100%; }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle; }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle; }
    .form-inline .radio label,
    .form-inline .checkbox label {
      padding-left: 0; }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0; }
  .form-inline .has-feedback .form-control-feedback {
    top: 0; } }

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px; }

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 34px; }

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px; }
  .form-horizontal .form-group:before, .form-horizontal .form-group:after {
    content: " ";
    display: table; }
  .form-horizontal .form-group:after {
    clear: both; }
  @supports (display: flow-root) {
    .form-horizontal .form-group {
      display: flow-root; }
      .form-horizontal .form-group:before, .form-horizontal .form-group:after {
        content: none; } }

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px; } }

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px; }

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 14.333333px;
    font-size: 22px; } }

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 15px; } }

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 17px;
  line-height: 1.6;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .btn:hover, .btn:focus, .btn.focus {
    color: #333;
    text-decoration: none; }
  .btn:active, .btn.active {
    outline: 0;
    background-image: none; }
  .btn.disabled, .btn[disabled],
  fieldset[disabled] .btn {
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc; }
  .btn-default:focus, .btn-default.focus {
    color: #333;
    background-color: #e6e5e5;
    border-color: #8c8c8c; }
  .btn-default:hover {
    color: #333;
    background-color: #e6e5e5;
    border-color: #adadad; }
  .btn-default:active, .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    color: #333;
    background-color: #e6e5e5;
    border-color: #adadad; }
    .btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus,
    .open > .btn-default.dropdown-toggle:hover,
    .open > .btn-default.dropdown-toggle:focus,
    .open > .btn-default.dropdown-toggle.focus {
      color: #333;
      background-color: #d4d4d4;
      border-color: #8c8c8c; }
  .btn-default:active, .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    background-image: none; }
  .btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, .btn-default[disabled]:active, .btn-default[disabled].active,
  fieldset[disabled] .btn-default,
  fieldset[disabled] .btn-default:hover,
  fieldset[disabled] .btn-default:focus,
  fieldset[disabled] .btn-default.focus,
  fieldset[disabled] .btn-default:active,
  fieldset[disabled] .btn-default.active {
    background-color: #fff;
    border-color: #ccc; }
  .btn-default .badge {
    color: #fff;
    background-color: #333; }

.btn-primary {
  color: #fff;
  background-color: #5C84B0;
  border-color: #4f77a3; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #476b92;
    border-color: #26394d; }
  .btn-primary:hover {
    color: #fff;
    background-color: #476b92;
    border-color: #3b597a; }
  .btn-primary:active, .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #476b92;
    border-color: #3b597a; }
    .btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus,
    .open > .btn-primary.dropdown-toggle:hover,
    .open > .btn-primary.dropdown-toggle:focus,
    .open > .btn-primary.dropdown-toggle.focus {
      color: #fff;
      background-color: #3b597a;
      border-color: #26394d; }
  .btn-primary:active, .btn-primary.active,
  .open > .btn-primary.dropdown-toggle {
    background-image: none; }
  .btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, .btn-primary[disabled]:active, .btn-primary[disabled].active,
  fieldset[disabled] .btn-primary,
  fieldset[disabled] .btn-primary:hover,
  fieldset[disabled] .btn-primary:focus,
  fieldset[disabled] .btn-primary.focus,
  fieldset[disabled] .btn-primary:active,
  fieldset[disabled] .btn-primary.active {
    background-color: #5C84B0;
    border-color: #4f77a3; }
  .btn-primary .badge {
    color: #5C84B0;
    background-color: #fff; }

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #449d44;
    border-color: #255625; }
  .btn-success:hover {
    color: #fff;
    background-color: #449d44;
    border-color: #398439; }
  .btn-success:active, .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #449d44;
    border-color: #398439; }
    .btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus,
    .open > .btn-success.dropdown-toggle:hover,
    .open > .btn-success.dropdown-toggle:focus,
    .open > .btn-success.dropdown-toggle.focus {
      color: #fff;
      background-color: #398439;
      border-color: #255625; }
  .btn-success:active, .btn-success.active,
  .open > .btn-success.dropdown-toggle {
    background-image: none; }
  .btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, .btn-success[disabled]:active, .btn-success[disabled].active,
  fieldset[disabled] .btn-success,
  fieldset[disabled] .btn-success:hover,
  fieldset[disabled] .btn-success:focus,
  fieldset[disabled] .btn-success.focus,
  fieldset[disabled] .btn-success:active,
  fieldset[disabled] .btn-success.active {
    background-color: #5cb85c;
    border-color: #4cae4c; }
  .btn-success .badge {
    color: #5cb85c;
    background-color: #fff; }

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #31b0d5;
    border-color: #1b6d85; }
  .btn-info:hover {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc; }
  .btn-info:active, .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #31b0d5;
    border-color: #269abc; }
    .btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus,
    .open > .btn-info.dropdown-toggle:hover,
    .open > .btn-info.dropdown-toggle:focus,
    .open > .btn-info.dropdown-toggle.focus {
      color: #fff;
      background-color: #269abc;
      border-color: #1b6d85; }
  .btn-info:active, .btn-info.active,
  .open > .btn-info.dropdown-toggle {
    background-image: none; }
  .btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, .btn-info[disabled]:active, .btn-info[disabled].active,
  fieldset[disabled] .btn-info,
  fieldset[disabled] .btn-info:hover,
  fieldset[disabled] .btn-info:focus,
  fieldset[disabled] .btn-info.focus,
  fieldset[disabled] .btn-info:active,
  fieldset[disabled] .btn-info.active {
    background-color: #5bc0de;
    border-color: #46b8da; }
  .btn-info .badge {
    color: #5bc0de;
    background-color: #fff; }

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #ec971f;
    border-color: #985f0d; }
  .btn-warning:hover {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512; }
  .btn-warning:active, .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ec971f;
    border-color: #d58512; }
    .btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus,
    .open > .btn-warning.dropdown-toggle:hover,
    .open > .btn-warning.dropdown-toggle:focus,
    .open > .btn-warning.dropdown-toggle.focus {
      color: #fff;
      background-color: #d58512;
      border-color: #985f0d; }
  .btn-warning:active, .btn-warning.active,
  .open > .btn-warning.dropdown-toggle {
    background-image: none; }
  .btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, .btn-warning[disabled]:active, .btn-warning[disabled].active,
  fieldset[disabled] .btn-warning,
  fieldset[disabled] .btn-warning:hover,
  fieldset[disabled] .btn-warning:focus,
  fieldset[disabled] .btn-warning.focus,
  fieldset[disabled] .btn-warning:active,
  fieldset[disabled] .btn-warning.active {
    background-color: #f0ad4e;
    border-color: #eea236; }
  .btn-warning .badge {
    color: #f0ad4e;
    background-color: #fff; }

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c9302c;
    border-color: #761c19; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925; }
  .btn-danger:active, .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c9302c;
    border-color: #ac2925; }
    .btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus,
    .open > .btn-danger.dropdown-toggle:hover,
    .open > .btn-danger.dropdown-toggle:focus,
    .open > .btn-danger.dropdown-toggle.focus {
      color: #fff;
      background-color: #ac2925;
      border-color: #761c19; }
  .btn-danger:active, .btn-danger.active,
  .open > .btn-danger.dropdown-toggle {
    background-image: none; }
  .btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, .btn-danger[disabled]:active, .btn-danger[disabled].active,
  fieldset[disabled] .btn-danger,
  fieldset[disabled] .btn-danger:hover,
  fieldset[disabled] .btn-danger:focus,
  fieldset[disabled] .btn-danger.focus,
  fieldset[disabled] .btn-danger:active,
  fieldset[disabled] .btn-danger.active {
    background-color: #d9534f;
    border-color: #d43f3a; }
  .btn-danger .badge {
    color: #d9534f;
    background-color: #fff; }

.btn-link {
  color: #5C84B0;
  font-weight: normal;
  border-radius: 0; }
  .btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled],
  fieldset[disabled] .btn-link {
    background-color: transparent;
    box-shadow: none; }
  .btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
    border-color: transparent; }
  .btn-link:hover, .btn-link:focus {
    color: #3f5e81;
    text-decoration: none;
    background-color: transparent; }
  .btn-link[disabled]:hover, .btn-link[disabled]:focus,
  fieldset[disabled] .btn-link:hover,
  fieldset[disabled] .btn-link:focus {
    color: #c7c7c7;
    text-decoration: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 22px;
  line-height: 1.3333333;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 0; }

.btn-xs, .btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: 5px; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0.01;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  .fade.in {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.in {
    display: block; }

tr.collapse.in {
  display: table-row; }

tbody.collapse.in {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease; }

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle:focus {
  outline: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 17px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box; }
  .dropdown-menu.pull-right {
    right: 0;
    left: auto; }
  .dropdown-menu .divider {
    height: 1px;
    margin: 12.5px 0;
    overflow: hidden;
    background-color: #e5e5e5; }
  .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.6;
    color: #333333;
    white-space: nowrap; }

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5; }

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #5C84B0; }

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #777777; }

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed; }

.open > .dropdown-menu {
  display: block; }

.open > a {
  outline: 0; }

.dropdown-menu-right {
  left: auto;
  right: 0; }

.dropdown-menu-left {
  left: 0;
  right: auto; }

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 15px;
  line-height: 1.6;
  color: #777777;
  white-space: nowrap; }

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990; }

.pull-right > .dropdown-menu {
  right: 0;
  left: auto; }

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: ""; }

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px; }

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto; }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto; } }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    float: left; }
    .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 2; }

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px; }

.btn-toolbar {
  margin-left: -5px; }
  .btn-toolbar:before, .btn-toolbar:after {
    content: " ";
    display: table; }
  .btn-toolbar:after {
    clear: both; }
  @supports (display: flow-root) {
    .btn-toolbar {
      display: flow-root; }
      .btn-toolbar:before, .btn-toolbar:after {
        content: none; } }
  .btn-toolbar .btn,
  .btn-toolbar .btn-group,
  .btn-toolbar .input-group {
    float: left; }
  .btn-toolbar > .btn,
  .btn-toolbar > .btn-group,
  .btn-toolbar > .input-group {
    margin-left: 5px; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .btn:first-child {
  margin-left: 0; }
  .btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0; }

.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px; }

.btn-group > .btn-lg + .dropdown-toggle, .btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px; }

.btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.open .dropdown-toggle.btn-link {
    box-shadow: none; }

.btn .caret {
  margin-left: 0; }

.btn-lg .caret, .btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0; }

.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px; }

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%; }

.btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after {
  content: " ";
  display: table; }

.btn-group-vertical > .btn-group:after {
  clear: both; }

@supports (display: flow-root) {
  .btn-group-vertical > .btn-group {
    display: flow-root; }
    .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after {
      content: none; } }

.btn-group-vertical > .btn-group > .btn {
  float: none; }

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0; }

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0; }

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; }
  .btn-group-justified > .btn,
  .btn-group-justified > .btn-group {
    float: none;
    display: table-cell;
    width: 1%; }
  .btn-group-justified > .btn-group .btn {
    width: 100%; }
  .btn-group-justified > .btn-group .dropdown-menu {
    left: auto; }

[data-toggle="buttons"] > .btn input[type="radio"],
[data-toggle="buttons"] > .btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }
  .nav:before, .nav:after {
    content: " ";
    display: table; }
  .nav:after {
    clear: both; }
  @supports (display: flow-root) {
    .nav {
      display: flow-root; }
      .nav:before, .nav:after {
        content: none; } }
  .nav > li {
    position: relative;
    display: block; }
    .nav > li > a {
      position: relative;
      display: block;
      padding: 10px 15px; }
      .nav > li > a:hover, .nav > li > a:focus {
        text-decoration: none;
        background-color: #eeeeee; }
    .nav > li.disabled > a {
      color: #777777; }
      .nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
        color: #777777;
        text-decoration: none;
        background-color: transparent;
        cursor: not-allowed; }
  .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: #eeeeee;
    border-color: #5C84B0; }
  .nav .nav-divider {
    height: 1px;
    margin: 12.5px 0;
    overflow: hidden;
    background-color: #e5e5e5; }
  .nav > li > a > img {
    max-width: none; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs > li {
    float: left;
    margin-bottom: -1px; }
    .nav-tabs > li > a {
      margin-right: 2px;
      line-height: 1.6;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0; }
      .nav-tabs > li > a:hover {
        border-color: #eeeeee #eeeeee #ddd; }
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
      color: #555555;
      background-color: #F4F4F0;
      border: 1px solid #ddd;
      border-bottom-color: transparent;
      cursor: default; }

.nav-pills > li {
  float: left; }
  .nav-pills > li > a {
    border-radius: 4px; }
  .nav-pills > li + li {
    margin-left: 2px; }
  .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    color: #fff;
    background-color: #5C84B0; }

.nav-stacked > li {
  float: none; }
  .nav-stacked > li + li {
    margin-top: 2px;
    margin-left: 0; }

.nav-justified, .nav-tabs.nav-justified {
  width: 100%; }
  .nav-justified > li, .nav-tabs.nav-justified > li {
    float: none; }
    .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
      text-align: center;
      margin-bottom: 5px; }
  .nav-justified > .dropdown .dropdown-menu {
    top: auto;
    left: auto; }
  @media (min-width: 768px) {
    .nav-justified > li, .nav-tabs.nav-justified > li {
      display: table-cell;
      width: 1%; }
      .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
        margin-bottom: 0; } }

.nav-tabs-justified, .nav-tabs.nav-justified {
  border-bottom: 0; }
  .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
    margin-right: 0;
    border-radius: 4px; }
  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus,
  .nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #ddd; }
  @media (min-width: 768px) {
    .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
      border-bottom: 1px solid #ddd;
      border-radius: 4px 4px 0 0; }
    .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
    .nav-tabs-justified > .active > a:hover,
    .nav-tabs.nav-justified > .active > a:hover,
    .nav-tabs-justified > .active > a:focus,
    .nav-tabs.nav-justified > .active > a:focus {
      border-bottom-color: #F4F4F0; } }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 27px 0;
  border-radius: 4px; }
  .pagination > li {
    display: inline; }
    .pagination > li > a,
    .pagination > li > span {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height: 1.6;
      text-decoration: none;
      color: #5C84B0;
      background-color: #fff;
      border: 1px solid #ddd;
      margin-left: -1px; }
    .pagination > li:first-child > a,
    .pagination > li:first-child > span {
      margin-left: 0;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px; }
    .pagination > li:last-child > a,
    .pagination > li:last-child > span {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px; }
  .pagination > li > a:hover, .pagination > li > a:focus,
  .pagination > li > span:hover,
  .pagination > li > span:focus {
    z-index: 3;
    color: #3f5e81;
    background-color: #eeeeee;
    border-color: #ddd; }
  .pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus,
  .pagination > .active > span,
  .pagination > .active > span:hover,
  .pagination > .active > span:focus {
    z-index: 2;
    color: #fff;
    background-color: #5C84B0;
    border-color: #5C84B0;
    cursor: default; }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    color: #777777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed; }

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 22px;
  line-height: 1.3333333; }

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px; }

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px; }

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 15px;
  line-height: 1.5; }

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px; }

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px; }

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em; }
  .label:empty {
    display: none; }
  .btn .label {
    position: relative;
    top: -1px; }

a.label:hover, a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer; }

.label-default {
  background-color: #777777; }
  .label-default[href]:hover, .label-default[href]:focus {
    background-color: #5e5e5e; }

.label-primary {
  background-color: #5C84B0; }
  .label-primary[href]:hover, .label-primary[href]:focus {
    background-color: #476b92; }

.label-success {
  background-color: #5cb85c; }
  .label-success[href]:hover, .label-success[href]:focus {
    background-color: #449d44; }

.label-info {
  background-color: #5bc0de; }
  .label-info[href]:hover, .label-info[href]:focus {
    background-color: #31b0d5; }

.label-warning {
  background-color: #f0ad4e; }
  .label-warning[href]:hover, .label-warning[href]:focus {
    background-color: #ec971f; }

.label-danger {
  background-color: #d9534f; }
  .label-danger[href]:hover, .label-danger[href]:focus {
    background-color: #c9302c; }

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #777777;
  border-radius: 10px; }
  .badge:empty {
    display: none; }
  .btn .badge {
    position: relative;
    top: -1px; }
  .btn-xs .badge, .btn-group-xs > .btn .badge,
  .btn-group-xs > .btn .badge {
    top: 0;
    padding: 1px 5px; }
  .list-group-item.active > .badge,
  .nav-pills > .active > a > .badge {
    color: #5C84B0;
    background-color: #fff; }
  .list-group-item > .badge {
    float: right; }
  .list-group-item > .badge + .badge {
    margin-right: 5px; }
  .nav-pills > li > a > .badge {
    margin-left: 3px; }

a.badge:hover, a.badge:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer; }

.alert {
  padding: 15px;
  margin-bottom: 27px;
  border: 1px solid transparent;
  border-radius: 4px; }
  .alert h4 {
    margin-top: 0;
    color: inherit; }
  .alert .alert-link {
    font-weight: bold; }
  .alert > p,
  .alert > ul {
    margin-bottom: 0; }
  .alert > p + p {
    margin-top: 5px; }

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px; }
  .alert-dismissable .close,
  .alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit; }

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d; }
  .alert-success hr {
    border-top-color: #c9e2b3; }
  .alert-success .alert-link {
    color: #2b542c; }

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f; }
  .alert-info hr {
    border-top-color: #a6e1ec; }
  .alert-info .alert-link {
    color: #245269; }

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b; }
  .alert-warning hr {
    border-top-color: #f7e1b5; }
  .alert-warning .alert-link {
    color: #66512c; }

.alert-danger, .alert-error {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442; }
  .alert-danger hr, .alert-error hr {
    border-top-color: #e4b9c0; }
  .alert-danger .alert-link, .alert-error .alert-link {
    color: #843534; }

.panel {
  margin-bottom: 27px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }

.panel-body {
  padding: 15px; }
  .panel-body:before, .panel-body:after {
    content: " ";
    display: table; }
  .panel-body:after {
    clear: both; }
  @supports (display: flow-root) {
    .panel-body {
      display: flow-root; }
      .panel-body:before, .panel-body:after {
        content: none; } }

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px; }
  .panel-heading > .dropdown .dropdown-toggle {
    color: inherit; }

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  color: inherit; }
  .panel-title > a,
  .panel-title > small,
  .panel-title > .small,
  .panel-title > small > a,
  .panel-title > .small > a {
    color: inherit; }

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0; }
  .panel > .list-group .list-group-item,
  .panel > .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0; }
  .panel > .list-group:first-child .list-group-item:first-child,
  .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px; }
  .panel > .list-group:last-child .list-group-item:last-child,
  .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0; }

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0; }

.list-group + .panel-footer {
  border-top-width: 0; }

.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0; }
  .panel > .table caption,
  .panel > .table-responsive > .table caption,
  .panel > .panel-collapse > .table caption {
    padding-left: 15px;
    padding-right: 15px; }

.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px; }
  .panel > .table:first-child > thead:first-child > tr:first-child,
  .panel > .table:first-child > tbody:first-child > tr:first-child,
  .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
  .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
    .panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
    .panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
      border-top-left-radius: 3px; }
    .panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
    .panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
      border-top-right-radius: 3px; }

.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }
  .panel > .table:last-child > tbody:last-child > tr:last-child,
  .panel > .table:last-child > tfoot:last-child > tr:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px; }
    .panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
    .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
      border-bottom-left-radius: 3px; }
    .panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
    .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
      border-bottom-right-radius: 3px; }

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd; }

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0; }

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0; }
  .panel > .table-bordered > thead > tr > th:first-child,
  .panel > .table-bordered > thead > tr > td:first-child,
  .panel > .table-bordered > tbody > tr > th:first-child,
  .panel > .table-bordered > tbody > tr > td:first-child,
  .panel > .table-bordered > tfoot > tr > th:first-child,
  .panel > .table-bordered > tfoot > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .panel > .table-bordered > thead > tr > th:last-child,
  .panel > .table-bordered > thead > tr > td:last-child,
  .panel > .table-bordered > tbody > tr > th:last-child,
  .panel > .table-bordered > tbody > tr > td:last-child,
  .panel > .table-bordered > tfoot > tr > th:last-child,
  .panel > .table-bordered > tfoot > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .panel > .table-bordered > thead > tr:first-child > td,
  .panel > .table-bordered > thead > tr:first-child > th,
  .panel > .table-bordered > tbody > tr:first-child > td,
  .panel > .table-bordered > tbody > tr:first-child > th,
  .panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
  .panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
  .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
  .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
    border-bottom: 0; }
  .panel > .table-bordered > tbody > tr:last-child > td,
  .panel > .table-bordered > tbody > tr:last-child > th,
  .panel > .table-bordered > tfoot > tr:last-child > td,
  .panel > .table-bordered > tfoot > tr:last-child > th,
  .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
  .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0; }

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0; }

.panel-group {
  margin-bottom: 27px; }
  .panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px; }
    .panel-group .panel + .panel {
      margin-top: 5px; }
  .panel-group .panel-heading {
    border-bottom: 0; }
    .panel-group .panel-heading + .panel-collapse > .panel-body,
    .panel-group .panel-heading + .panel-collapse > .list-group {
      border-top: 1px solid #ddd; }
  .panel-group .panel-footer {
    border-top: 0; }
    .panel-group .panel-footer + .panel-collapse .panel-body {
      border-bottom: 1px solid #ddd; }

.panel-default {
  border-color: #ddd; }
  .panel-default > .panel-heading {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #ddd; }
    .panel-default > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #ddd; }
    .panel-default > .panel-heading .badge {
      color: #f5f5f5;
      background-color: #333333; }
  .panel-default > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ddd; }

.panel-primary {
  border-color: #5C84B0; }
  .panel-primary > .panel-heading {
    color: #fff;
    background-color: #5C84B0;
    border-color: #5C84B0; }
    .panel-primary > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #5C84B0; }
    .panel-primary > .panel-heading .badge {
      color: #5C84B0;
      background-color: #fff; }
  .panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #5C84B0; }

.panel-success {
  border-color: #d6e9c6; }
  .panel-success > .panel-heading {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6; }
    .panel-success > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #d6e9c6; }
    .panel-success > .panel-heading .badge {
      color: #dff0d8;
      background-color: #3c763d; }
  .panel-success > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #d6e9c6; }

.panel-info {
  border-color: #bce8f1; }
  .panel-info > .panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1; }
    .panel-info > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #bce8f1; }
    .panel-info > .panel-heading .badge {
      color: #d9edf7;
      background-color: #31708f; }
  .panel-info > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #bce8f1; }

.panel-warning {
  border-color: #faebcc; }
  .panel-warning > .panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc; }
    .panel-warning > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #faebcc; }
    .panel-warning > .panel-heading .badge {
      color: #fcf8e3;
      background-color: #8a6d3b; }
  .panel-warning > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #faebcc; }

.panel-danger {
  border-color: #ebccd1; }
  .panel-danger > .panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1; }
    .panel-danger > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #ebccd1; }
    .panel-danger > .panel-heading .badge {
      color: #f2dede;
      background-color: #a94442; }
  .panel-danger > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ebccd1; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive img,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.embed-responsive-16by9 {
  padding-bottom: 56.25%; }

.embed-responsive-4by3 {
  padding-bottom: 75%; }

.close {
  float: right;
  font-size: 25.5px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5; }

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0; }
  .modal.fade .modal-dialog {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out; }
  .modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0); }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.in {
    opacity: 0.5; }

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.6px; }

.modal-header .close {
  margin-top: -2px; }

.modal-title {
  margin: 0;
  line-height: 1.6; }

.modal-body {
  position: relative;
  padding: 15px; }

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5; }
  .modal-footer:before, .modal-footer:after {
    content: " ";
    display: table; }
  .modal-footer:after {
    clear: both; }
  @supports (display: flow-root) {
    .modal-footer {
      display: flow-root; }
      .modal-footer:before, .modal-footer:after {
        content: none; } }
  .modal-footer .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; }
  .modal-footer .btn-group .btn + .btn {
    margin-left: -1px; }
  .modal-footer .btn-block + .btn-block {
    margin-left: 0; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto; }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }
  .modal-sm {
    width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    width: 900px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: 'QuattrocentoSans-Regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 15px;
  opacity: 0; }
  .tooltip.in {
    opacity: 0.9; }
  .tooltip.top {
    margin-top: -3px;
    padding: 5px 0; }
  .tooltip.right {
    margin-left: 3px;
    padding: 0 5px; }
  .tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0; }
  .tooltip.left {
    margin-left: -3px;
    padding: 0 5px; }

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px; }

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000; }

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000; }

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000; }

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: 'QuattrocentoSans-Regular', sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 17px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
  .popover.top {
    margin-top: -10px; }
  .popover.right {
    margin-left: 10px; }
  .popover.bottom {
    margin-top: 10px; }
  .popover.left {
    margin-left: -10px; }

.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 17px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0; }

.popover-content {
  padding: 9px 14px; }

.popover > .arrow, .popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.popover > .arrow {
  border-width: 11px; }

.popover > .arrow:after {
  border-width: 10px;
  content: ""; }

.popover.top > .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px; }
  .popover.top > .arrow:after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: #fff; }

.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25); }
  .popover.right > .arrow:after {
    content: " ";
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: #fff; }

.popover.bottom > .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px; }
  .popover.bottom > .arrow:after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #fff; }

.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25); }
  .popover.left > .arrow:after {
    content: " ";
    right: 1px;
    border-right-width: 0;
    border-left-color: #fff;
    bottom: -10px; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

@supports (display: flow-root) {
  .clearfix {
    display: flow-root; }
    .clearfix:before, .clearfix:after {
      content: none; } }

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.pull-right {
  float: right !important; }

.pull-left {
  float: left !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.hidden {
  display: none !important; }

.affix {
  position: fixed; }

@-ms-viewport {
  width: device-width; }

.visible-xs {
  display: none !important; }

.visible-sm {
  display: none !important; }

.visible-md {
  display: none !important; }

.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table !important; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

/* 
    Created on : 28-ott-2015, 17.18.36
    Author     : Davide
*/
/*@mixin button-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $background;
          border-color: $border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}*/
.col, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px; }

.breadcrumb {
  font-size: 0.875em;
  padding: 0.4705882353em 0;
  margin-top: 1.5882352941em;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 4px; }
  .breadcrumb > li {
    display: inline-block; }
    .breadcrumb > li + li:before {
      content: "/ ";
      padding: 0 5px;
      color: #ccc; }
  .breadcrumb > .active {
    color: #777777; }

.btn-flex, #FormGuestbook .file-input-wrapper {
  padding: 0.1764705882em 2.1176470588em;
  font-size: 1.1764705882em;
  line-height: 1.6;
  border-radius: 0;
  transition: all, 0.5s; }

.btn-default {
  color: #5C84B0;
  background-color: white;
  border-color: white;
  transition: all, 0.5s;
  font-weight: bold; }
  .btn-default:hover, .btn-default:focus {
    color: white;
    background-color: #5C84B0;
    border-color: #5C84B0; }

.btn-dark_cerulean {
  color: white;
  background-color: #003F7F;
  border-color: #003F7F;
  transition: all, 0.5s;
  font-weight: bold; }
  .btn-dark_cerulean:hover, .btn-dark_cerulean:focus {
    color: white;
    background-color: #5C84B0;
    border-color: #5C84B0; }

.input-group {
  position: relative;
  display: table;
  border-collapse: separate; }
  .input-group[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0; }
  .input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0; }

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell; }
  .input-group-addon:not(:first-child):not(:last-child),
  .input-group-btn:not(:first-child):not(:last-child),
  .input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0; }

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle; }

.input-group-addon {
  padding: 6px 12px;
  font-size: 17px;
  font-weight: normal;
  line-height: 1;
  color: #555555;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #e6e5e5;
  border-radius: input-group-border-radius; }
  .input-group-addon.input-sm,
  .input-group-sm > .input-group-addon,
  .input-group-sm > .input-group-btn > .input-group-addon.btn {
    padding: 5px 10px;
    font-size: 15px;
    border-radius: 0; }
  .input-group-addon.input-lg,
  .input-group-lg > .input-group-addon,
  .input-group-lg > .input-group-btn > .input-group-addon.btn {
    padding: 10px 16px;
    font-size: 22px;
    border-radius: 0; }
  .input-group-addon input[type="radio"],
  .input-group-addon input[type="checkbox"] {
    margin-top: 0; }

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.input-group-addon:first-child {
  border-right: 0; }

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.input-group-addon:last-child {
  border-left: 0; }

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap; }
  .input-group-btn > .btn {
    position: relative; }
    .input-group-btn > .btn + .btn {
      margin-left: -1px; }
    .input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
      z-index: 2; }
  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .btn-group {
    margin-right: -1px; }
  .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .btn-group {
    z-index: 2;
    margin-left: -1px; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 750px; }
  .container:before, .container:after {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }
  @supports (display: flow-root) {
    .container {
      display: flow-root; }
      .container:before, .container:after {
        content: none; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      width: 1170px; } }
  .container.max-sm {
    max-width: 750px !important; }
  .container.max-md {
    max-width: 970px !important; }
  .container.max-lg {
    max-width: 1170px !important; }
  .container.max-xl {
    max-width: 1310px !important; }

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table; }
  .container-fluid:after {
    clear: both; }
  @supports (display: flow-root) {
    .container-fluid {
      display: flow-root; }
      .container-fluid:before, .container-fluid:after {
        content: none; } }

.row {
  margin-left: -15px;
  margin-right: -15px; }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }
  @supports (display: flow-root) {
    .row {
      display: flow-root; }
      .row:before, .row:after {
        content: none; } }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.3333333333%; }

.col-xs-2 {
  width: 16.6666666667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.3333333333%; }

.col-xs-5 {
  width: 41.6666666667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.3333333333%; }

.col-xs-8 {
  width: 66.6666666667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.3333333333%; }

.col-xs-11 {
  width: 91.6666666667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-pull-0 {
  right: auto; }

.col-xs-pull-1 {
  right: 8.3333333333%; }

.col-xs-pull-2 {
  right: 16.6666666667%; }

.col-xs-pull-3 {
  right: 25%; }

.col-xs-pull-4 {
  right: 33.3333333333%; }

.col-xs-pull-5 {
  right: 41.6666666667%; }

.col-xs-pull-6 {
  right: 50%; }

.col-xs-pull-7 {
  right: 58.3333333333%; }

.col-xs-pull-8 {
  right: 66.6666666667%; }

.col-xs-pull-9 {
  right: 75%; }

.col-xs-pull-10 {
  right: 83.3333333333%; }

.col-xs-pull-11 {
  right: 91.6666666667%; }

.col-xs-pull-12 {
  right: 100%; }

.col-xs-push-0 {
  left: auto; }

.col-xs-push-1 {
  left: 8.3333333333%; }

.col-xs-push-2 {
  left: 16.6666666667%; }

.col-xs-push-3 {
  left: 25%; }

.col-xs-push-4 {
  left: 33.3333333333%; }

.col-xs-push-5 {
  left: 41.6666666667%; }

.col-xs-push-6 {
  left: 50%; }

.col-xs-push-7 {
  left: 58.3333333333%; }

.col-xs-push-8 {
  left: 66.6666666667%; }

.col-xs-push-9 {
  left: 75%; }

.col-xs-push-10 {
  left: 83.3333333333%; }

.col-xs-push-11 {
  left: 91.6666666667%; }

.col-xs-push-12 {
  left: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 8.3333333333%; }

.col-xs-offset-2 {
  margin-left: 16.6666666667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.3333333333%; }

.col-xs-offset-5 {
  margin-left: 41.6666666667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.3333333333%; }

.col-xs-offset-8 {
  margin-left: 66.6666666667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.3333333333%; }

.col-xs-offset-11 {
  margin-left: 91.6666666667%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.3333333333%; }
  .col-sm-2 {
    width: 16.6666666667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.3333333333%; }
  .col-sm-5 {
    width: 41.6666666667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.3333333333%; }
  .col-sm-8 {
    width: 66.6666666667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.3333333333%; }
  .col-sm-11 {
    width: 91.6666666667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-pull-0 {
    right: auto; }
  .col-sm-pull-1 {
    right: 8.3333333333%; }
  .col-sm-pull-2 {
    right: 16.6666666667%; }
  .col-sm-pull-3 {
    right: 25%; }
  .col-sm-pull-4 {
    right: 33.3333333333%; }
  .col-sm-pull-5 {
    right: 41.6666666667%; }
  .col-sm-pull-6 {
    right: 50%; }
  .col-sm-pull-7 {
    right: 58.3333333333%; }
  .col-sm-pull-8 {
    right: 66.6666666667%; }
  .col-sm-pull-9 {
    right: 75%; }
  .col-sm-pull-10 {
    right: 83.3333333333%; }
  .col-sm-pull-11 {
    right: 91.6666666667%; }
  .col-sm-pull-12 {
    right: 100%; }
  .col-sm-push-0 {
    left: auto; }
  .col-sm-push-1 {
    left: 8.3333333333%; }
  .col-sm-push-2 {
    left: 16.6666666667%; }
  .col-sm-push-3 {
    left: 25%; }
  .col-sm-push-4 {
    left: 33.3333333333%; }
  .col-sm-push-5 {
    left: 41.6666666667%; }
  .col-sm-push-6 {
    left: 50%; }
  .col-sm-push-7 {
    left: 58.3333333333%; }
  .col-sm-push-8 {
    left: 66.6666666667%; }
  .col-sm-push-9 {
    left: 75%; }
  .col-sm-push-10 {
    left: 83.3333333333%; }
  .col-sm-push-11 {
    left: 91.6666666667%; }
  .col-sm-push-12 {
    left: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%; }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%; }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%; }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%; }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.3333333333%; }
  .col-md-2 {
    width: 16.6666666667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.3333333333%; }
  .col-md-5 {
    width: 41.6666666667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.3333333333%; }
  .col-md-8 {
    width: 66.6666666667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.3333333333%; }
  .col-md-11 {
    width: 91.6666666667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-pull-0 {
    right: auto; }
  .col-md-pull-1 {
    right: 8.3333333333%; }
  .col-md-pull-2 {
    right: 16.6666666667%; }
  .col-md-pull-3 {
    right: 25%; }
  .col-md-pull-4 {
    right: 33.3333333333%; }
  .col-md-pull-5 {
    right: 41.6666666667%; }
  .col-md-pull-6 {
    right: 50%; }
  .col-md-pull-7 {
    right: 58.3333333333%; }
  .col-md-pull-8 {
    right: 66.6666666667%; }
  .col-md-pull-9 {
    right: 75%; }
  .col-md-pull-10 {
    right: 83.3333333333%; }
  .col-md-pull-11 {
    right: 91.6666666667%; }
  .col-md-pull-12 {
    right: 100%; }
  .col-md-push-0 {
    left: auto; }
  .col-md-push-1 {
    left: 8.3333333333%; }
  .col-md-push-2 {
    left: 16.6666666667%; }
  .col-md-push-3 {
    left: 25%; }
  .col-md-push-4 {
    left: 33.3333333333%; }
  .col-md-push-5 {
    left: 41.6666666667%; }
  .col-md-push-6 {
    left: 50%; }
  .col-md-push-7 {
    left: 58.3333333333%; }
  .col-md-push-8 {
    left: 66.6666666667%; }
  .col-md-push-9 {
    left: 75%; }
  .col-md-push-10 {
    left: 83.3333333333%; }
  .col-md-push-11 {
    left: 91.6666666667%; }
  .col-md-push-12 {
    left: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.3333333333%; }
  .col-md-offset-2 {
    margin-left: 16.6666666667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.3333333333%; }
  .col-md-offset-5 {
    margin-left: 41.6666666667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.3333333333%; }
  .col-md-offset-8 {
    margin-left: 66.6666666667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.3333333333%; }
  .col-md-offset-11 {
    margin-left: 91.6666666667%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.3333333333%; }
  .col-lg-2 {
    width: 16.6666666667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.3333333333%; }
  .col-lg-5 {
    width: 41.6666666667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.3333333333%; }
  .col-lg-8 {
    width: 66.6666666667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.3333333333%; }
  .col-lg-11 {
    width: 91.6666666667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-pull-0 {
    right: auto; }
  .col-lg-pull-1 {
    right: 8.3333333333%; }
  .col-lg-pull-2 {
    right: 16.6666666667%; }
  .col-lg-pull-3 {
    right: 25%; }
  .col-lg-pull-4 {
    right: 33.3333333333%; }
  .col-lg-pull-5 {
    right: 41.6666666667%; }
  .col-lg-pull-6 {
    right: 50%; }
  .col-lg-pull-7 {
    right: 58.3333333333%; }
  .col-lg-pull-8 {
    right: 66.6666666667%; }
  .col-lg-pull-9 {
    right: 75%; }
  .col-lg-pull-10 {
    right: 83.3333333333%; }
  .col-lg-pull-11 {
    right: 91.6666666667%; }
  .col-lg-pull-12 {
    right: 100%; }
  .col-lg-push-0 {
    left: auto; }
  .col-lg-push-1 {
    left: 8.3333333333%; }
  .col-lg-push-2 {
    left: 16.6666666667%; }
  .col-lg-push-3 {
    left: 25%; }
  .col-lg-push-4 {
    left: 33.3333333333%; }
  .col-lg-push-5 {
    left: 41.6666666667%; }
  .col-lg-push-6 {
    left: 50%; }
  .col-lg-push-7 {
    left: 58.3333333333%; }
  .col-lg-push-8 {
    left: 66.6666666667%; }
  .col-lg-push-9 {
    left: 75%; }
  .col-lg-push-10 {
    left: 83.3333333333%; }
  .col-lg-push-11 {
    left: 91.6666666667%; }
  .col-lg-push-12 {
    left: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%; }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%; }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%; }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%; }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

.page-header h1, .page-header .h1, h1.lemma, .h1.lemma, header.h1 {
  font-size: 3.1764705882em; }

.page-header h2, .page-header .h2, h2.lemma, .h2.lemma, header.h2 {
  font-size: 2.3793516316em; }

.page-header h3, .page-header .h3, h3.lemma, .h3.lemma, header.h3 {
  font-size: 1.7822655774em; }

.page-header h4, .page-header .h4, h4.lemma, .h4.lemma, header.h4 {
  font-size: 1.3350151974em; }

.page-header h5, .page-header .h5, h5.lemma, .h5.lemma, header.h5 {
  font-size: 1.7822655774em; }

.page-header h6, .page-header .h6, h6.lemma, .h6.lemma, header.h6 {
  font-size: 0.7490551433em; }

.page-header {
  padding-bottom: 1px;
  padding-top: 1px; }
  .breadcrumb + .page-header {
    margin-top: -27px; }
  .page-header h1, .page-header .h1 {
    margin-bottom: 0.2314814815em;
    margin-top: 0.4814814815em; }
  .page-header h2, .page-header .h2 {
    margin-bottom: 0.3090312957em;
    margin-top: 0.6427850951em; }
  .page-header h3, .page-header .h3 {
    margin-bottom: 0.4125614762em;
    margin-top: 0.8581278706em; }
  .page-header h4, .page-header .h4 {
    margin-bottom: 0.5507758407em;
    margin-top: 0.5507758407em; }
  .page-header h5, .page-header .h5 {
    margin-bottom: 0.4125614762em;
    margin-top: 0.4125614762em; }
  .page-header h6, .page-header .h6 {
    margin-bottom: 0.9816288216em;
    margin-top: 0.9816288216em; }
  .page-header h1, .page-header h2, .page-header h3, .page-header h4, .page-header h5, .page-header h6, .page-header .h1, .page-header .h2, .page-header .h3, .page-header .h4, .page-header .h5, .page-header .h6 {
    line-height: 1;
    padding-bottom: 0.125em; }
  .page-header h1, .page-header h2, .page-header h3, .page-header .h1, .page-header .h2, .page-header .h3 {
    padding-top: 0.25em; }
  .page-header h4, .page-header h5, .page-header h6, .page-header .h4, .page-header .h5, .page-header .h6 {
    padding-top: 0.125em; }

.bg-air_force_blue {
  background-color: #5C84B0; }

a.bg-air_force_blue:hover,
a.bg-air_force_blue:focus {
  background-color: #476b92; }

.bg-dark_cerulean {
  background-color: #003F7F; }

a.bg-dark_cerulean:hover,
a.bg-dark_cerulean:focus {
  background-color: #00264c; }

.bg-ivory {
  background-color: #F4F4F0; }

a.bg-ivory:hover,
a.bg-ivory:focus {
  background-color: #deded3; }

.bg-pacific_blue {
  background-color: #0198CC; }

a.bg-pacific_blue:hover,
a.bg-pacific_blue:focus {
  background-color: #017299; }

.bg-cerulean {
  background-color: #006CA5; }

a.bg-cerulean:hover,
a.bg-cerulean:focus {
  background-color: #004b72; }

.bg-pine_cone {
  background-color: #706558; }

a.bg-pine_cone:hover,
a.bg-pine_cone:focus {
  background-color: #534b42; }

.bg-skyblue {
  background-color: #a4d3ee; }

a.bg-skyblue:hover,
a.bg-skyblue:focus {
  background-color: #79bee6; }

.bg-lightskyblue {
  background-color: #87cefa; }

a.bg-lightskyblue:hover,
a.bg-lightskyblue:focus {
  background-color: #56baf8; }

.bg-seagreen {
  background-color: #54ff9f; }

a.bg-seagreen:hover,
a.bg-seagreen:focus {
  background-color: #21ff82; }

.bg-darkgray {
  background-color: #a9a9a9; }

a.bg-darkgray:hover,
a.bg-darkgray:focus {
  background-color: #908f8f; }

@media (min-width: 1360px) {
  .container {
    width: 1310px; } }

@media (min-width: 1600px) {
  .container.larger, .container.largest {
    width: 1560px; } }

@media (min-width: 1910px) {
  .container.largest {
    width: 1830px; } }

@media (min-width: 1360px) {
  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    float: left; }
  .col-xl-1 {
    width: 8.3333333333%; }
  .col-xl-2 {
    width: 16.6666666667%; }
  .col-xl-3 {
    width: 25%; }
  .col-xl-4 {
    width: 33.3333333333%; }
  .col-xl-5 {
    width: 41.6666666667%; }
  .col-xl-6 {
    width: 50%; }
  .col-xl-7 {
    width: 58.3333333333%; }
  .col-xl-8 {
    width: 66.6666666667%; }
  .col-xl-9 {
    width: 75%; }
  .col-xl-10 {
    width: 83.3333333333%; }
  .col-xl-11 {
    width: 91.6666666667%; }
  .col-xl-12 {
    width: 100%; }
  .col-xl-pull-0 {
    right: auto; }
  .col-xl-pull-1 {
    right: 8.3333333333%; }
  .col-xl-pull-2 {
    right: 16.6666666667%; }
  .col-xl-pull-3 {
    right: 25%; }
  .col-xl-pull-4 {
    right: 33.3333333333%; }
  .col-xl-pull-5 {
    right: 41.6666666667%; }
  .col-xl-pull-6 {
    right: 50%; }
  .col-xl-pull-7 {
    right: 58.3333333333%; }
  .col-xl-pull-8 {
    right: 66.6666666667%; }
  .col-xl-pull-9 {
    right: 75%; }
  .col-xl-pull-10 {
    right: 83.3333333333%; }
  .col-xl-pull-11 {
    right: 91.6666666667%; }
  .col-xl-pull-12 {
    right: 100%; }
  .col-xl-push-0 {
    left: auto; }
  .col-xl-push-1 {
    left: 8.3333333333%; }
  .col-xl-push-2 {
    left: 16.6666666667%; }
  .col-xl-push-3 {
    left: 25%; }
  .col-xl-push-4 {
    left: 33.3333333333%; }
  .col-xl-push-5 {
    left: 41.6666666667%; }
  .col-xl-push-6 {
    left: 50%; }
  .col-xl-push-7 {
    left: 58.3333333333%; }
  .col-xl-push-8 {
    left: 66.6666666667%; }
  .col-xl-push-9 {
    left: 75%; }
  .col-xl-push-10 {
    left: 83.3333333333%; }
  .col-xl-push-11 {
    left: 91.6666666667%; }
  .col-xl-push-12 {
    left: 100%; }
  .col-xl-offset-0 {
    margin-left: 0%; }
  .col-xl-offset-1 {
    margin-left: 8.3333333333%; }
  .col-xl-offset-2 {
    margin-left: 16.6666666667%; }
  .col-xl-offset-3 {
    margin-left: 25%; }
  .col-xl-offset-4 {
    margin-left: 33.3333333333%; }
  .col-xl-offset-5 {
    margin-left: 41.6666666667%; }
  .col-xl-offset-6 {
    margin-left: 50%; }
  .col-xl-offset-7 {
    margin-left: 58.3333333333%; }
  .col-xl-offset-8 {
    margin-left: 66.6666666667%; }
  .col-xl-offset-9 {
    margin-left: 75%; }
  .col-xl-offset-10 {
    margin-left: 83.3333333333%; }
  .col-xl-offset-11 {
    margin-left: 91.6666666667%; }
  .col-xl-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1600px) {
  .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12 {
    float: left; }
  .col-xxl-1 {
    width: 8.3333333333%; }
  .col-xxl-2 {
    width: 16.6666666667%; }
  .col-xxl-3 {
    width: 25%; }
  .col-xxl-4 {
    width: 33.3333333333%; }
  .col-xxl-5 {
    width: 41.6666666667%; }
  .col-xxl-6 {
    width: 50%; }
  .col-xxl-7 {
    width: 58.3333333333%; }
  .col-xxl-8 {
    width: 66.6666666667%; }
  .col-xxl-9 {
    width: 75%; }
  .col-xxl-10 {
    width: 83.3333333333%; }
  .col-xxl-11 {
    width: 91.6666666667%; }
  .col-xxl-12 {
    width: 100%; }
  .col-xxl-pull-0 {
    right: auto; }
  .col-xxl-pull-1 {
    right: 8.3333333333%; }
  .col-xxl-pull-2 {
    right: 16.6666666667%; }
  .col-xxl-pull-3 {
    right: 25%; }
  .col-xxl-pull-4 {
    right: 33.3333333333%; }
  .col-xxl-pull-5 {
    right: 41.6666666667%; }
  .col-xxl-pull-6 {
    right: 50%; }
  .col-xxl-pull-7 {
    right: 58.3333333333%; }
  .col-xxl-pull-8 {
    right: 66.6666666667%; }
  .col-xxl-pull-9 {
    right: 75%; }
  .col-xxl-pull-10 {
    right: 83.3333333333%; }
  .col-xxl-pull-11 {
    right: 91.6666666667%; }
  .col-xxl-pull-12 {
    right: 100%; }
  .col-xxl-push-0 {
    left: auto; }
  .col-xxl-push-1 {
    left: 8.3333333333%; }
  .col-xxl-push-2 {
    left: 16.6666666667%; }
  .col-xxl-push-3 {
    left: 25%; }
  .col-xxl-push-4 {
    left: 33.3333333333%; }
  .col-xxl-push-5 {
    left: 41.6666666667%; }
  .col-xxl-push-6 {
    left: 50%; }
  .col-xxl-push-7 {
    left: 58.3333333333%; }
  .col-xxl-push-8 {
    left: 66.6666666667%; }
  .col-xxl-push-9 {
    left: 75%; }
  .col-xxl-push-10 {
    left: 83.3333333333%; }
  .col-xxl-push-11 {
    left: 91.6666666667%; }
  .col-xxl-push-12 {
    left: 100%; }
  .col-xxl-offset-0 {
    margin-left: 0%; }
  .col-xxl-offset-1 {
    margin-left: 8.3333333333%; }
  .col-xxl-offset-2 {
    margin-left: 16.6666666667%; }
  .col-xxl-offset-3 {
    margin-left: 25%; }
  .col-xxl-offset-4 {
    margin-left: 33.3333333333%; }
  .col-xxl-offset-5 {
    margin-left: 41.6666666667%; }
  .col-xxl-offset-6 {
    margin-left: 50%; }
  .col-xxl-offset-7 {
    margin-left: 58.3333333333%; }
  .col-xxl-offset-8 {
    margin-left: 66.6666666667%; }
  .col-xxl-offset-9 {
    margin-left: 75%; }
  .col-xxl-offset-10 {
    margin-left: 83.3333333333%; }
  .col-xxl-offset-11 {
    margin-left: 91.6666666667%; }
  .col-xxl-offset-12 {
    margin-left: 100%; } }

.visible-xl {
  display: none !important; }

.visible-xxl {
  display: none !important; }

.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block,
.visible-xxl-block,
.visible-xxl-inline,
.visible-xxl-inline-block {
  display: none !important; }

@media (min-width: 1200px) and (max-width: 1359px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) and (max-width: 1359px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) and (max-width: 1359px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) and (max-width: 1359px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (min-width: 1360px) and (max-width: 1599px) {
  .visible-xl {
    display: block !important; }
  table.visible-xl {
    display: table !important; }
  tr.visible-xl {
    display: table-row !important; }
  th.visible-xl,
  td.visible-xl {
    display: table-cell !important; } }

@media (min-width: 1360px) and (max-width: 1599px) {
  .visible-xl-block {
    display: block !important; } }

@media (min-width: 1360px) and (max-width: 1599px) {
  .visible-xl-inline {
    display: inline !important; } }

@media (min-width: 1360px) and (max-width: 1599px) {
  .visible-xl-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-xxl {
    display: block !important; }
  table.visible-xxl {
    display: table !important; }
  tr.visible-xxl {
    display: table-row !important; }
  th.visible-xxl,
  td.visible-xxl {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-xxl-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-xxl-inline {
    display: inline !important; } }

@media (min-width: 1600px) {
  .visible-xxl-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) and (max-width: 1359px) {
  .hidden-lg {
    display: none !important; } }

@media (min-width: 1360px) and (max-width: 1599px) {
  .hidden-xl {
    display: none !important; } }

@media (min-width: 1360px) {
  .hidden-xxl {
    display: none !important; } }

body, nav .horizontal, nav .horizontal li .submenu, .form-group.checkbox, .form-group.radio, .captcha, .flex-form-row, .flex-form-row .form-group.file, .vegas-container .navigation, nav, .gallery-container.flex-gallery .figure, .gallery-container.flex-gallery .figure a, #GuestbookMessages .wrapper_pagination {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex; }

.gallery-container.flex-gallery .figure a .more-images {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex; }

@svg-load endesia url(images/layout/endesia.svg) {
  fill-opacity: 1;
  #Endesia, #Webdesign, #reg {
    fill: white; } }

@svg-load endesia-static url(images/layout/endesia.svg) {
  fill-opacity: 0.65;
  * {
    fill: white; } }

@svg-load logo  url(images/layout/logo.svg) {
  * {
    fill: white; } }

@svg-load logo-placeholder  url(images/layout/logo.svg) {
  * {
    fill: rgba(255, 255, 255, 0.8); } }

@svg-load tripadvisor url(images/svg/logos/tripadvisor.svg) {
  .st0 {
    fill: #FFDE9D;
    stroke: #FFDE9D;
    stroke-miterlimit: 10; }
  .st1 {
    fill: #EC2227; }
  .st2 {
    fill: #00AF87; }
  .st3 {
    fill: #231F20; }
  .st4 {
    fill: #999999;
    stroke: #999999;
    stroke-miterlimit: 10; }
  .st5 {
    fill: #999999; }
  .st6 {
    fill: #333333; }
  .mark {
    display: none; } }

@svg-load instagram url(images/svg/logos/instagram.svg) {}@svg-load youtube url(images/svg/logos/youtube.svg) {}@svg-load flickr url(images/svg/logos/flickr.svg) {}@svg-load twitter url(images/svg/logos/twitter.svg) {
  * {
    fill: #1da1f2; } }

@svg-load facebook url(images/svg/logos/facebook.svg) {
  .color-fb {
    fill: #1876f2; }
  .mark {
    display: none; } }

@svg-load whatsapp url(images/svg/logos/whatsapp.svg) {
  .fill-white {
    fill: white; }
  .fill-whatsapp {
    fill: #25d366; }
  .fill-gray {
    fill: #9E9E9E; } }

@svg-load enjoy-the-coast url(images/svg/logos/enjoy.svg) {
  #Sole {
    fill: #f0af30; }
  #Onda {
    fill: #0384bd; }
  #E {
    fill: #d55b47; }
  #N {
    fill: #f0af30; }
  #J {
    fill: #7fc24f; }
  #O {
    fill: #1e7ac0; }
  #Y {
    fill: #664199; } }

@svg-load caret url(images/svg/caret.svg) {
  #Riempimento {
    fill: #5C84B0; }
  #Contorno {
    fill: white;
    fill-opacity: 0.75; } }

@svg-load flag_en url(images/svg/flags/united_kingdom.svg) {
  .fill-blue {
    fill: #00247d; }
  .stroke-white {
    stroke: white; }
  .stroke-red {
    stroke: #cf142b; } }

@svg-load flag_it url(images/svg/flags/vertical_3.svg) {
  #Left {
    fill: #009246; }
  #Center {
    fill: white; }
  #Right {
    fill: #cf2b37; } }

@svg-load flag_fr url(images/svg/flags/vertical_3.svg) {
  #Left {
    fill: #002495; }
  #Center {
    fill: white; }
  #Right {
    fill: #ed2939; } }

@svg-load flag_de url(images/svg/flags/horizontal_3.svg) {
  #Top {
    fill: black; }
  #Center {
    fill: #dd0f15; }
  #Bottom {
    fill: #ffce00; } }

@svg-load flag_es url(images/svg/flags/horizontal_3_1.svg) {
  #Top, #Bottom {
    fill: #c70c1f; }
  #Center {
    fill: #ffc400; } }

.svg-embed {
  position: relative;
  display: inline-block; }
  .svg-embed:before {
    size: 100%; }

#GuestbookMessages .wrapper_pagination {
  width: 720px; }
  @media (min-width: 992px) {
    #GuestbookMessages .wrapper_pagination {
      width: 940px; } }
  @media (min-width: 1200px) {
    #GuestbookMessages .wrapper_pagination {
      width: 1140px; } }
  @media (min-width: 1360px) {
    #GuestbookMessages .wrapper_pagination {
      width: 1280px; } }

#SliderWrapper .scroll:after, .gallery-container .figure a:before, .flex-images .figure a:before {
  position: absolute;
  content: "";
  display: block; }

.gallery-container .figure a .zoom, .flex-images .figure a .zoom, #Lodgings .additional-gallery-wrapper .gallery-container .main-image .figure a .zoom, #Lodgings .additional-gallery-wrapper .gallery-container .small-images .figure a .zoom, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .main-image .figure a .zoom, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images .figure a .zoom {
  visibility: hidden;
  color: white;
  transform: scale(0);
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 1;
  font-size: 5.2941176471em;
  margin-top: -0.5em;
  margin-left: -0.5em;
  -webkit-transition: all 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530);
  -moz-transition: all 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530);
  -o-transition: all 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530);
  transition: all 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530);
  -webkit-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530);
  -moz-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530);
  -ms-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530);
  -o-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530);
  transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530);
  z-index: 4; }
  .gallery-container .figure a .svg-embed.zoom, .flex-images .figure a .svg-embed.zoom, #Lodgings .additional-gallery-wrapper .gallery-container .main-image .figure a .svg-embed.zoom, #Lodgings .additional-gallery-wrapper .gallery-container .small-images .figure a .svg-embed.zoom, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .main-image .figure a .svg-embed.zoom, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images .figure a .svg-embed.zoom {
    width: 1em;
    height: 1em; }

.gallery-container .figure a:hover .zoom, .flex-images .figure a:hover .zoom, #Lodgings .additional-gallery-wrapper .gallery-container .main-image .figure a:hover .zoom, #Lodgings .additional-gallery-wrapper .gallery-container .small-images .figure a:hover .zoom, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .main-image .figure a:hover .zoom, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images .figure a:hover .zoom {
  transform: scale(1);
  visibility: visible; }

.text-white {
  color: white; }

#Offers .offer .offer-photo.has-no-image, #Offer .offer-detail .offer-photo.has-no-image {
  background: url("/assets/images/layout/logo.png") center center no-repeat #a9a9a9;
  background-size: 4.7058823529em 4.7058823529em; }

.flex.align-left, .inline-flex.align-left {
  justify-content: flex-start; }

.flex.align-right, .inline-flex.align-right {
  justify-content: flex-end; }

.flex.align-center, .inline-flex.align-center {
  justify-content: center; }

.flex.align-top, .inline-flex.align-top {
  align-items: flex-start; }

.flex.align-bottom, .inline-flex.align-bottom {
  align-items: flex-end; }

.flex.align-middle, .inline-flex.align-middle {
  align-items: center; }

.flex.align-justify, .inline-flex.align-justify {
  justify-content: space-between; }

.flex.align-spaced, .inline-flex.align-spaced {
  justify-content: space-around; }

.flex.column, .inline-flex.column {
  flex-direction: column; }
  .flex.column.align-top, .inline-flex.column.align-top {
    justify-content: flex-start;
    align-items: stretch; }
  .flex.column.align-bottom, .inline-flex.column.align-bottom {
    justify-content: flex-end;
    align-items: stretch; }
  .flex.column.align-middle, .inline-flex.column.align-middle {
    justify-content: center;
    align-items: stretch; }
  .flex.column.align-center, .inline-flex.column.align-center {
    align-items: center; }
  .flex.column.align-left, .inline-flex.column.align-left {
    align-items: flex-start; }
  .flex.column.align-right, .inline-flex.column.align-right {
    align-items: flex-end; }
  .flex.column.reverse, .inline-flex.column.reverse {
    flex-direction: column-reverse; }
    .flex.column.reverse.align-top, .inline-flex.column.reverse.align-top {
      justify-content: flex-end; }
    .flex.column.reverse.align-bottom, .inline-flex.column.reverse.align-bottom {
      justify-content: flex-start; }

.flex.reverse, .inline-flex.reverse {
  flex-direction: row-reverse; }
  .flex.reverse.align-left, .inline-flex.reverse.align-left {
    justify-content: flex-end; }
  .flex.reverse.align-right, .inline-flex.reverse.align-right {
    justify-content: flex-start; }

@supports (display: flex) {
  .flex.container:before, .flex.container:after, .flex.row:before, .flex.row:after, .inline-flex.container:before, .inline-flex.container:after, .inline-flex.row:before, .inline-flex.row:after {
    content: none; } }

.flex.wrap, .inline-flex.wrap {
  flex-wrap: wrap; }
  .flexbox.no-flexwrap .flex.wrap.row, .flexbox.no-flexwrap .inline-flex.wrap.row {
    display: block; }
    .flexbox.no-flexwrap .flex.wrap.row:before, .flexbox.no-flexwrap .flex.wrap.row:after, .flexbox.no-flexwrap .inline-flex.wrap.row:before, .flexbox.no-flexwrap .inline-flex.wrap.row:after {
      content: " ";
      display: table; }
    .flexbox.no-flexwrap .flex.wrap.row:after, .flexbox.no-flexwrap .inline-flex.wrap.row:after {
      clear: both; }

.flex.wrap-reverse, .inline-flex.wrap-reverse {
  flex-wrap: wrap-reverse; }
  .flexbox.no-flexwrap .flex.wrap-reverse.row, .flexbox.no-flexwrap .inline-flex.wrap-reverse.row {
    display: block; }
    .flexbox.no-flexwrap .flex.wrap-reverse.row:before, .flexbox.no-flexwrap .flex.wrap-reverse.row:after, .flexbox.no-flexwrap .inline-flex.wrap-reverse.row:before, .flexbox.no-flexwrap .inline-flex.wrap-reverse.row:after {
      content: " ";
      display: table; }
    .flexbox.no-flexwrap .flex.wrap-reverse.row:after, .flexbox.no-flexwrap .inline-flex.wrap-reverse.row:after {
      clear: both; }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

body {
  flex-direction: column; }
  .no-flexbox.flexboxlegacy body {
    display: block; }
  body > pre {
    flex: 0 0 auto; }

.flex #Top, .inline-flex #Top {
  flex: 0 0 auto; }

.flex .main, .inline-flex .main {
  flex: 1 0 auto; }

body, nav .horizontal, nav .horizontal li .submenu, .form-group.checkbox, .form-group.radio, .captcha, .flex-form-row, .flex-form-row .form-group.file, .vegas-container .navigation, nav, .gallery-container.flex-gallery .figure, .gallery-container.flex-gallery .figure a, #GuestbookMessages .wrapper_pagination {
  display: flex; }

.gallery-container.flex-gallery .figure a .more-images {
  display: inline-flex; }

.flexible {
  flex: 1 1; }

.inflexible {
  flex: 0;
  flex-shrink: 0; }

.growable, nav .horizontal li {
  flex: 1 0; }

.shrinkable {
  flex: 0 1; }

.relative.flexible, .relative.inflexible, .relative.growable, .relative.shrinkable, nav .horizontal li {
  flex-basis: auto; }

.absolute.flexible, .absolute.inflexible, .absolute.growable, .absolute.shrinkable {
  flex-basis: 0; }

@keyframes scrolltocontent {
  0% {
    transform: translateY(0); }
  20% {
    transform: translateY(0); }
  50% {
    transform: translateY(0); }
  80% {
    transform: translateY(0); }
  100% {
    transform: translateY(0); }
  40% {
    transform: translateY(-30px); }
  60% {
    transform: translateY(-15px); } }

@keyframes flip {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out; }
  40% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out; }
  50% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in; }
  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in; }
  100% {
    transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
    animation-timing-function: ease-in; } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

@keyframes slide-right {
  from {
    transform: translateX(-50%);
    opacity: 0; }
  to {
    transform: translateX(0%);
    opacity: 1; } }

@keyframes slide-left {
  from {
    transform: translateX(50%);
    opacity: 0; }
  to {
    transform: translateX(0%);
    opacity: 1; } }

@keyframes slide-bottom {
  from {
    transform: translateY(-50%);
    opacity: 0; }
  to {
    transform: translateY(0%);
    opacity: 1; } }

@keyframes slide-top {
  from {
    transform: translateY(50%);
    opacity: 0; }
  to {
    transform: translateY(0%);
    opacity: 1; } }

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    transform: perspective(400px); } }

@keyframes fadeOut {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes foldingLeft {
  from {
    opacity: 0;
    transform: skewY(20deg) scale(0.5); }
  to {
    opacity: 1;
    transform: skewY(0deg) scale(1); } }

@keyframes foldingRight {
  from {
    opacity: 0;
    transform: skewY(-20deg) scale(0.5); }
  to {
    opacity: 1;
    transform: skewY(0deg) scale(1); } }

@keyframes rotateLeft {
  from {
    opacity: 0;
    transform: rotateY(18deg); }
  to {
    opacity: 1;
    transform: rotateY(0deg); } }

@keyframes rotateRight {
  from {
    opacity: 0;
    transform: rotateY(-18deg); }
  to {
    opacity: 1;
    transform: rotateY(0deg); } }

@keyframes sepia {
  from {
    filter: sepia(1); }
  to {
    filter: sepia(0); } }

@keyframes blur {
  from {
    filter: blur(5px); }
  to {
    filter: blur(0px); } }

@keyframes blinker {
  50% {
    opacity: 0; } }

@keyframes pulse {
  from {
    transform: perspective(100px) translateZ(25px); }
  to {
    transform: perspective(0px) translateZ(0px); } }

.fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .fluid.large {
    max-width: 1280px; }
  .fluid.larger {
    max-width: 1530px; }
  .fluid.largest {
    max-width: 1937px; }

article p {
  text-align: justify;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -o-hyphens: auto;
  hyphens: auto; }

.text-center .img-responsive {
  margin-left: auto;
  margin-right: auto; }

.text-ellipis {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis; }

.text-shadowen {
  color: white;
  text-shadow: 0px 0px 15px black, 0px 0px 5px rgba(0, 0, 0, 0.75), -1px -1px rgba(0, 0, 0, 0.25), 1px 1px rgba(0, 0, 0, 0.75); }

.text-small-caps {
  font-variant: small-caps; }

.text-air_force_blue {
  color: #5C84B0; }

a.text-air_force_blue:hover,
a.text-air_force_blue:focus {
  color: #476b92; }

.text-dark_cerulean {
  color: #003F7F; }

a.text-dark_cerulean:hover,
a.text-dark_cerulean:focus {
  color: #00264c; }

.text-ivory {
  color: #F4F4F0; }

a.text-ivory:hover,
a.text-ivory:focus {
  color: #deded3; }

.text-pacific_blue {
  color: #0198CC; }

a.text-pacific_blue:hover,
a.text-pacific_blue:focus {
  color: #017299; }

.text-cerulean {
  color: #006CA5; }

a.text-cerulean:hover,
a.text-cerulean:focus {
  color: #004b72; }

.text-pine_cone {
  color: #706558; }

a.text-pine_cone:hover,
a.text-pine_cone:focus {
  color: #534b42; }

.text-skyblue {
  color: #a4d3ee; }

a.text-skyblue:hover,
a.text-skyblue:focus {
  color: #79bee6; }

.text-lightskyblue {
  color: #87cefa; }

a.text-lightskyblue:hover,
a.text-lightskyblue:focus {
  color: #56baf8; }

.text-seagreen {
  color: #54ff9f; }

a.text-seagreen:hover,
a.text-seagreen:focus {
  color: #21ff82; }

.text-darkgray {
  color: #a9a9a9; }

a.text-darkgray:hover,
a.text-darkgray:focus {
  color: #908f8f; }

h1, .h1 {
  font-size: 1.8264705882em; }

h2, .h2 {
  font-size: 1.3681271882em; }

h3, .h3 {
  font-size: 1.024802707em; }

h4, .h4 {
  font-size: 0.7676337385em; }

h5, .h5 {
  font-size: 1.024802707em; }

h6, .h6 {
  font-size: 0.4307067074em; }

h1, .h1 {
  font-size: 2.7em; }

h2, .h2 {
  font-size: 2.0224488869em; }

h3, .h3 {
  font-size: 1.5149257408em; }

h4, .h4 {
  font-size: 1.1347629178em; }

h5, .h5 {
  font-size: 1.5149257408em; }

h6, .h6 {
  font-size: 0.6366968718em; }

@media (min-width: 992px) {
  h1, .h1 {
    font-size: 3.1764705882em; } }

@media (min-width: 992px) {
  h2, .h2 {
    font-size: 2.3793516316em; } }

@media (min-width: 992px) {
  h3, .h3 {
    font-size: 1.7822655774em; } }

@media (min-width: 992px) {
  h4, .h4 {
    font-size: 1.3350151974em; } }

@media (min-width: 992px) {
  h5, .h5 {
    font-size: 1.7822655774em; } }

@media (min-width: 992px) {
  h6, .h6 {
    font-size: 0.7490551433em; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 1.1em; }
  h1.great, h2.great, h3.great, h4.great, h5.great, h6.great, .h1.great, .h2.great, .h3.great, .h4.great, .h5.great, .h6.great {
    margin-top: 2.2em; }
  h1.humble, h2.humble, h3.humble, h4.humble, h5.humble, h6.humble, .h1.humble, .h2.humble, .h3.humble, .h4.humble, .h5.humble, .h6.humble {
    margin-top: 0.55em; }
  h1.zero-top, h2.zero-top, h3.zero-top, h4.zero-top, h5.zero-top, h6.zero-top, .h1.zero-top, .h2.zero-top, .h3.zero-top, .h4.zero-top, .h5.zero-top, .h6.zero-top {
    margin-top: 0 !important; }
  h1.zero-bottom, h2.zero-bottom, h3.zero-bottom, h4.zero-bottom, h5.zero-bottom, h6.zero-bottom, .h1.zero-bottom, .h2.zero-bottom, .h3.zero-bottom, .h4.zero-bottom, .h5.zero-bottom, .h6.zero-bottom {
    margin-bottom: 0 !important; }
  h1 > .subheading, h2 > .subheading, h3 > .subheading, h4 > .subheading, h5 > .subheading, h6 > .subheading, .h1 > .subheading, .h2 > .subheading, .h3 > .subheading, .h4 > .subheading, .h5 > .subheading, .h6 > .subheading {
    display: table;
    margin: auto;
    font-weight: inherit;
    color: #9db5d0; }

h1, h2, h3, .h1, .h2, .h3 {
  margin-bottom: 0.55em; }
  h1.balanced, h2.balanced, h3.balanced, .h1.balanced, .h2.balanced, .h3.balanced {
    margin-top: 0.55em; }
  h1.great, h2.great, h3.great, .h1.great, .h2.great, .h3.great {
    margin-bottom: 1.1em; }
    h1.great.balanced, h2.great.balanced, h3.great.balanced, .h1.great.balanced, .h2.great.balanced, .h3.great.balanced {
      margin-top: 1.1em; }
  h1.humble, h2.humble, h3.humble, .h1.humble, .h2.humble, .h3.humble {
    margin-bottom: 0.275em; }
    h1.humble.balanced, h2.humble.balanced, h3.humble.balanced, .h1.humble.balanced, .h2.humble.balanced, .h3.humble.balanced {
      margin-top: 0.275em; }

h4, h5, h6, .h4, .h5, .h6 {
  margin-bottom: 1.1em; }
  h4.great, h5.great, h6.great, .h4.great, .h5.great, .h6.great {
    margin-bottom: 2.2em; }
  h4.humble, h5.humble, h6.humble, .h4.humble, .h5.humble, .h6.humble {
    margin-bottom: 0.55em; }

.lead {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 1.0588235294em; }

.unicode {
  font-family: "Lucida Grande", "Arial Unicode MS", Arial, sans-serif;
  font-size: 14px;
  padding-left: 5px;
  text-decoration: none !important; }

.img-flex {
  width: 100%;
  height: auto; }

.img-fit {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover;"; }

.disable-transitions {
  -webkit-transition: none !important;
  transition: none !important; }

.no-flexbox.no-flexboxtweener.no-flexboxlegacy nav .horizontal, nav .no-flexbox.no-flexboxtweener.no-flexboxlegacy .horizontal, .lt-ie9 nav .horizontal, nav .lt-ie9 .horizontal, .ie9 nav .horizontal, nav .ie9 .horizontal {
  display: table;
  margin: auto; }

nav .horizontal li {
  position: relative;
  float: left;
  height: 68px;
  line-height: 68px;
  vertical-align: middle;
  font-size: 17px; }
  nav .horizontal li a {
    text-align: center;
    height: 68px;
    display: block; }
    nav .horizontal li a .icon {
      padding-left: 0.5em; }
  nav .horizontal li .submenu {
    background-color: #a4d3ee;
    position: absolute;
    flex-direction: column;
    min-width: 100%; }
    .no-flexboxlegacy nav .horizontal li .submenu li, nav .no-flexboxlegacy .horizontal li .submenu li {
      float: none;
      display: block; }
    nav .horizontal li .submenu li a {
      padding-left: 1em;
      padding-right: 1em; }

nav .horizontal li {
  font-size: 1em; }

form {
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto; }
  form legend {
    margin-bottom: 30px;
    color: #003F7F;
    font-family: 'QuattrocentoSans-Regular', sans-serif;
    border-color: #003F7F; }
  form input[type="text"], form textarea {
    appearance: none; }

.form-group {
  position: relative; }
  .form-group .styled-select {
    position: static;
    overflow: hidden; }
    .form-group .styled-select:after {
      content: ""; }
    .form-group .styled-select select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      appearance: none;
      text-indent: .01px;
      text-overflow: '';
      outline: 0;
      line-height: 1.6;
      position: static; }
      .form-group .styled-select select::-ms-expand {
        display: none; }
      .form-group .styled-select select.input-lg, .form-group .styled-select .input-group-lg > select.form-control,
      .form-group .styled-select .input-group-lg > select.input-group-addon,
      .form-group .styled-select .input-group-lg > .input-group-btn > select.btn {
        line-height: 1.3333333; }
      .form-group .styled-select select.input-sm, .form-group .styled-select .input-group-sm > select.form-control,
      .form-group .styled-select .input-group-sm > select.input-group-addon,
      .form-group .styled-select .input-group-sm > .input-group-btn > select.btn {
        line-height: 1.5; }
      .form-group .styled-select select.indent {
        text-indent: -999em; }
        .form-group .styled-select select.indent _:-ms-input-placeholder, :root .form-group .styled-select select.indent, .ie9 .form-group .styled-select select.indent, .lt-ie9 .form-group .styled-select select.indent {
          color: rgba(64, 191, 191, 0); }
          .form-group .styled-select select.indent _:-ms-input-placeholder option, .form-group .styled-select select.indent _:-ms-input-placeholder optgroup, :root .form-group .styled-select select.indent option, :root .form-group .styled-select select.indent optgroup, .ie9 .form-group .styled-select select.indent option, .ie9 .form-group .styled-select select.indent optgroup, .lt-ie9 .form-group .styled-select select.indent option, .lt-ie9 .form-group .styled-select select.indent optgroup {
            color: #706558; }
        .form-group .styled-select select.indent _:-ms-lang(x), .form-group .styled-select select.indent _:-webkit-full-screen, .form-group .styled-select select.indent {
          color: rgba(64, 191, 191, 0); }
          .form-group .styled-select select.indent _:-ms-lang(x) option, .form-group .styled-select select.indent _:-ms-lang(x) optgroup, .form-group .styled-select select.indent _:-webkit-full-screen option, .form-group .styled-select select.indent _:-webkit-full-screen optgroup, .form-group .styled-select select.indent option, .form-group .styled-select select.indent optgroup {
            color: #706558; }
  .form-group.checkbox, .form-group.radio {
    align-items: center; }
    .form-group.checkbox label, .form-group.radio label {
      padding-left: 0;
      margin-left: 20px; }
    .form-group.checkbox input[type="checkbox"], .form-group.checkbox input[type="radio"], .form-group.radio input[type="checkbox"], .form-group.radio input[type="radio"] {
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -moz-order: -1;
      -ms-flex-order: -1;
      order: -1;
      position: static;
      margin: 0; }
  .form-group.calendar:after {
    content: "";
    color: #5C84B0;
    font-size: 1.0588235294em;
    position: absolute;
    display: inline;
    line-height: 1;
    top: 50%;
    margin-top: -0.5em;
    right: calc(15px + 0.7058823529em);
    pointer-events: none; }
  .form-group.calendar.checked:after {
    content: ""; }
  .form-group.submit-send {
    text-align: center; }

.form-control {
  box-shadow: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .form-control + label {
    position: absolute;
    color: #bfbab4;
    z-index: 51;
    top: 50%;
    left: 30px;
    font-weight: normal;
    pointer-events: none;
    transform: translateY(-50%);
    transform-origin: 0 0; }
  .form-control:focus + label, .form-group.filled .form-control + label {
    line-height: 1.6;
    font-size: 11.5px;
    font-weight: bold;
    color: #5C84B0;
    top: 0%;
    left: 15px;
    transform: translateY(-100%); }
  .form-group.active .form-control:-ms-input-placeholder {
    opacity: 0;
    color: rgba(0, 0, 0, 0); }
  .form-group.active .form-control:-moz-placeholder {
    opacity: 0;
    color: rgba(0, 0, 0, 0); }
  .form-group.active .form-control::-moz-placeholder {
    opacity: 0;
    color: rgba(0, 0, 0, 0); }
  .form-group.active .form-control::-webkit-input-placeholder {
    opacity: 0;
    color: rgba(0, 0, 0, 0); }

textarea.form-control + label {
  display: none !important; }

.footer-form {
  margin-top: 54px;
  font-size: 15px;
  line-height: 19px; }
  .footer-form p {
    margin: 0; }

.captcha {
  min-height: 45px;
  align-items: center; }
  .captcha label {
    margin-bottom: 0;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -moz-order: -1;
    -ms-flex-order: -1;
    order: -1; }
  .captcha label {
    line-height: 30px;
    width: auto; }
  .captcha > * {
    float: left !important; }
  .captcha #captcha-input {
    width: 15%; }
  .captcha #refresh-captcha {
    display: table-cell;
    vertical-align: middle; }
    .captcha #refresh-captcha > .icon-refresh {
      display: block; }
  .captcha .refresh {
    cursor: pointer;
    color: #5C84B0;
    font-size: 1.5em;
    margin-left: 0.5ch;
    display: inline-table;
    line-height: 1; }
  .captcha + * {
    clear: both; }
  .captcha .captcha-image {
    position: relative;
    display: block;
    width: 120px;
    height: 40px; }
    .captcha .captcha-image img {
      max-width: 100%;
      max-height: 100%; }

.input-group.date .form-control[readonly] {
  background: white;
  cursor: pointer; }

.ui-autocomplete {
  z-index: 3 !important; }

.form-wrapper {
  position: relative;
  padding-top: 1.7647058824em;
  padding-bottom: 1.7647058824em;
  background: white;
  position: relative;
  padding: 30px; }
  .form-wrapper > form {
    position: relative;
    z-index: 1; }
  .form-wrapper form {
    margin: auto; }
  .form-wrapper legend {
    text-transform: uppercase;
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: 2px;
    word-spacing: 0.25ch; }
  .form-wrapper.inverse {
    background-color: #5C84B0;
    color: white; }
    .form-wrapper.inverse a {
      color: #aec2d8; }
      .form-wrapper.inverse a:hover {
        color: #becedf; }
  .flex-form-row {
    align-items: center;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px; }
    .flex-form-row.col-1 .form-group {
      width: 100%; }
    .flex-form-row.col-2 .form-group {
      width: 50%; }
    .flex-form-row.col-3 .form-group {
      width: 33.3333333333%; }
    .flex-form-row.col-4 .form-group {
      width: 25%; }
    .flex-form-row.col-5 .form-group {
      width: 20%; }
    .flex-form-row.col-6 .form-group {
      width: 16.6666666667%; }
    .no-flexbox.no-flexboxtweener.no-flexboxlegacy .flex-form-row:before, .no-flexbox.no-flexboxtweener.no-flexboxlegacy .flex-form-row:after, .lt-ie9 .flex-form-row:before, .lt-ie9 .flex-form-row:after, .ie9 .flex-form-row:before, .ie9 .flex-form-row:after {
      content: " ";
      display: table; }
    .no-flexbox.no-flexboxtweener.no-flexboxlegacy .flex-form-row:after, .lt-ie9 .flex-form-row:after, .ie9 .flex-form-row:after {
      clear: both; }
    .flex-form-row .bootstrap-select {
      margin-bottom: 0 !important; }
    .flex-form-row .styledselect:before {
      width: -moz-calc(100% - 30px);
      width: -webkit-calc(100% - 30px);
      width: calc(100% - 30px);
      left: 15px; }
    .flex-form-row .form-group {
      flex: 0 0 auto;
      padding-left: 15px;
      padding-right: 15px; }
      .no-flexbox.no-flexboxtweener.no-flexboxlegacy .flex-form-row .form-group, .lt-ie9 .flex-form-row .form-group, .ie9 .flex-form-row .form-group {
        float: left; }
      .flex-form-row .form-group label {
        margin-bottom: 1px; }
      .flex-form-row .form-group.file {
        align-items: center; }
        .flex-form-row .form-group.file > .btn {
          flex: 1 1 auto; }
  .flex-form .form-group .form-control {
    font-size: inherit;
    padding: 0.3529411765em 0.7058823529em;
    height: 2.4117647059em; }
  .flex-form .form-group.textarea textarea {
    height: auto; }
  .flex-form .form-group.captcha {
    font-size: 1.0588235294em;
    padding-top: 30px;
    border-top: 1px solid #e5e5e5; }
  .flex-form .form-actions {
    font-size: 1.1176470588em;
    text-align: center;
    text-transform: uppercase;
    margin-top: 1em; }
    .flex-form .form-actions .btn {
      min-width: 12ch;
      padding: 0.3529411765em 1.4117647059em;
      font-size: inherit;
      line-height: 1.6;
      border-radius: 0; }
  .datepicker[readonly], .input-daterange input[readonly] {
    background-color: #fff; }
  .inverse form .btn.dropdown-toggle, form.inverse .btn.dropdown-toggle {
    color: #f0f0f0;
    background-color: #92adca;
    border-color: #e6e5e5; }
    .inverse form .btn.dropdown-toggle:hover, .inverse form .btn.dropdown-toggle:focus, .inverse form .btn.dropdown-toggle:active, .inverse form .btn.dropdown-toggle.active,
    .open > .inverse form .btn.dropdown-toggle.dropdown-toggle, form.inverse .btn.dropdown-toggle:hover, form.inverse .btn.dropdown-toggle:focus, form.inverse .btn.dropdown-toggle:active, form.inverse .btn.dropdown-toggle.active,
    .open > form.inverse .btn.dropdown-toggle.dropdown-toggle {
      color: #f0f0f0;
      background-color: #b4c7db;
      border-color: white; }
    .inverse form .btn.dropdown-toggle:active, .inverse form .btn.dropdown-toggle.active,
    .open > .inverse form .btn.dropdown-toggle.dropdown-toggle, form.inverse .btn.dropdown-toggle:active, form.inverse .btn.dropdown-toggle.active,
    .open > form.inverse .btn.dropdown-toggle.dropdown-toggle {
      background-image: none; }
    .inverse form .btn.dropdown-toggle.disabled, .inverse form .btn.dropdown-toggle.disabled:hover, .inverse form .btn.dropdown-toggle.disabled:focus, .inverse form .btn.dropdown-toggle.disabled:active, .inverse form .btn.dropdown-toggle.disabled.active, .inverse form .btn.dropdown-toggle[disabled], .inverse form .btn.dropdown-toggle[disabled]:hover, .inverse form .btn.dropdown-toggle[disabled]:focus, .inverse form .btn.dropdown-toggle[disabled]:active, .inverse form .btn.dropdown-toggle[disabled].active,
    fieldset[disabled] .inverse form .btn.dropdown-toggle,
    fieldset[disabled] .inverse form .btn.dropdown-toggle:hover,
    fieldset[disabled] .inverse form .btn.dropdown-toggle:focus,
    fieldset[disabled] .inverse form .btn.dropdown-toggle:active,
    fieldset[disabled] .inverse form .btn.dropdown-toggle.active, form.inverse .btn.dropdown-toggle.disabled, form.inverse .btn.dropdown-toggle.disabled:hover, form.inverse .btn.dropdown-toggle.disabled:focus, form.inverse .btn.dropdown-toggle.disabled:active, form.inverse .btn.dropdown-toggle.disabled.active, form.inverse .btn.dropdown-toggle[disabled], form.inverse .btn.dropdown-toggle[disabled]:hover, form.inverse .btn.dropdown-toggle[disabled]:focus, form.inverse .btn.dropdown-toggle[disabled]:active, form.inverse .btn.dropdown-toggle[disabled].active,
    fieldset[disabled] form.inverse .btn.dropdown-toggle,
    fieldset[disabled] form.inverse .btn.dropdown-toggle:hover,
    fieldset[disabled] form.inverse .btn.dropdown-toggle:focus,
    fieldset[disabled] form.inverse .btn.dropdown-toggle:active,
    fieldset[disabled] form.inverse .btn.dropdown-toggle.active {
      background-color: #92adca;
      border-color: #e6e5e5; }
    .inverse form .btn.dropdown-toggle .badge, form.inverse .btn.dropdown-toggle .badge {
      color: #92adca;
      background-color: #f0f0f0; }
  .inverse form .btn-default, form.inverse .btn-default {
    color: #5C84B0;
    background-color: #dee6ef;
    border-color: #7799bd; }
    .inverse form .btn-default:hover, .inverse form .btn-default:focus, .inverse form .btn-default:active, .inverse form .btn-default.active,
    .open > .inverse form .btn-default.dropdown-toggle, form.inverse .btn-default:hover, form.inverse .btn-default:focus, form.inverse .btn-default:active, form.inverse .btn-default.active,
    .open > form.inverse .btn-default.dropdown-toggle {
      color: #5C84B0;
      background-color: #fff;
      border-color: #85a3c4; }
    .inverse form .btn-default:active, .inverse form .btn-default.active,
    .open > .inverse form .btn-default.dropdown-toggle, form.inverse .btn-default:active, form.inverse .btn-default.active,
    .open > form.inverse .btn-default.dropdown-toggle {
      background-image: none; }
    .inverse form .btn-default.disabled, .inverse form .btn-default.disabled:hover, .inverse form .btn-default.disabled:focus, .inverse form .btn-default.disabled:active, .inverse form .btn-default.disabled.active, .inverse form .btn-default[disabled], .inverse form .btn-default[disabled]:hover, .inverse form .btn-default[disabled]:focus, .inverse form .btn-default[disabled]:active, .inverse form .btn-default[disabled].active,
    fieldset[disabled] .inverse form .btn-default,
    fieldset[disabled] .inverse form .btn-default:hover,
    fieldset[disabled] .inverse form .btn-default:focus,
    fieldset[disabled] .inverse form .btn-default:active,
    fieldset[disabled] .inverse form .btn-default.active, form.inverse .btn-default.disabled, form.inverse .btn-default.disabled:hover, form.inverse .btn-default.disabled:focus, form.inverse .btn-default.disabled:active, form.inverse .btn-default.disabled.active, form.inverse .btn-default[disabled], form.inverse .btn-default[disabled]:hover, form.inverse .btn-default[disabled]:focus, form.inverse .btn-default[disabled]:active, form.inverse .btn-default[disabled].active,
    fieldset[disabled] form.inverse .btn-default,
    fieldset[disabled] form.inverse .btn-default:hover,
    fieldset[disabled] form.inverse .btn-default:focus,
    fieldset[disabled] form.inverse .btn-default:active,
    fieldset[disabled] form.inverse .btn-default.active {
      background-color: #dee6ef;
      border-color: #7799bd; }
    .inverse form .btn-default .badge, form.inverse .btn-default .badge {
      color: #dee6ef;
      background-color: #5C84B0; }
  .inverse form .form-control:focus, form.inverse .form-control:focus {
    border-color: white;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 255, 255, 0.6); }
  .bg-air_force_blue .inverse form .form-control, .bg-air_force_blue form.inverse .form-control {
    background-color: #dee6ef;
    color: #536980; }
    .bg-air_force_blue .inverse form .form-control:-ms-input-placeholder, .bg-air_force_blue form.inverse .form-control:-ms-input-placeholder {
      color: #3f4f60; }
    .bg-air_force_blue .inverse form .form-control:-moz-placeholder, .bg-air_force_blue form.inverse .form-control:-moz-placeholder {
      color: #3f4f60; }
    .bg-air_force_blue .inverse form .form-control::-moz-placeholder, .bg-air_force_blue form.inverse .form-control::-moz-placeholder {
      color: #3f4f60; }
    .bg-air_force_blue .inverse form .form-control::-webkit-input-placeholder, .bg-air_force_blue form.inverse .form-control::-webkit-input-placeholder {
      color: #3f4f60; }
  .bg-dark_cerulean .inverse form .form-control, .bg-dark_cerulean form.inverse .form-control {
    background-color: #ccd9e5;
    color: #224366; }
    .bg-dark_cerulean .inverse form .form-control:-ms-input-placeholder, .bg-dark_cerulean form.inverse .form-control:-ms-input-placeholder {
      color: #1a334d; }
    .bg-dark_cerulean .inverse form .form-control:-moz-placeholder, .bg-dark_cerulean form.inverse .form-control:-moz-placeholder {
      color: #1a334d; }
    .bg-dark_cerulean .inverse form .form-control::-moz-placeholder, .bg-dark_cerulean form.inverse .form-control::-moz-placeholder {
      color: #1a334d; }
    .bg-dark_cerulean .inverse form .form-control::-webkit-input-placeholder, .bg-dark_cerulean form.inverse .form-control::-webkit-input-placeholder {
      color: #1a334d; }
  .bg-ivory .inverse form .form-control, .bg-ivory form.inverse .form-control {
    background-color: #fdfdfc;
    color: #a4a4a2; }
    .bg-ivory .inverse form .form-control:-ms-input-placeholder, .bg-ivory form.inverse .form-control:-ms-input-placeholder {
      color: #7b7b7a; }
    .bg-ivory .inverse form .form-control:-moz-placeholder, .bg-ivory form.inverse .form-control:-moz-placeholder {
      color: #7b7b7a; }
    .bg-ivory .inverse form .form-control::-moz-placeholder, .bg-ivory form.inverse .form-control::-moz-placeholder {
      color: #7b7b7a; }
    .bg-ivory .inverse form .form-control::-webkit-input-placeholder, .bg-ivory form.inverse .form-control::-webkit-input-placeholder {
      color: #7b7b7a; }
  .bg-pacific_blue .inverse form .form-control, .bg-pacific_blue form.inverse .form-control {
    background-color: #cceaf5;
    color: #23738f; }
    .bg-pacific_blue .inverse form .form-control:-ms-input-placeholder, .bg-pacific_blue form.inverse .form-control:-ms-input-placeholder {
      color: #1a576b; }
    .bg-pacific_blue .inverse form .form-control:-moz-placeholder, .bg-pacific_blue form.inverse .form-control:-moz-placeholder {
      color: #1a576b; }
    .bg-pacific_blue .inverse form .form-control::-moz-placeholder, .bg-pacific_blue form.inverse .form-control::-moz-placeholder {
      color: #1a576b; }
    .bg-pacific_blue .inverse form .form-control::-webkit-input-placeholder, .bg-pacific_blue form.inverse .form-control::-webkit-input-placeholder {
      color: #1a576b; }
  .bg-cerulean .inverse form .form-control, .bg-cerulean form.inverse .form-control {
    background-color: #cce2ed;
    color: #225b7a; }
    .bg-cerulean .inverse form .form-control:-ms-input-placeholder, .bg-cerulean form.inverse .form-control:-ms-input-placeholder {
      color: #1a455c; }
    .bg-cerulean .inverse form .form-control:-moz-placeholder, .bg-cerulean form.inverse .form-control:-moz-placeholder {
      color: #1a455c; }
    .bg-cerulean .inverse form .form-control::-moz-placeholder, .bg-cerulean form.inverse .form-control::-moz-placeholder {
      color: #1a455c; }
    .bg-cerulean .inverse form .form-control::-webkit-input-placeholder, .bg-cerulean form.inverse .form-control::-webkit-input-placeholder {
      color: #1a455c; }
  .bg-pine_cone .inverse form .form-control, .bg-pine_cone form.inverse .form-control {
    background-color: #e2e0de;
    color: #5e5851; }
    .bg-pine_cone .inverse form .form-control:-ms-input-placeholder, .bg-pine_cone form.inverse .form-control:-ms-input-placeholder {
      color: #47423d; }
    .bg-pine_cone .inverse form .form-control:-moz-placeholder, .bg-pine_cone form.inverse .form-control:-moz-placeholder {
      color: #47423d; }
    .bg-pine_cone .inverse form .form-control::-moz-placeholder, .bg-pine_cone form.inverse .form-control::-moz-placeholder {
      color: #47423d; }
    .bg-pine_cone .inverse form .form-control::-webkit-input-placeholder, .bg-pine_cone form.inverse .form-control::-webkit-input-placeholder {
      color: #47423d; }
  .bg-skyblue .inverse form .form-control, .bg-skyblue form.inverse .form-control {
    background-color: #edf6fc;
    color: #7993a1; }
    .bg-skyblue .inverse form .form-control:-ms-input-placeholder, .bg-skyblue form.inverse .form-control:-ms-input-placeholder {
      color: #5b6e79; }
    .bg-skyblue .inverse form .form-control:-moz-placeholder, .bg-skyblue form.inverse .form-control:-moz-placeholder {
      color: #5b6e79; }
    .bg-skyblue .inverse form .form-control::-moz-placeholder, .bg-skyblue form.inverse .form-control::-moz-placeholder {
      color: #5b6e79; }
    .bg-skyblue .inverse form .form-control::-webkit-input-placeholder, .bg-skyblue form.inverse .form-control::-webkit-input-placeholder {
      color: #5b6e79; }
  .bg-lightskyblue .inverse form .form-control, .bg-lightskyblue form.inverse .form-control {
    background-color: #e7f5fe;
    color: #6a90a7; }
    .bg-lightskyblue .inverse form .form-control:-ms-input-placeholder, .bg-lightskyblue form.inverse .form-control:-ms-input-placeholder {
      color: #506c7e; }
    .bg-lightskyblue .inverse form .form-control:-moz-placeholder, .bg-lightskyblue form.inverse .form-control:-moz-placeholder {
      color: #506c7e; }
    .bg-lightskyblue .inverse form .form-control::-moz-placeholder, .bg-lightskyblue form.inverse .form-control::-moz-placeholder {
      color: #506c7e; }
    .bg-lightskyblue .inverse form .form-control::-webkit-input-placeholder, .bg-lightskyblue form.inverse .form-control::-webkit-input-placeholder {
      color: #506c7e; }
  .bg-seagreen .inverse form .form-control, .bg-seagreen form.inverse .form-control {
    background-color: #ddffec;
    color: #4faa77; }
    .bg-seagreen .inverse form .form-control:-ms-input-placeholder, .bg-seagreen form.inverse .form-control:-ms-input-placeholder {
      color: #3b8059; }
    .bg-seagreen .inverse form .form-control:-moz-placeholder, .bg-seagreen form.inverse .form-control:-moz-placeholder {
      color: #3b8059; }
    .bg-seagreen .inverse form .form-control::-moz-placeholder, .bg-seagreen form.inverse .form-control::-moz-placeholder {
      color: #3b8059; }
    .bg-seagreen .inverse form .form-control::-webkit-input-placeholder, .bg-seagreen form.inverse .form-control::-webkit-input-placeholder {
      color: #3b8059; }
  .bg-darkgray .inverse form .form-control, .bg-darkgray form.inverse .form-control {
    background-color: #eeeeee;
    color: #7c7c7c; }
    .bg-darkgray .inverse form .form-control:-ms-input-placeholder, .bg-darkgray form.inverse .form-control:-ms-input-placeholder {
      color: #5d5d5d; }
    .bg-darkgray .inverse form .form-control:-moz-placeholder, .bg-darkgray form.inverse .form-control:-moz-placeholder {
      color: #5d5d5d; }
    .bg-darkgray .inverse form .form-control::-moz-placeholder, .bg-darkgray form.inverse .form-control::-moz-placeholder {
      color: #5d5d5d; }
    .bg-darkgray .inverse form .form-control::-webkit-input-placeholder, .bg-darkgray form.inverse .form-control::-webkit-input-placeholder {
      color: #5d5d5d; }
  .inverse form .form-control:-ms-input-placeholder, form.inverse .form-control:-ms-input-placeholder {
    font-style: italic; }
  .inverse form .form-control:-moz-placeholder, form.inverse .form-control:-moz-placeholder {
    font-style: italic; }
  .inverse form .form-control::-moz-placeholder, form.inverse .form-control::-moz-placeholder {
    font-style: italic; }
  .inverse form .form-control::-webkit-input-placeholder, form.inverse .form-control::-webkit-input-placeholder {
    font-style: italic; }
  .bg-air_force_blue .inverse form .form-group label, .bg-air_force_blue form.inverse .form-group label {
    color: #3f4f60;
    font-style: italic; }
    .bg-air_force_blue .inverse form .form-group label[for="privacy"], .bg-air_force_blue form.inverse .form-group label[for="privacy"] {
      color: #dee6ef;
      font-style: normal; }
  .bg-air_force_blue .inverse form .form-group.styledselect label, .bg-air_force_blue form.inverse .form-group.styledselect label {
    color: #3f4f60; }
  .bg-air_force_blue .inverse form .form-group.calendar:after, .bg-air_force_blue .inverse form .form-group .styled-select:after, .bg-air_force_blue form.inverse .form-group.calendar:after, .bg-air_force_blue form.inverse .form-group .styled-select:after {
    color: #536980; }
  .bg-air_force_blue .inverse form .form-group.captcha, .bg-air_force_blue form.inverse .form-group.captcha {
    border-top-color: rgba(222, 230, 239, 0.5); }
    .bg-air_force_blue .inverse form .form-group.captcha label, .bg-air_force_blue form.inverse .form-group.captcha label {
      color: #dee6ef;
      font-style: normal; }
    .bg-air_force_blue .inverse form .form-group.captcha .refresh, .bg-air_force_blue form.inverse .form-group.captcha .refresh {
      color: #dee6ef; }
  .bg-dark_cerulean .inverse form .form-group label, .bg-dark_cerulean form.inverse .form-group label {
    color: #1a334d;
    font-style: italic; }
    .bg-dark_cerulean .inverse form .form-group label[for="privacy"], .bg-dark_cerulean form.inverse .form-group label[for="privacy"] {
      color: #ccd9e5;
      font-style: normal; }
  .bg-dark_cerulean .inverse form .form-group.styledselect label, .bg-dark_cerulean form.inverse .form-group.styledselect label {
    color: #1a334d; }
  .bg-dark_cerulean .inverse form .form-group.calendar:after, .bg-dark_cerulean .inverse form .form-group .styled-select:after, .bg-dark_cerulean form.inverse .form-group.calendar:after, .bg-dark_cerulean form.inverse .form-group .styled-select:after {
    color: #224366; }
  .bg-dark_cerulean .inverse form .form-group.captcha, .bg-dark_cerulean form.inverse .form-group.captcha {
    border-top-color: rgba(204, 217, 229, 0.5); }
    .bg-dark_cerulean .inverse form .form-group.captcha label, .bg-dark_cerulean form.inverse .form-group.captcha label {
      color: #ccd9e5;
      font-style: normal; }
    .bg-dark_cerulean .inverse form .form-group.captcha .refresh, .bg-dark_cerulean form.inverse .form-group.captcha .refresh {
      color: #ccd9e5; }
  .bg-ivory .inverse form .form-group label, .bg-ivory form.inverse .form-group label {
    color: #7b7b7a;
    font-style: italic; }
    .bg-ivory .inverse form .form-group label[for="privacy"], .bg-ivory form.inverse .form-group label[for="privacy"] {
      color: #fdfdfc;
      font-style: normal; }
  .bg-ivory .inverse form .form-group.styledselect label, .bg-ivory form.inverse .form-group.styledselect label {
    color: #7b7b7a; }
  .bg-ivory .inverse form .form-group.calendar:after, .bg-ivory .inverse form .form-group .styled-select:after, .bg-ivory form.inverse .form-group.calendar:after, .bg-ivory form.inverse .form-group .styled-select:after {
    color: #a4a4a2; }
  .bg-ivory .inverse form .form-group.captcha, .bg-ivory form.inverse .form-group.captcha {
    border-top-color: rgba(253, 253, 252, 0.5); }
    .bg-ivory .inverse form .form-group.captcha label, .bg-ivory form.inverse .form-group.captcha label {
      color: #fdfdfc;
      font-style: normal; }
    .bg-ivory .inverse form .form-group.captcha .refresh, .bg-ivory form.inverse .form-group.captcha .refresh {
      color: #fdfdfc; }
  .bg-pacific_blue .inverse form .form-group label, .bg-pacific_blue form.inverse .form-group label {
    color: #1a576b;
    font-style: italic; }
    .bg-pacific_blue .inverse form .form-group label[for="privacy"], .bg-pacific_blue form.inverse .form-group label[for="privacy"] {
      color: #cceaf5;
      font-style: normal; }
  .bg-pacific_blue .inverse form .form-group.styledselect label, .bg-pacific_blue form.inverse .form-group.styledselect label {
    color: #1a576b; }
  .bg-pacific_blue .inverse form .form-group.calendar:after, .bg-pacific_blue .inverse form .form-group .styled-select:after, .bg-pacific_blue form.inverse .form-group.calendar:after, .bg-pacific_blue form.inverse .form-group .styled-select:after {
    color: #23738f; }
  .bg-pacific_blue .inverse form .form-group.captcha, .bg-pacific_blue form.inverse .form-group.captcha {
    border-top-color: rgba(204, 234, 245, 0.5); }
    .bg-pacific_blue .inverse form .form-group.captcha label, .bg-pacific_blue form.inverse .form-group.captcha label {
      color: #cceaf5;
      font-style: normal; }
    .bg-pacific_blue .inverse form .form-group.captcha .refresh, .bg-pacific_blue form.inverse .form-group.captcha .refresh {
      color: #cceaf5; }
  .bg-cerulean .inverse form .form-group label, .bg-cerulean form.inverse .form-group label {
    color: #1a455c;
    font-style: italic; }
    .bg-cerulean .inverse form .form-group label[for="privacy"], .bg-cerulean form.inverse .form-group label[for="privacy"] {
      color: #cce2ed;
      font-style: normal; }
  .bg-cerulean .inverse form .form-group.styledselect label, .bg-cerulean form.inverse .form-group.styledselect label {
    color: #1a455c; }
  .bg-cerulean .inverse form .form-group.calendar:after, .bg-cerulean .inverse form .form-group .styled-select:after, .bg-cerulean form.inverse .form-group.calendar:after, .bg-cerulean form.inverse .form-group .styled-select:after {
    color: #225b7a; }
  .bg-cerulean .inverse form .form-group.captcha, .bg-cerulean form.inverse .form-group.captcha {
    border-top-color: rgba(204, 226, 237, 0.5); }
    .bg-cerulean .inverse form .form-group.captcha label, .bg-cerulean form.inverse .form-group.captcha label {
      color: #cce2ed;
      font-style: normal; }
    .bg-cerulean .inverse form .form-group.captcha .refresh, .bg-cerulean form.inverse .form-group.captcha .refresh {
      color: #cce2ed; }
  .bg-pine_cone .inverse form .form-group label, .bg-pine_cone form.inverse .form-group label {
    color: #47423d;
    font-style: italic; }
    .bg-pine_cone .inverse form .form-group label[for="privacy"], .bg-pine_cone form.inverse .form-group label[for="privacy"] {
      color: #e2e0de;
      font-style: normal; }
  .bg-pine_cone .inverse form .form-group.styledselect label, .bg-pine_cone form.inverse .form-group.styledselect label {
    color: #47423d; }
  .bg-pine_cone .inverse form .form-group.calendar:after, .bg-pine_cone .inverse form .form-group .styled-select:after, .bg-pine_cone form.inverse .form-group.calendar:after, .bg-pine_cone form.inverse .form-group .styled-select:after {
    color: #5e5851; }
  .bg-pine_cone .inverse form .form-group.captcha, .bg-pine_cone form.inverse .form-group.captcha {
    border-top-color: rgba(226, 224, 222, 0.5); }
    .bg-pine_cone .inverse form .form-group.captcha label, .bg-pine_cone form.inverse .form-group.captcha label {
      color: #e2e0de;
      font-style: normal; }
    .bg-pine_cone .inverse form .form-group.captcha .refresh, .bg-pine_cone form.inverse .form-group.captcha .refresh {
      color: #e2e0de; }
  .bg-skyblue .inverse form .form-group label, .bg-skyblue form.inverse .form-group label {
    color: #5b6e79;
    font-style: italic; }
    .bg-skyblue .inverse form .form-group label[for="privacy"], .bg-skyblue form.inverse .form-group label[for="privacy"] {
      color: #edf6fc;
      font-style: normal; }
  .bg-skyblue .inverse form .form-group.styledselect label, .bg-skyblue form.inverse .form-group.styledselect label {
    color: #5b6e79; }
  .bg-skyblue .inverse form .form-group.calendar:after, .bg-skyblue .inverse form .form-group .styled-select:after, .bg-skyblue form.inverse .form-group.calendar:after, .bg-skyblue form.inverse .form-group .styled-select:after {
    color: #7993a1; }
  .bg-skyblue .inverse form .form-group.captcha, .bg-skyblue form.inverse .form-group.captcha {
    border-top-color: rgba(237, 246, 252, 0.5); }
    .bg-skyblue .inverse form .form-group.captcha label, .bg-skyblue form.inverse .form-group.captcha label {
      color: #edf6fc;
      font-style: normal; }
    .bg-skyblue .inverse form .form-group.captcha .refresh, .bg-skyblue form.inverse .form-group.captcha .refresh {
      color: #edf6fc; }
  .bg-lightskyblue .inverse form .form-group label, .bg-lightskyblue form.inverse .form-group label {
    color: #506c7e;
    font-style: italic; }
    .bg-lightskyblue .inverse form .form-group label[for="privacy"], .bg-lightskyblue form.inverse .form-group label[for="privacy"] {
      color: #e7f5fe;
      font-style: normal; }
  .bg-lightskyblue .inverse form .form-group.styledselect label, .bg-lightskyblue form.inverse .form-group.styledselect label {
    color: #506c7e; }
  .bg-lightskyblue .inverse form .form-group.calendar:after, .bg-lightskyblue .inverse form .form-group .styled-select:after, .bg-lightskyblue form.inverse .form-group.calendar:after, .bg-lightskyblue form.inverse .form-group .styled-select:after {
    color: #6a90a7; }
  .bg-lightskyblue .inverse form .form-group.captcha, .bg-lightskyblue form.inverse .form-group.captcha {
    border-top-color: rgba(231, 245, 254, 0.5); }
    .bg-lightskyblue .inverse form .form-group.captcha label, .bg-lightskyblue form.inverse .form-group.captcha label {
      color: #e7f5fe;
      font-style: normal; }
    .bg-lightskyblue .inverse form .form-group.captcha .refresh, .bg-lightskyblue form.inverse .form-group.captcha .refresh {
      color: #e7f5fe; }
  .bg-seagreen .inverse form .form-group label, .bg-seagreen form.inverse .form-group label {
    color: #3b8059;
    font-style: italic; }
    .bg-seagreen .inverse form .form-group label[for="privacy"], .bg-seagreen form.inverse .form-group label[for="privacy"] {
      color: #ddffec;
      font-style: normal; }
  .bg-seagreen .inverse form .form-group.styledselect label, .bg-seagreen form.inverse .form-group.styledselect label {
    color: #3b8059; }
  .bg-seagreen .inverse form .form-group.calendar:after, .bg-seagreen .inverse form .form-group .styled-select:after, .bg-seagreen form.inverse .form-group.calendar:after, .bg-seagreen form.inverse .form-group .styled-select:after {
    color: #4faa77; }
  .bg-seagreen .inverse form .form-group.captcha, .bg-seagreen form.inverse .form-group.captcha {
    border-top-color: rgba(221, 255, 236, 0.5); }
    .bg-seagreen .inverse form .form-group.captcha label, .bg-seagreen form.inverse .form-group.captcha label {
      color: #ddffec;
      font-style: normal; }
    .bg-seagreen .inverse form .form-group.captcha .refresh, .bg-seagreen form.inverse .form-group.captcha .refresh {
      color: #ddffec; }
  .bg-darkgray .inverse form .form-group label, .bg-darkgray form.inverse .form-group label {
    color: #5d5d5d;
    font-style: italic; }
    .bg-darkgray .inverse form .form-group label[for="privacy"], .bg-darkgray form.inverse .form-group label[for="privacy"] {
      color: #eeeeee;
      font-style: normal; }
  .bg-darkgray .inverse form .form-group.styledselect label, .bg-darkgray form.inverse .form-group.styledselect label {
    color: #5d5d5d; }
  .bg-darkgray .inverse form .form-group.calendar:after, .bg-darkgray .inverse form .form-group .styled-select:after, .bg-darkgray form.inverse .form-group.calendar:after, .bg-darkgray form.inverse .form-group .styled-select:after {
    color: #7c7c7c; }
  .bg-darkgray .inverse form .form-group.captcha, .bg-darkgray form.inverse .form-group.captcha {
    border-top-color: rgba(238, 238, 238, 0.5); }
    .bg-darkgray .inverse form .form-group.captcha label, .bg-darkgray form.inverse .form-group.captcha label {
      color: #eeeeee;
      font-style: normal; }
    .bg-darkgray .inverse form .form-group.captcha .refresh, .bg-darkgray form.inverse .form-group.captcha .refresh {
      color: #eeeeee; }
  .inverse form .form-group label, form.inverse .form-group label {
    font-style: italic; }
  .inverse form .form-group.filled label, .inverse form .form-group.active label, form.inverse .form-group.filled label, form.inverse .form-group.active label {
    color: white !important;
    font-stile: normal; }
  .bg-air_force_blue .inverse form legend, .bg-air_force_blue form.inverse legend {
    color: #dee6ef;
    border-bottom-color: rgba(222, 230, 239, 0.5); }
  .bg-dark_cerulean .inverse form legend, .bg-dark_cerulean form.inverse legend {
    color: #ccd9e5;
    border-bottom-color: rgba(204, 217, 229, 0.5); }
  .bg-ivory .inverse form legend, .bg-ivory form.inverse legend {
    color: #fdfdfc;
    border-bottom-color: rgba(253, 253, 252, 0.5); }
  .bg-pacific_blue .inverse form legend, .bg-pacific_blue form.inverse legend {
    color: #cceaf5;
    border-bottom-color: rgba(204, 234, 245, 0.5); }
  .bg-cerulean .inverse form legend, .bg-cerulean form.inverse legend {
    color: #cce2ed;
    border-bottom-color: rgba(204, 226, 237, 0.5); }
  .bg-pine_cone .inverse form legend, .bg-pine_cone form.inverse legend {
    color: #e2e0de;
    border-bottom-color: rgba(226, 224, 222, 0.5); }
  .bg-skyblue .inverse form legend, .bg-skyblue form.inverse legend {
    color: #edf6fc;
    border-bottom-color: rgba(237, 246, 252, 0.5); }
  .bg-lightskyblue .inverse form legend, .bg-lightskyblue form.inverse legend {
    color: #e7f5fe;
    border-bottom-color: rgba(231, 245, 254, 0.5); }
  .bg-seagreen .inverse form legend, .bg-seagreen form.inverse legend {
    color: #ddffec;
    border-bottom-color: rgba(221, 255, 236, 0.5); }
  .bg-darkgray .inverse form legend, .bg-darkgray form.inverse legend {
    color: #eeeeee;
    border-bottom-color: rgba(238, 238, 238, 0.5); }

.checkbox-privacy {
  font-size: 0.9117647059em; }

.legenda-campi-richiesti {
  font-size: 0.9117647059em;
  max-width: 1110px;
  margin-top: 1.2903225806em;
  margin-left: auto;
  margin-right: auto; }

.form-group .styled-select:after {
  font-size: 1.0588235294em;
  position: absolute;
  display: block;
  line-height: 1;
  top: 50%;
  margin-top: -0.5em;
  right: calc(15px + 0.7058823529em);
  pointer-events: none;
  color: #5C84B0; }

.main {
  position: relative;
  z-index: 100;
  background-color: transparent;
  flex: 1 0 auto;
  background-color: #F4F4F0;
  margin-top: calc((4.3529411765em)*-1); }
  .has-block-affix .main {
    z-index: 100;
    margin-top: calc(7.9411764706em); }
  .main .section-default.animated-element.has-animation {
    animation: slideInUp 1 both;
    animation-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);
    animation-duration: 1.5s; }
  .main .section-default .wrapper-outer {
    margin: 1.7647058824em auto;
    border: solid 20px rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.8);
    padding: 6px;
    background-clip: content-box; }
    .main .section-default .wrapper-outer .wrapper-inner {
      padding: 3.5294117647em; }
      .main .section-default .wrapper-outer .wrapper-inner .lemma + p {
        margin-top: 2.6470588235em; }
  .main #ServicesHome {
    margin-top: 5.8823529412em; }
    .main #ServicesHome .service {
      position: relative;
      margin-bottom: 4.9411764706em; }
      .main #ServicesHome .service:after {
        content: "";
        width: 90%;
        height: 0.3529411765em;
        background-color: white;
        position: absolute;
        left: 15px;
        bottom: 2.3529411765em;
        z-index: 1; }
      .main #ServicesHome .service.normal:after {
        left: auto;
        right: 30px; }
      .main #ServicesHome .service.animated-element .image {
        opacity: 0; }
      .main #ServicesHome .service.animated-element .content .lemma:before {
        opacity: 0; }
      .main #ServicesHome .service.animated-element:after {
        opacity: 0; }
      .main #ServicesHome .service.animated-element.has-animation .image {
        animation: slide-right 1 both;
        animation-delay: 0.5s;
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-duration: 1.3s; }
      .main #ServicesHome .service.animated-element.has-animation .content .lemma:before {
        animation: foldingLeft 1 both;
        animation-delay: 0.5s;
        animation-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
        animation-duration: 1.5s; }
      .main #ServicesHome .service.animated-element.has-animation:after {
        animation: slide-left 1 both;
        animation-delay: 1s;
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-duration: 1s; }
      .main #ServicesHome .service.animated-element.reverse.has-animation .image {
        animation: slide-left 1 both;
        animation-delay: 0.5s;
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-duration: 1.3s; }
      .main #ServicesHome .service.animated-element.reverse.has-animation:after {
        animation: slide-right 1 both;
        animation-delay: 1s;
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        animation-duration: 1s; }
      .main #ServicesHome .service .image {
        position: relative; }
        .main #ServicesHome .service .image .embed-responsive {
          position: static;
          width: 100%; }
          .main #ServicesHome .service .image .embed-responsive img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; }
        @media (max-width: 991px) {
          .main #ServicesHome .service .image .embed-responsive {
            padding-bottom: 75%; } }
      .main #ServicesHome .service .content {
        position: relative;
        padding-bottom: 2.3529411765em;
        padding-top: 6.4705882353em; }
        .main #ServicesHome .service .content .lemma {
          position: relative;
          margin-left: 0.660098362em; }
          .main #ServicesHome .service .content .lemma:before {
            content: attr(data-title);
            position: absolute;
            top: -0.2727272727em;
            left: 0.3636363636em;
            width: 100%;
            height: 100%;
            color: rgba(112, 101, 88, 0.1);
            font-size: 1.8152704955em;
            z-index: -1;
            white-space: nowrap; }
          @media (max-width: 1199px) {
            .main #ServicesHome .service .content .lemma:before {
              top: -0.075em;
              left: 0.5em;
              font-size: 1.320196724em; } }
        .main #ServicesHome .service .content .wrapper_content {
          position: relative;
          background-color: #FFFFFF;
          padding: 2.9411764706em; }
          .main #ServicesHome .service .content .wrapper_content:before {
            content: "";
            width: 11.0588235294em;
            height: 11.0588235294em;
            border: 0.4705882353em solid white;
            position: absolute;
            left: 3.2352941176em;
            top: -40%;
            z-index: -1; }
          @media (max-width: 1199px) {
            .main #ServicesHome .service .content .wrapper_content:before {
              top: -30%; } }
          @media (max-width: 991px) {
            .main #ServicesHome .service .content .wrapper_content:before {
              top: -25%; } }
          .main #ServicesHome .service .content .wrapper_content .content_text {
            line-height: 2;
            color: #003F7F;
            font-size: 1.1764705882em; }
            .main #ServicesHome .service .content .wrapper_content .content_text p {
              text-align: center; }
  .main #ArrangerOfferGallery .wrapper > article.flex {
    padding: 0; }
  .main #ArrangerOfferGallery .gallery-container .figure img {
    height: 100%;
    object-fit: cover; }
  @media (max-width: 991px) {
    .main #ArrangerOfferGallery .content {
      padding: 0;
      margin-top: 0.8823529412em; } }
  .main #PanoramicHome {
    position: relative; }
    .main #PanoramicHome.animated-element #CDSWIDSSP {
      opacity: 0; }
    .main #PanoramicHome.animated-element.has-animation #CDSWIDSSP {
      animation: foldingLeft 1 both;
      animation-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);
      animation-duration: 1.5s;
      animation-delay: 0.5s; }
    .main #PanoramicHome .parallax-mask {
      position: relative;
      overflow: hidden;
      height: 52vh; }
      @media (max-width: 991px) {
        .main #PanoramicHome .parallax-mask {
          height: 40vh; } }
      .main #PanoramicHome .parallax-mask .parallax {
        position: absolute;
        -webkit-transition: 0.1s;
        transition: 0.1s;
        transform: translate3d(0px, 0px, 0px);
        width: 100%;
        height: 120%; }
        .main #PanoramicHome .parallax-mask .parallax img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .main #PanoramicHome .parallax-mask .wrapper-tripadvisor {
        z-index: 1;
        margin-left: 2.9411764706em;
        width: 30%; }
        @media (max-width: 1199px) {
          .main #PanoramicHome .parallax-mask .wrapper-tripadvisor {
            width: 40%; } }
        @media (max-width: 991px) {
          .main #PanoramicHome .parallax-mask .wrapper-tripadvisor {
            width: 50%; } }
        .main #PanoramicHome .parallax-mask .wrapper-tripadvisor #CDSWIDSSP {
          position: relative;
          width: 100% !important; }
          .main #PanoramicHome .parallax-mask .wrapper-tripadvisor #CDSWIDSSP:before {
            content: "";
            position: absolute;
            left: 1.1764705882em;
            top: 1.1764705882em;
            width: 46px;
            height: 31px;
            background: url("/assets/images/layout/left-quote.png") no-repeat;
            background-size: 100% 100%;
            z-index: 1; }
          .main #PanoramicHome .parallax-mask .wrapper-tripadvisor #CDSWIDSSP:after {
            content: "";
            position: absolute;
            right: 1.1764705882em;
            bottom: 1.1764705882em;
            width: 46px;
            height: 31px;
            background: url("/assets/images/layout/right-quote.png") no-repeat;
            background-size: 100% 100%;
            z-index: 1; }
          .main #PanoramicHome .parallax-mask .wrapper-tripadvisor #CDSWIDSSP .widSSPData {
            border: none !important;
            background-color: rgba(255, 255, 255, 0.9);
            padding: 2.9411764706em; }
            .main #PanoramicHome .parallax-mask .wrapper-tripadvisor #CDSWIDSSP .widSSPData .widSSPBranding {
              border: none; }
              .main #PanoramicHome .parallax-mask .wrapper-tripadvisor #CDSWIDSSP .widSSPData .widSSPBranding dl dt {
                margin-left: auto;
                margin-right: auto;
                text-align: center; }
                .main #PanoramicHome .parallax-mask .wrapper-tripadvisor #CDSWIDSSP .widSSPData .widSSPBranding dl dt img {
                  background-color: transparent; }
              .main #PanoramicHome .parallax-mask .wrapper-tripadvisor #CDSWIDSSP .widSSPData .widSSPBranding .widSSPTagline {
                display: none; }
            .main #PanoramicHome .parallax-mask .wrapper-tripadvisor #CDSWIDSSP .widSSPData .widSSPComponent .widSSPSummary {
              text-align: center; }
              .main #PanoramicHome .parallax-mask .wrapper-tripadvisor #CDSWIDSSP .widSSPData .widSSPComponent .widSSPSummary a {
                text-decoration: none; }
                .main #PanoramicHome .parallax-mask .wrapper-tripadvisor #CDSWIDSSP .widSSPData .widSSPComponent .widSSPSummary a dt {
                  font-size: 20px; }

.body-home .main .section-default .wrapper {
  position: relative;
  padding-bottom: 8.2352941176em; }
  .body-home .main .section-default .wrapper:after {
    content: "";
    width: 9.0588235294em;
    height: 6.5882352941em;
    background: url("/assets/images/layout/logo-bandiera-blue.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: 1.1764705882em;
    left: 50%;
    margin-left: -4.5294117647em; }

.body-beach .main .section-default .wrapper {
  position: relative;
  padding-bottom: 5.8823529412em; }
  .body-beach .main .section-default .wrapper:after {
    content: "";
    width: 18.1176470588em;
    height: 8.7058823529em;
    background: url("/assets/images/layout/decorator_beach.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: -4.3529411765em;
    left: 50%;
    margin-left: -9.0588235294em; }

.body-beach .main .section-default + #Gallery {
  margin: 4.7058823529em auto; }

.svg-loader.svg-embed:before {
  display: inline-block;
  content: svg-load("images/svg/loader.svg"); }

.svg-embed {
  position: relative;
  display: inline-block;
  font-size: 2.3793516316em;
  width: 1em; }
  .svg-embed:before {
    width: 1em;
    height: 1em; }

.vegas-container .navigation {
  position: absolute;
  width: 100%; }
  .vegas-container .navigation .nav-item {
    width: 1em;
    height: 1em;
    text-align: center;
    margin: 0.25em 0.125em;
    color: white;
    cursor: pointer; }

#Top.has-slider {
  width: 100%;
  height: 300px;
  position: relative;
  z-index: 1; }

@media (orientation: portrait) and (min-height: 625px) {
  #Top.has-slider {
    height: 48%;
    height: 48vh; } }

@media (orientation: landscape) and (min-height: 428.571428571px) {
  #Top.has-slider {
    height: 70%;
    height: 70vh; } }

.new-modal.modal-dialog {
  width: 100% !important;
  padding-left: 5%;
  padding-right: 5%;
  margin: 6vh auto; }
  .new-modal.modal-dialog .new-modal-content {
    position: relative;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    background: white;
    padding: 2.3529411765em; }
    .new-modal.modal-dialog .new-modal-content .close {
      display: block !important; }

.new-modal.modal-dialog .new-modal-content .close {
  font-size: inherit;
  position: absolute;
  top: 0;
  right: 0;
  width: 2.3529411765em;
  height: 2.3529411765em;
  background: #5C84B0;
  opacity: 1; }
  .new-modal.modal-dialog .new-modal-content .close span {
    display: inline-block;
    text-indent: -999em; }
  .new-modal.modal-dialog .new-modal-content .close:hover {
    background: #3f5e81; }
  .new-modal.modal-dialog .new-modal-content .close:before {
    content: svg-load("images/svg/close.svg", fill=white);
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
    pointer-events: none; }

#cookieChoiceInfo:before {
  content: "";
  display: inline-block;
  background: svg-load("images/svg/cookiechoices_icon.svg") no-repeat;
  background-size: cover;
  width: 32px;
  height: 32px;
  float: left;
  margin-top: 0.75em;
  margin-right: 1em; }

.booking-bar {
  position: relative;
  height: 74px;
  padding: 0.2941176471em;
  margin: 0 1.4705882353em;
  background-color: rgba(0, 63, 127, 0.9);
  z-index: 1012; }
  .has-block-affix .booking-bar {
    margin: 0; }
  .booking-bar #BookingBar {
    z-index: 102;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    width: 100%;
    padding-right: 70px; }
    .booking-bar #BookingBar > .flex {
      flex: 1 0 auto; }
    #Top.affix + .booking-bar #BookingBar {
      position: fixed;
      left: 0;
      width: 100%;
      margin-top: 4em;
      background-color: #006CA5;
      box-shadow: 0 8px 16px -6px rgba(0, 0, 0, 0.5); }
    .booking-bar #BookingBar.dinamic-affix {
      position: fixed;
      left: 0;
      width: 100%;
      top: 4em;
      margin-top: 0 !important;
      -webkit-transform: translateZ(0);
      background-color: #006CA5;
      box-shadow: 0 8px 16px -6px rgba(0, 0, 0, 0.5); }

#sb-container.custom-sb-container {
  width: 100%;
  min-width: 680px; }
  #BookingBar #sb-container.custom-sb-container .sb {
    width: 100%;
    justify-content: center;
    padding: 0px 20px; }
    #BookingBar #sb-container.custom-sb-container .sb, #BookingBar #sb-container.custom-sb-container .sb #sb-container_sb__form, #BookingBar #sb-container.custom-sb-container .sb .sb__dates, #BookingBar #sb-container.custom-sb-container .sb .sb__footer, #BookingBar #sb-container.custom-sb-container .sb .sb__footer-actions {
      display: flex !important; }
    #BookingBar #sb-container.custom-sb-container .sb:after {
      content: none; }
    #BookingBar #sb-container.custom-sb-container .sb #sb-container_sb__form {
      width: auto;
      flex: 1 0 auto; }
      #BookingBar #sb-container.custom-sb-container .sb #sb-container_sb__form > * {
        flex: 1 0 auto;
        width: auto; }
      #BookingBar #sb-container.custom-sb-container .sb #sb-container_sb__form .sb__dates {
        flex-grow: 2; }
        #BookingBar #sb-container.custom-sb-container .sb #sb-container_sb__form .sb__dates .sb__form-field-month-year {
          white-space: nowrap; }
      #BookingBar #sb-container.custom-sb-container .sb #sb-container_sb__form .sb__btn--verify {
        height: auto;
        padding: 10px 30px;
        margin-top: 0;
        align-self: flex-end;
        font-weight: bold;
        text-transform: uppercase;
        flex-grow: 0;
        letter-spacing: 1px; }
        @media (max-width: 991px) {
          #BookingBar #sb-container.custom-sb-container .sb #sb-container_sb__form .sb__btn--verify {
            padding: 10px;
            margin-left: 5px; } }
      #BookingBar #sb-container.custom-sb-container .sb #sb-container_sb__form .sb__guests {
        min-width: 340px; }
    #BookingBar #sb-container.custom-sb-container .sb .sb__form-field--checkout, #BookingBar #sb-container.custom-sb-container .sb .sb__guests-rooms, #BookingBar #sb-container.custom-sb-container .sb .sb__footer-link {
      margin-top: 0; }
    #BookingBar #sb-container.custom-sb-container .sb .sb__form-field-input {
      padding: 8px 48px 8px 8px;
      height: auto; }
      @media (max-width: 991px) {
        #BookingBar #sb-container.custom-sb-container .sb .sb__form-field-input .sb__form-field-date-number {
          font-size: 20px; }
        #BookingBar #sb-container.custom-sb-container .sb .sb__form-field-input .sb-custom-icon-color {
          width: 20px;
          height: 20px; } }
    #BookingBar #sb-container.custom-sb-container .sb .sb__form-field-icon {
      right: 10px;
      top: 10px;
      bottom: 10px; }
    #BookingBar #sb-container.custom-sb-container .sb .sb__footer-actions {
      margin-left: 32px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; }
      @media (max-width: 1199px) {
        #BookingBar #sb-container.custom-sb-container .sb .sb__footer-actions {
          display: none !important; } }
      #BookingBar #sb-container.custom-sb-container .sb .sb__footer-actions .sb__footer-link {
        display: flex;
        white-space: nowrap; }
        #BookingBar #sb-container.custom-sb-container .sb .sb__footer-actions .sb__footer-link:hover {
          text-decoration: underline; }

#Top + #BookingBar {
  position: fixed;
  left: 0;
  width: 100%;
  margin-top: 68px;
  -webkit-transform: translateZ(0); }

#Top + #BookingBar + .main {
  margin-top: 8.3529411765em; }

@-webkit-viewport {
  width: device-width; }

@-moz-viewport {
  width: device-width; }

@-ms-viewport {
  width: device-width; }

@-o-viewport {
  width: device-width; }

@viewport {
  width: device-width; }

::selection {
  background: rgba(92, 132, 176, 0.925);
  color: #fff; }

.modal {
  overflow-y: auto;
  overflow-x: auto; }
  .modal::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.4705882353em rgba(84, 255, 159, 0.3);
    background-color: #e3e5e8; }
  .modal::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: #f2f2f3; }
  .modal::-webkit-scrollbar-thumb {
    background-color: #5C84B0;
    border: solid 1px rgba(255, 255, 255, 0.5); }
    .modal::-webkit-scrollbar-thumb:hover {
      background-color: #476b92; }
    .modal::-webkit-scrollbar-thumb:vertical {
      border-top: none;
      border-bottom: none;
      border-right: none; }
    .modal::-webkit-scrollbar-thumb:horizontal {
      border-left: none;
      border-bottom: none;
      border-right: none; }

html {
  font-size: 17px; }
  html, body {
    height: 100%; }
  body {
    overflow: hidden;
    min-width: 750px;
    font-size: 0.75em;
    --sf-sp: 2.9411764706vw; }
    @media (min-width: 992px) {
      body {
        font-size: 0.875em; } }
    @media (min-width: 1200px) {
      body {
        font-size: 1em; } }
    @media (min-width: 1360px) {
      body {
        --sf-sp: 40px; } }
    @media (max-width: 991px) {
      body {
        --sf-sp: 29.1764705882px; } }

.anchors {
  display: block;
  position: relative;
  pointer-events: none;
  z-index: -1;
  bottom: 284px; }

.modal-dialog.awards {
  margin-top: calc(4.8235294118em + 3vh); }

.new-modal-content.awards {
  max-width: 800px !important;
  border: solid 0.8823529412em #00AF87;
  border-top-width: 2.4705882353em; }
  .new-modal-content.awards .close {
    top: -2.3529411765em !important;
    right: -0.8823529412em !important;
    background-color: #00AF87 !important; }
    .new-modal-content.awards .close:hover {
      background-color: #007c60 !important; }
  .new-modal-content.awards .modal-title {
    color: #00AF87;
    font-family: 'QuattrocentoSans-Regular', sans-serif;
    font-variant: small-caps;
    text-transform: capitalize; }
  #LogoAwards {
    position: relative;
    margin-top: -9.6470588235em;
    margin-bottom: -1em;
    padding-bottom: 1em; }
    #LogoAwards img {
      width: 11.7647058824em;
      height: 8em; }

#Top {
  position: relative;
  z-index: 12;
  transition: border 0.25s; }
  #Top.has-slider {
    z-index: 100; }
    @media (orientation: landscape) {
      #Top.has-slider.top-home {
        height: 100vh !important; } }
    @media (orientation: portrait) {
      #Top.has-slider.top-home {
        height: 58vh; } }
  #Top.no-slider {
    min-height: 0.8823529412em; }
  #Top .logo {
    margin: 0;
    padding: 0;
    font-size: inherit; }
    #Top .logo a {
      display: block; }
  #Top .boxes-slider {
    position: absolute;
    z-index: 3;
    top: 4.1176470588em;
    left: 4.1176470588em; }
  #Top .wrapper_logo .logo a {
    width: 100%;
    height: 100%;
    padding: 1.7647058824em;
    text-align: center; }
  #Top .wrapper_logo .logo img {
    width: 16.7058823529em;
    height: 13.8823529412em;
    object-fit: cover; }

.wrapper_nav {
  position: relative;
  width: 100%;
  min-width: 720px;
  z-index: 1000;
  background-color: #F4F4F0;
  top: -4.3529411765em;
  transition: all, 0.5s; }
  .has-block-affix .wrapper_nav {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0px 6px 12px -8px rgba(26, 25, 25, 0.5); }
  .wrapper_nav > .container {
    padding: 0; }
  .wrapper_nav.affix {
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    box-shadow: 0px 6px 12px -8px rgba(26, 25, 25, 0.5); }
  .wrapper_nav .nav-bar {
    position: relative;
    height: 7.9411764706em;
    transition: 0.25s;
    transition-property: height;
    transition-delay: 0;
    z-index: 1011; }
    @media (max-width: 991px) {
      .wrapper_nav .nav-bar {
        padding: 0 0.8823529412em; } }
    .wrapper_nav .nav-bar.reduced {
      height: 4em;
      transition-delay: 0.25s; }
      .wrapper_nav .nav-bar.reduced .logo img, .wrapper_nav .nav-bar.reduced .small-logo {
        width: 1.2555555556em !important;
        height: 1.0444444444em !important;
        image-rendering: -webkit-optimize-contrast; }
        @media (max-width: 1199px) {
          .wrapper_nav .nav-bar.reduced .logo img, .wrapper_nav .nav-bar.reduced .small-logo {
            width: 1.0462962963em !important;
            height: 0.8703703704em !important; } }
    .wrapper_nav .nav-bar .logo, .wrapper_nav .nav-bar .small-logo {
      margin-right: 0.5555555556em;
      margin-top: 0;
      margin-bottom: 0; }
      @media (max-width: 1199px) {
        .wrapper_nav .nav-bar .logo, .wrapper_nav .nav-bar .small-logo {
          margin-right: 0.2777777778em; } }
    .wrapper_nav .nav-bar .logo img, .wrapper_nav .nav-bar .small-logo {
      position: relative;
      transition: all 0.5s;
      width: 2.0925925926em;
      height: 1.7407407407em; }
      @media (max-width: 1199px) {
        .wrapper_nav .nav-bar .logo img, .wrapper_nav .nav-bar .small-logo {
          bottom: 0 !important;
          width: 1.3950617284em !important;
          height: 1.1604938272em !important; } }
      @media (max-width: 991px) {
        .wrapper_nav .nav-bar .logo img, .wrapper_nav .nav-bar .small-logo {
          width: 1.0462962963em !important;
          height: 0.8703703704em !important; } }
    .wrapper_nav .nav-bar .logo img {
      object-fit: contain; }
    .wrapper_nav .nav-bar .small-logo {
      font-size: 54px;
      background-image: url("/assets/images/layout/logo-navbar.png");
      background-size: 100% 100%; }
      .wrapper_nav .nav-bar .small-logo.unseen {
        width: 0em; }

nav ul li.submenu {
  visibility: hidden;
  opacity: 0; }

nav ul li.active {
  visibility: visible;
  opacity: 1; }

nav ul {
  list-style: none;
  width: 100%;
  margin: 0; }
  nav ul a {
    font-family: 'QuattrocentoSans-Regular', sans-serif; }
    nav ul a:hover, nav ul a:focus, nav ul a.active {
      text-decoration: none; }

nav .horizontal {
  margin-left: auto;
  margin-right: auto; }

nav.top {
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  flex: 1 0 auto; }
  nav.top .horizontal {
    max-width: 1920px;
    padding-left: 0; }
    nav.top .horizontal > li {
      -webkit-box-flex: 1;
      -webkit-flex: 1 0 auto;
      -moz-box-flex: 1;
      -moz-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      position: relative; }
      nav.top .horizontal > li.active a {
        color: #0198CC; }
      nav.top .horizontal > li a {
        position: relative; }
        nav.top .horizontal > li a span {
          position: relative;
          color: #006CA5;
          text-transform: uppercase;
          padding: 0.8823529412em 0.2941176471em;
          transform: translateZ(0);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -moz-osx-font-smoothing: grayscale;
          overflow: hidden; }
          nav.top .horizontal > li a span:before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 50%;
            right: 50%;
            bottom: 0;
            border-bottom: 2px solid rgba(0, 63, 127, 0.8);
            border-top: 2px solid rgba(0, 63, 127, 0.8);
            height: 100%;
            -webkit-transition-property: left, right;
            transition-property: left, right;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
            width: 0; }
        nav.top .horizontal > li a:hover span:before, nav.top .horizontal > li a:focus span:before, nav.top .horizontal > li a.active span:before {
          left: 0;
          right: 0;
          width: inherit; }
        @media (max-width: 1199px) {
          nav.top .horizontal > li a span {
            font-size: 0.8em;
            display: inline-block;
            text-align: center;
            line-height: 1;
            vertical-align: middle;
            max-width: 7em; } }
        @media (max-width: 991px) {
          nav.top .horizontal > li a span:before {
            top: -170%; }
          nav.top .horizontal > li a span:after {
            top: 150%; } }

#Language {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin: 0;
  height: 0;
  overflow: hidden;
  -webkit-transition: height linear 0.25s;
  transition: height linear 0.25s; }
  #Language ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block; }
    #Language ul:before {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      line-height: 74px;
      width: 100%;
      text-align: center;
      font-family: 'QuattrocentoSans-Regular', sans-serif;
      color: #FFFFFF; }
  #Language li {
    display: block;
    margin: 0;
    padding: 0; }
  #Language a {
    text-indent: initial;
    text-align: center;
    color: white;
    position: relative;
    display: block;
    line-height: 60px;
    opacity: 3/5;
    padding: 0; }
    #Language a span {
      text-indent: initial;
      text-transform: uppercase;
      color: inherit; }
    #Language a:hover {
      opacity: 1; }
  #Language .active {
    display: none; }
  .it#Language ul:before {
    content: "ITA"; }
  .en#Language ul:before {
    content: "ENG"; }
  .de#Language ul:before {
    content: "DEU"; }
  #Language:hover, #Language:focus, .hover#Language {
    height: 120px; }

#Language {
  padding-top: 74px;
  padding-left: 0.2941176471em;
  padding-right: 0.2941176471em; }
  #Language .icon {
    line-height: 74px;
    width: 1em;
    height: 3.7647058824em;
    position: absolute;
    text-align: center;
    left: calc(50% - 0.5em);
    top: 20px; }
  #Language a {
    width: 60px;
    height: 60px; }

#Language {
  z-index: 1012;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(244, 244, 240, 0.5);
  transition: all 0.5s; }
  #Language:hover, #Language:focus, #Language.hover {
    background-color: #003F7F; }
  .has-block-affix #Language {
    margin-right: 1.4705882353em; }
  #Language ul:before {
    color: white; }
  #Language ul li a {
    color: #d9d9d9; }
  #Language ul li .icon {
    color: white;
    z-index: -1; }

#SliderWrapper {
  overflow: hidden;
  width: 100%;
  position: relative; }
  #SliderWrapper > .slider {
    width: 100% !important;
    height: 100% !important; }
    #SliderWrapper > .slider:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: 1.4705882353em solid #FFFFFF;
      border-bottom: 0;
      z-index: 1; }
  #SliderWrapper .svg-loader {
    font-size: 4.7058823529em;
    width: 1em;
    height: 1em;
    color: #003F7F;
    line-height: 1;
    animation: spin 2s infinite linear;
    position: absolute;
    top: 45%;
    left: 50%;
    margin-left: -0.5em;
    margin-top: -0.5em;
    z-index: 51; }
  #SliderWrapper .scroll {
    z-index: 1;
    position: absolute;
    bottom: 5.2941176471em;
    left: calc(50% - 2.6470588235em);
    animation: scrolltocontent 7s infinite;
    cursor: pointer;
    width: 5.2941176471em;
    height: 5.2941176471em; }
    @media (orientation: portrait) {
      #SliderWrapper .scroll {
        display: none; } }
    #SliderWrapper .scroll > * {
      position: relative; }
    #SliderWrapper .scroll:after {
      transition: 0.5s;
      z-index: 1; }
    #SliderWrapper .scroll:after {
      transition-property: border;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 100%;
      background-color: rgba(0, 108, 165, 0.45); }
    #SliderWrapper .scroll .icon {
      transition: color, 0.5s;
      line-height: 0.4130434783;
      z-index: 3;
      color: rgba(255, 255, 255, 0.85);
      font-size: 2.3529411765em;
      text-shadow: 0px 0px 3px rgba(26, 25, 25, 0.5); }
      #SliderWrapper .scroll .icon:before {
        height: inherit; }
    #SliderWrapper .scroll:hover .icon {
      color: white; }
    #SliderWrapper .scroll:hover:after {
      border: solid 1px rgba(255, 255, 255, 0.5); }
  #SliderWrapper .slider-title-wrapper {
    width: 50%;
    position: absolute;
    left: 1.3043478261em;
    bottom: 1.5217391304em; }
    #SliderWrapper .slider-title-wrapper > h1 {
      font-size: 2.7058823529em;
      margin: 0;
      padding: 1em 0em 2.5em 1.5217391304em;
      color: white;
      text-shadow: 2px 2px #333;
      font-style: italic;
      font-family: 'QuattrocentoSansBold', sans-serif;
      letter-spacing: 2px; }
      #SliderWrapper .slider-title-wrapper > h1 span {
        display: block; }

#Scroll {
  flex: 0 0 100vh;
  transform: translate3d(0, 0, 0); }
  #Scroll, #Scroll .scroll-content {
    display: flex;
    flex-direction: column; }

.socials#Socials, .social-item {
  display: grid !important;
  grid-gap: var(--gap, 0); }

.socials#Socials {
  width: 100%;
  color: #fff #003F7F;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  --gap: calc(var(--sf-sp)/3); }

.social-item {
  place-items: center center;
  size: 3.5294117647rem;
  --sq: 1.4705882353rem;
  color: currentColor;
  border: solid 1px currentColor;
  border-radius: calc(3.5294117647rem / 2);
  transition: color 0.5s, background 0.5s, border 0.5s; }
  .social-item--facebook {
    --brand-color: #1876f2; }
  .social-item--instagram {
    --brand-color: #e1306c; }
  .social-item--tripadvisor {
    --brand-color: #00AF87; }
  .social-item:hover, .social-item:focus {
    color: var(--brand-color) white !important; }
  .social-item-svg {
    size: var(--sq) var(--sq);
    fill: currentColor; }
    .social-item--tripadvisor .social-item-svg {
      size: calc(var(--sq)*2) ""; }
  .social-item--enjoy {
    --brand-color: #f0af30;
    background-image: svg-load("images/svg/logos/enjoy.svg", fill=white);
    background-size: 75% auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-origin: content-box; }
    .social-item--enjoy:hover, .social-item--enjoy:focus {
      background-image: svg-inline(enjoy-the-coast); }

.footer#Footer, .footer-bound, .footer-logo {
  display: grid; }

.footer#Footer, .footer#Footer:before, .footer-bound {
  position: relative; }

.footer#Footer:before, .footer-bound {
  grid-area: 1 / 1 / 2 / 2; }

.footer#Footer {
  size: stretch "";
  --foot-pad: calc(var(--sf-sp));
  --gap: calc(var(--foot-pad)/2);
  padding: var(--foot-pad) "";
  color: #fff #003F7F;
  margin: "";
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 1.0588235294rem; }

.contacts#Contacts {
  display: grid;
  grid-template: "logo logo" auto  "contacts contacts" auto  "flag socials" auto  / 1fr 1fr;
  grid-gap: 2.4rem 0;
  justify-items: center;
  font-size: inherit / 1.4;
  margin: 0; }
  @media (min-width: 1200px) {
    .contacts#Contacts {
      grid-template: "logo contacts flag" auto  "socials socials socials" auto  / 1fr 1fr 1fr;
      grid-row-gap: 2rem; } }
  @media (min-width: 1360px) {
    .contacts#Contacts {
      grid-template: "logo contacts flag socials" auto  / 1fr 2fr 1fr 1fr;
      grid-column-gap: 1px;
      background: rgba(255, 255, 255, 0.3); } }
  .contacts#Contacts > p {
    display: contents; }
  .contacts#Contacts a {
    color: inherit;
    min-height: 25px;
    display: inline-block; }
  .contacts#Contacts .fn.org {
    text-transform: uppercase;
    grid-column: span 3;
    font-size: 1.7822655774rem; }
  .contacts#Contacts .socials {
    grid-area: socials;
    margin: -1px; }

.contact-svg {
  size: 1.5294117647rem;
  fill: currentColor;
  fill-opacity: 0.85;
  flex: none;
  justify-self: center;
  grid-column-start: 1; }

.contact-label {
  display: none;
  display: inline;
  white-space: nowrap; }

.contact-group {
  grid-area: contacts;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.8rem;
  align-items: center;
  justify-content: center;
  background: #003F7F;
  width: 100%;
  margin: -1px; }
  .contact-group-item {
    display: grid;
    grid-template: "icon title text" auto/calc(1.8823529412rem + 1rem) minmax(10ch, auto) 1fr;
    align-items: center;
    grid-gap: 0.5rem; }
    .contact-group-item .icon {
      grid-area: icon;
      font-size: 1.8823529412rem;
      justify-self: center;
      color: white; }
    .contact-group-item-title {
      grid-area: title;
      opacity: 0.7; }
    .contact-group-item:last-of-type {
      margin: 0; }

.contact-element {
  grid-area: text; }
  .contact-element-whatsapp {
    justify-self: left;
    display: flex;
    align-items: center;
    grid-area: wapp; }
  .contact-elements {
    display: flex;
    align-items: center; }
  .contact-element-dash:last-child {
    display: none; }
  .contact-element.adr {
    white-space: nowrap; }
  .contact-element svg.whatsapp {
    size: 1.5294117647rem;
    margin: "" "" "" 1rem;
    fill: #25d366; }
  .contact-element .h4 {
    font-size: 1.1764705882rem;
    color: #5C84B0;
    font-stretch: extra-expanded; }

.contact-logo {
  grid-area: logo;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #003F7F;
  width: 100%;
  margin: -1px; }

.contact-flag {
  grid-area: flag;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #003F7F;
  width: 100%;
  margin: -1px; }
  .contact-flag img {
    width: 6.2777777778em;
    height: 6.3888888889em; }

.closer#Closer {
  margin: "" "";
  font-family: sans-serif;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  color: rgba(255, 255, 255, 0.85) #0398CE;
  size: stretch "";
  padding: var(--sf-sp);
  justify-content: center;
  justify-items: center;
  grid-gap: var(--sf-sp);
  grid-gap: 0;
  padding: 0 "";
  justify-content: space-between;
  size: "" 2.9411764706rem;
  grid-auto-flow: column;
  justify-items: flex-start; }
  .closer#Closer .author {
    justify-items: flex-end; }
  @supports (width: stretch) {
    .closer#Closer {
      width: stretch; } }

.closer-disclaimers {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  flex-flow: row nowrap;
  grid-area: 1 / 1 / 2 / 2; }

.closer-disclaimer-item {
  font-size: 14px;
  line-height: 25px;
  margin: 4px "";
  margin: 0 ""; }

.closer-disclaimer-sep {
  margin: "" 0.5ch;
  display: none; }
  @media (min-width: 992px) {
    .closer-disclaimer-sep {
      display: inline-block; } }

.closer-top, .closer-author,
.closer-disclaimers a {
  color: inherit; }
  .closer-top:hover, .closer-top:focus, .closer-author:hover, .closer-author:focus,
  .closer-disclaimers a:hover,
  .closer-disclaimers a:focus {
    color: #fff; }

.closer-top {
  grid-area: 1 / 1 / 2 / 3;
  place-self: center center;
  cursor: pointer;
  font-size: 2rem; }

.closer-author {
  grid-area: 1 / 2 / 2 / 3; }

.gallery-container .figure a .additional {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: sans-serif; }
  .no-flexbox.no-flexboxtweener.no-flexboxlegacy .gallery-container .figure a .additional, .gallery-container .figure a .no-flexbox.no-flexboxtweener.no-flexboxlegacy .additional, .lt-ie9 .gallery-container .figure a .additional, .gallery-container .figure a .lt-ie9 .additional, .ie9 .gallery-container .figure a .additional, .gallery-container .figure a .ie9 .additional {
    display: block; }
  .gallery-container .figure a .additional > span {
    -webkit-transition: transform, 0.25s;
    transition: transform, 0.25s;
    display: inline-block;
    font-size: 2.4705882353em;
    line-height: 1;
    text-align: center;
    text-shadow: 1px 1px 0 black; }

.gallery-container .figure a:before, .flex-images .figure a:before {
  transition: all 0.25s;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0; }

.gallery-container .figure a:hover:before, .flex-images .figure a:hover:before {
  background: rgba(0, 0, 0, 0.333333); }

.gallery-container {
  margin-left: -0.2205882353em;
  margin-right: -0.2205882353em;
  margin-bottom: -0.4411764706em; }
  .gallery-container .figure {
    font-size: 18px;
    padding: 0px 8px 0px 7px;
    padding-left: 0.2205882353em;
    padding-right: 0.2205882353em;
    margin-bottom: 0.4411764706em;
    position: relative;
    z-index: 1; }
    .gallery-container .figure.animated-element.has-animation {
      animation: slideInUp 1 both;
      animation-delay: 0.2s;
      animation-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);
      animation-duration: 1s; }
    .gallery-container .figure a {
      position: relative;
      display: block;
      perspective: 35em;
      width: 100%;
      height: 100%;
      color: white; }
      .gallery-container .figure a img {
        z-index: -1; }
      .gallery-container .figure a:not(.embed-responsive) img {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: -1;
        object-fit: cover; }
      .gallery-container .figure a.embed-responsive img {
        object-fit: cover; }
      .gallery-container .figure a .caption {
        color: #003F7F;
        width: 100%;
        height: auto;
        box-sizing: content-box;
        background: rgba(255, 255, 255, 0.8);
        padding: 0.5em 0;
        line-height: 2;
        margin-top: -3em;
        height: 2em;
        text-align: center; }
        @media (max-width: 1199px) {
          .gallery-container .figure a .caption {
            line-height: 2;
            margin-top: -3em;
            height: 2em; } }
        .gallery-container .figure a .caption > span {
          display: inline-block;
          max-height: 2em;
          overflow: hidden;
          vertical-align: middle;
          line-height: 1;
          font-size: 1.2941176471em; }
          @media (max-width: 1199px) {
            .gallery-container .figure a .caption > span {
              font-size: 1.1764705882em; } }
        .gallery-container .figure a .caption > .icon {
          font-size: 1.5em;
          position: absolute;
          right: 0.5em;
          top: 50%;
          margin-top: -0.5em; }
      .gallery-container .figure a .svg-loader {
        font-size: 3.5294117647em;
        width: 0.5em;
        height: 0.5em;
        line-height: 1;
        animation: spin 2s infinite linear;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -0.25em;
        margin-top: -0.25em;
        z-index: 101; }
      .gallery-container .figure a img.in + .svg-loader {
        display: none; }
      .gallery-container .figure a:hover .additional > span {
        transform: scale(1.25); }
      .gallery-container .figure a:hover .caption {
        transform: rotateX(0deg);
        visibility: visible; }
  .gallery-container.flex-gallery .figure a {
    flex-direction: column; }
    .no-objectfit .gallery-container.flex-gallery .figure a {
      max-height: 380px;
      overflow: hidden; }
    .gallery-container.flex-gallery .figure a img {
      flex: 1 1 auto;
      object-fit: cover;
      max-height: 380px; }
      .no-objectfit .gallery-container.flex-gallery .figure a img {
        flex: 0 0 auto;
        max-height: none; }
    .gallery-container.flex-gallery .figure a .more-images {
      position: absolute;
      -webkit-transition: all 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530);
      -moz-transition: all 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530);
      -o-transition: all 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530);
      transition: all 0.25s cubic-bezier(0.550, 0.085, 0.680, 0.530);
      -webkit-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530);
      -moz-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530);
      -ms-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530);
      -o-transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530);
      transition-delay: cubic-bezier(0.550, 0.085, 0.680, 0.530);
      width: 90px;
      height: 90px;
      align-items: center;
      justify-content: center;
      transform: scale(0.75);
      font-size: 90px;
      line-height: 1;
      top: 50%;
      left: 50%;
      margin-top: -45px;
      margin-left: -45px; }
    .gallery-container.flex-gallery .figure a:hover .more-images {
      transform: scale 1; }
  .gallery-container.flex-gallery .figure.has-others a:before {
    background: rgba(0, 0, 0, 0.5); }

.flex-images {
  padding: 11px 15px;
  margin-top: 15px;
  padding-top: 0; }
  .flex-images .figure {
    border: none; }
    .flex-images .figure a .svg-loader {
      font-size: 3.5294117647em;
      width: 1em;
      height: 1em;
      line-height: 1;
      animation: spin 2s infinite linear;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -0.5em;
      margin-top: -0.5em;
      z-index: 101; }
    .flex-images .figure a img.in + .svg-loader {
      display: none; }

#Gallery, .photogallery {
  margin: 4.7058823529em auto; }

.random-offer {
  background-color: white;
  padding: 2.9411764706em; }
  .random-offer .wrapper h4 {
    letter-spacing: 4px;
    font-weight: bold; }
    .random-offer .wrapper h4 small {
      color: rgba(0, 108, 165, 0.5);
      font-weight: bold; }
  .random-offer .wrapper hr {
    border-color: #F4F4F0;
    border-width: 3px; }
  .random-offer .wrapper .btn {
    margin-top: 1.1764705882em; }
  .random-offer.animated-element.has-animation {
    animation: foldingRight 1 both;
    animation-delay: 0.5s;
    animation-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);
    animation-duration: 1.5s; }

#Offers {
  margin-top: 1.7647058824em; }
  #Offers .offer {
    position: relative;
    margin-bottom: 1.7647058824em;
    z-index: 1;
    display: flex;
    border: solid 20px rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.8);
    padding: 6px;
    background-clip: content-box; }
    #Offers .offer.animated-element.has-animation {
      animation: slideInUp 1 both;
      animation-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);
      animation-duration: 1.2s; }
    #Offers .offer > .flex {
      margin-bottom: 1.1764705882em; }
    #Offers .offer > .inflexible {
      width: 50%;
      position: relative; }
    #Offers .offer .offer-detail {
      padding: 2.9411764706em;
      background-color: rgba(255, 255, 255, 0.8); }
      #Offers .offer .offer-detail .lemma {
        margin-top: 0; }
      #Offers .offer .offer-detail p {
        font-size: 0.8823529412em; }
    #Offers .offer .offer-photo {
      position: relative;
      overflow: hidden; }
      #Offers .offer .offer-photo a {
        display: block;
        overflow: hidden; }
        #Offers .offer .offer-photo a img {
          -o-object-fit: cover;
          object-fit: cover;
          font-family: "object-fit: cover;";
          -webkit-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -moz-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -o-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
          transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -webkit-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -moz-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -ms-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -o-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
          transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -webkit-transform-origin: 25% 25%;
          -moz-transform-origin: 25% 25%;
          -ms-transform-origin: 25% 25%;
          transform-origin: 25% 25%; }
        #Offers .offer .offer-photo a:hover img {
          transform: scale(1.15); }
      #Offers .offer .offer-photo a, #Offers .offer .offer-photo img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
      #Offers .offer .offer-photo .ribbon {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(92, 132, 176, 0.6);
        padding: 1.2941176471em;
        height: 3.5294117647em;
        z-index: 100; }
        #Offers .offer .offer-photo .ribbon h4 {
          margin: 0;
          color: white; }
    #Offers .offer .wrapper_btn {
      margin-top: 1.7647058824em; }

#Offer {
  padding-bottom: 8em; }
  #Offer .offer-detail {
    margin: 2.9411764706em 0; }
    #Offer .offer-detail .detail {
      background-color: #FFFFFF;
      padding: 2.9411764706em; }
      #Offer .offer-detail .detail .title {
        margin-top: 0; }
      #Offer .offer-detail .detail .wrapper_content {
        position: relative;
        padding: 2.9411764706em;
        border-top: 8px solid blue;
        border-bottom: 8px solid blue; }
      #Offer .offer-detail .detail .btn-wrapper {
        margin-top: 1.7647058824em;
        display: inline-block; }
        #Offer .offer-detail .detail .btn-wrapper a {
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          -moz-align-items: center;
          align-items: center; }
          #Offer .offer-detail .detail .btn-wrapper a .icon {
            margin-left: 0.5882352941em; }
    #Offer .offer-detail .offer-photo {
      padding: 0; }
      #Offer .offer-detail .offer-photo a {
        position: relative;
        display: block;
        overflow: hidden;
        max-height: 50vh; }
        #Offer .offer-detail .offer-photo a img {
          -o-object-fit: cover;
          object-fit: cover;
          font-family: "object-fit: cover;";
          width: 100%;
          height: 100%;
          -webkit-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -moz-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -o-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
          transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -webkit-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -moz-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -ms-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -o-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
          transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
          -webkit-transform-origin: 25% 25%;
          -moz-transform-origin: 25% 25%;
          -ms-transform-origin: 25% 25%;
          transform-origin: 25% 25%; }
        #Offer .offer-detail .offer-photo a:hover img {
          transform: scale(1.15); }
        #Offer .offer-detail .offer-photo a .shadow {
          position: absolute;
          width: 100%;
          height: 100%;
          box-shadow: inset 0px 12px 16px -6px rgba(0, 0, 0, 0.2), inset 0px -12px 16px -6px rgba(0, 0, 0, 0.2);
          top: 0;
          left: 0; }
      #Offer .offer-detail .offer-photo .ribbon {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(92, 132, 176, 0.9);
        padding: 1.2941176471em;
        height: 3.5294117647em;
        z-index: 100; }
        #Offer .offer-detail .offer-photo .ribbon h4 {
          margin: 0;
          color: white; }
  #Offer .link-request {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    margin: 0;
    -webkit-transition: all, 0.5s;
    transition: all, 0.5s; }
    #Offer .link-request .icon {
      font-size: 0.6em;
      margin-right: 0.4705882353em;
      line-height: 1.2; }

#OfferRequest {
  position: relative;
  margin-top: 3.1764705882em;
  padding-bottom: 3.1764705882em;
  padding-top: 2em;
  clear: both;
  z-index: 1; }
  #OfferRequest > .container {
    margin-top: -9em;
    background: #fff;
    padding: 3em 0 0; }
    #OfferRequest > .container h2.header {
      padding: 0 30px;
      margin: 0; }
      #OfferRequest > .container h2.header .icon {
        font-size: 0.7em; }

.no-offers .form-wrapper {
  padding-top: 3.5294117647em;
  margin-top: 3.5294117647em; }

.no-offers .alert-success + .form-wrapper {
  padding: 0;
  margin: 0; }

.rooms-icons {
  position: relative;
  margin-top: 3.5294117647em;
  padding: 0; }
  .rooms-icons .wrapper_slides {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    position: relative; }
  .rooms-icons .col {
    padding: 0.8823529412em 0.2941176471em; }
  .rooms-icons .wrapper-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    line-height: 1;
    position: relative; }
    @media (max-width: 991px) {
      .rooms-icons .wrapper-content {
        padding: 1em 3em; } }
    @media (min-width: 992px) {
      .rooms-icons .wrapper-content {
        padding: 1em; } }
    @media (min-width: 1360px) {
      .rooms-icons .wrapper-content {
        padding: 1em 6em; } }
    .rooms-icons .wrapper-content .icon {
      position: relative;
      display: table;
      margin-right: 0.3333333333em;
      font-size: 3.5294117647em;
      color: #003F7F; }
    .rooms-icons .wrapper-content .title {
      margin: 0;
      display: block;
      text-align: center; }
    .rooms-icons .wrapper-content.lines-2 .icon {
      top: -0.1em; }

#Lodgings {
  margin-top: 4.7058823529em; }
  @media (max-width: 1199px) {
    #Lodgings {
      margin-top: 2.3529411765em; } }
  #Lodgings .additional-gallery-wrapper {
    position: relative;
    padding: 4.7058823529em 0;
    z-index: 1; }
    #Lodgings .additional-gallery-wrapper.animated-element > .container:after {
      opacity: 0; }
    #Lodgings .additional-gallery-wrapper.animated-element .lodging .lemma:before {
      opacity: 0; }
    #Lodgings .additional-gallery-wrapper.animated-element .lodging .gallery-container {
      opacity: 0; }
    #Lodgings .additional-gallery-wrapper.animated-element.has-animation .lodging .lemma:before {
      animation: foldingLeft 1 both;
      animation-delay: 0.5s;
      animation-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
      animation-duration: 1.5s; }
    #Lodgings .additional-gallery-wrapper.animated-element.has-animation > .container:after {
      animation: slide-right 1 both;
      animation-delay: 1s;
      animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      animation-duration: 1s; }
    #Lodgings .additional-gallery-wrapper.animated-element.has-animation > .container .gallery-container {
      animation: slide-left 1 both;
      animation-delay: 0.5s;
      animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      animation-duration: 1.3s; }
    #Lodgings .additional-gallery-wrapper.animated-element.reverse.has-animation > .container:after {
      animation: slide-left 1 both;
      animation-delay: 1s;
      animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      animation-duration: 1s; }
    #Lodgings .additional-gallery-wrapper.animated-element.reverse.has-animation > .container .gallery-container {
      animation: slide-right 1 both;
      animation-delay: 0.5s;
      animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
      animation-duration: 1.3s; }
    #Lodgings .additional-gallery-wrapper > .container {
      position: relative; }
      #Lodgings .additional-gallery-wrapper > .container:after {
        content: "";
        width: 90%;
        height: 0.3529411765em;
        background-color: white;
        position: absolute;
        left: 30px;
        bottom: 2.3529411765em;
        z-index: 1; }
      #Lodgings .additional-gallery-wrapper > .container.reverse:after {
        left: auto;
        right: 30px; }
    #Lodgings .additional-gallery-wrapper .lodging {
      position: relative;
      padding-bottom: 2.3529411765em;
      padding-top: 6.4705882353em; }
      #Lodgings .additional-gallery-wrapper .lodging .lemma {
        position: relative;
        margin-left: 0.660098362em; }
        #Lodgings .additional-gallery-wrapper .lodging .lemma:before {
          content: attr(data-title);
          position: absolute;
          top: -0.3666666667em;
          left: 0.3333333333em;
          width: 100%;
          height: 100%;
          color: rgba(112, 101, 88, 0.1);
          font-size: 1.980295086em;
          z-index: -1; }
      #Lodgings .additional-gallery-wrapper .lodging .wrapper_content {
        position: relative;
        background-color: #FFFFFF;
        padding: 2.9411764706em; }
        #Lodgings .additional-gallery-wrapper .lodging .wrapper_content:before {
          content: "";
          width: 11.0588235294em;
          height: 11.0588235294em;
          border: 0.4705882353em solid white;
          position: absolute;
          left: 3.2352941176em;
          top: -40%;
          z-index: -1; }
        #Lodgings .additional-gallery-wrapper .lodging .wrapper_content .content_text {
          line-height: 2;
          font-size: 1.0588235294em; }
    #Lodgings .additional-gallery-wrapper .gallery-container {
      position: relative;
      margin-bottom: 0; }
      #Lodgings .additional-gallery-wrapper .gallery-container .main-image, #Lodgings .additional-gallery-wrapper .gallery-container .small-images {
        padding: 0; }
        #Lodgings .additional-gallery-wrapper .gallery-container .main-image .figure, #Lodgings .additional-gallery-wrapper .gallery-container .small-images .figure {
          position: relative;
          overflow: hidden;
          -webkit-box-flex: 1;
          -webkit-flex: 1 0 auto;
          -moz-box-flex: 1;
          -moz-flex: 1 0 auto;
          -ms-flex: 1 0 auto;
          flex: 1 0 auto;
          margin-bottom: 0; }
          #Lodgings .additional-gallery-wrapper .gallery-container .main-image .figure a, #Lodgings .additional-gallery-wrapper .gallery-container .small-images .figure a {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 0; }
            #Lodgings .additional-gallery-wrapper .gallery-container .main-image .figure a img, #Lodgings .additional-gallery-wrapper .gallery-container .small-images .figure a img {
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
              font-family: "object-fit: cover;";
              -webkit-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -moz-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -o-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
              transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -webkit-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -moz-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -ms-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -o-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
              transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -webkit-transform-origin: 25% 25%;
              -moz-transform-origin: 25% 25%;
              -ms-transform-origin: 25% 25%;
              transform-origin: 25% 25%; }
            #Lodgings .additional-gallery-wrapper .gallery-container .main-image .figure a .additional, #Lodgings .additional-gallery-wrapper .gallery-container .small-images .figure a .additional {
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              background: rgba(0, 0, 0, 0.5);
              color: white;
              z-index: 1; }
              #Lodgings .additional-gallery-wrapper .gallery-container .main-image .figure a .additional span, #Lodgings .additional-gallery-wrapper .gallery-container .small-images .figure a .additional span {
                display: -webkit-box;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                -moz-align-items: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                -moz-justify-content: center;
                justify-content: center;
                font-size: 40.4489777376px;
                line-height: 1;
                text-shadow: 1px 1px 0 black;
                height: inherit; }
            #Lodgings .additional-gallery-wrapper .gallery-container .main-image .figure a:hover img, #Lodgings .additional-gallery-wrapper .gallery-container .small-images .figure a:hover img {
              transform: scale(1.15); }
      #Lodgings .additional-gallery-wrapper .gallery-container .main-image {
        margin-right: 0.2941176471em; }
      #Lodgings .additional-gallery-wrapper .gallery-container .small-images {
        margin-top: -0.1764705882em;
        margin-bottom: -0.1764705882em; }
        #Lodgings .additional-gallery-wrapper .gallery-container .small-images .figure {
          border-top: solid 0.1764705882em transparent;
          border-bottom: solid 0.1764705882em transparent;
          height: 33.3333333333%; }
    #Lodgings .additional-gallery-wrapper .lodging .lemma {
      font-size: 2.6470588235em; }
    #Lodgings .additional-gallery-wrapper .lodging .wrapper_content:before {
      top: -30%; }
    @media (max-width: 1199px) {
      #Lodgings .additional-gallery-wrapper {
        padding: 2.3529411765em 0; }
        #Lodgings .additional-gallery-wrapper .lodging .lemma {
          font-size: 2em; }
        #Lodgings .additional-gallery-wrapper .lodging .wrapper_content {
          padding: 1.7647058824em; }
          #Lodgings .additional-gallery-wrapper .lodging .wrapper_content:before {
            top: -20%; }
          #Lodgings .additional-gallery-wrapper .lodging .wrapper_content .content_text {
            line-height: 1.6; } }

.wrapper_location {
  padding-right: 0; }
  @media (max-width: 991px) {
    .wrapper_location {
      padding-left: 0; } }
  .wrapper_location #ItemsLocation {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    border: solid 20px rgba(255, 255, 255, 0.8);
    background: rgba(255, 255, 255, 0.8);
    padding: 6px;
    background-clip: content-box; }
    .wrapper_location #ItemsLocation .wrapper_items {
      padding: 0 1.7647058824em; }
      .wrapper_location #ItemsLocation .wrapper_items .box-item {
        font-family: 'Ebrima', sans-serif;
        margin-bottom: 1.7647058824em; }
        .wrapper_location #ItemsLocation .wrapper_items .box-item .wrapper-icon {
          width: 20%; }
          .wrapper_location #ItemsLocation .wrapper_items .box-item .wrapper-icon .icon {
            color: #003F7F;
            width: 6em;
            height: 6em;
            margin: 0;
            border-radius: 100%;
            border: 1px solid;
            display: flex;
            justify-content: center;
            align-items: center; }
            .wrapper_location #ItemsLocation .wrapper_items .box-item .wrapper-icon .icon:before {
              font-size: 2.4705882353em; }
            .wrapper_location #ItemsLocation .wrapper_items .box-item .wrapper-icon .icon.icon-plane:before {
              font-size: 1.7647058824em; }
        .wrapper_location #ItemsLocation .wrapper_items .box-item .wrapper-content {
          width: 80%; }
          .wrapper_location #ItemsLocation .wrapper_items .box-item .wrapper-content p {
            font-size: 0.8823529412em; }
          .wrapper_location #ItemsLocation .wrapper_items .box-item .wrapper-content .title {
            color: #5C84B0;
            font-size: 1.0588235294em; }
        @media (min-width: 992px) {
          .wrapper_location #ItemsLocation .wrapper_items .box-item .wrapper-icon {
            width: 30%; }
          .wrapper_location #ItemsLocation .wrapper_items .box-item .wrapper-content {
            width: 70%; } }

#ArrangerMap {
  margin-top: 1.7647058824em; }
  #ArrangerMap .wrapper_map {
    padding: 0; }
    @media (max-width: 991px) {
      #ArrangerMap .wrapper_map {
        height: 40vh;
        margin-bottom: 1.7647058824em; } }
  #ArrangerMap.animated-element .wrapper_map, #ArrangerMap.animated-element .wrapper_location {
    opacity: 0; }
  #ArrangerMap.animated-element.has-animation .wrapper_map {
    animation: slide-right 1 both;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-duration: 1.5s; }
  #ArrangerMap.animated-element.has-animation .wrapper_location {
    animation: slide-left 1 both;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-duration: 1.5s; }

.google_map {
  position: relative;
  z-index: 1;
  height: 100%;
  flex: 1 0 auto; }

#baloon {
  width: 15.2941176471em; }
  #baloon img {
    display: table !important;
    margin: auto auto 0.3970588235em !important; }
  #baloon p {
    line-height: 1;
    text-align: center;
    margin-bottom: 0.7941176471em; }
    #baloon p strong {
      display: block;
      margin-top: 0.7941176471em;
      text-transform: uppercase;
      font-size: 1.3350151974em;
      padding-bottom: 0.3970588235em; }
    #baloon p span:first-of-type:after {
      content: "\A";
      white-space: pre; }

#ArrangerReviews {
  margin-bottom: 1.7647058824em; }
  #ArrangerReviews .wrapper-gallery {
    padding-right: 0; }
    @media (max-width: 991px) {
      #ArrangerReviews .wrapper-gallery {
        padding-left: 0; } }
  #ArrangerReviews.animated-element #Reviews, #ArrangerReviews.animated-element #Reviews2, #ArrangerReviews.animated-element .wrapper-gallery {
    opacity: 0; }
  #ArrangerReviews.animated-element.has-animation #Reviews {
    animation: slide-right 1 both;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-duration: 1.5s;
    animation-delay: 0.5s; }
  #ArrangerReviews.animated-element.has-animation #Reviews2, #ArrangerReviews.animated-element.has-animation .wrapper-gallery {
    animation: slide-left 1 both;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-duration: 1.5s;
    animation-delay: 0.5s; }

@media (max-width: 991px) {
  #Reviews, #Reviews2 {
    margin-bottom: 1.7647058824em; } }

#Reviews #CDSWIDSSP, #Reviews2 #CDSWIDSSP {
  width: 100% !important;
  padding: 3px;
  background: white;
  border: 3px solid #00AF87;
  margin-bottom: 1.4705882353em; }
  #Reviews #CDSWIDSSP .widSSPData, #Reviews2 #CDSWIDSSP .widSSPData {
    padding: 1.1764705882em !important;
    min-height: 382px; }

#Reviews .TA_cdswritereviewlg, #Reviews2 .TA_cdswritereviewlg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -moz-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  min-height: 25vh; }
  #Reviews .TA_cdswritereviewlg .widSSPSummary a, #Reviews .TA_cdswritereviewlg .widWRLLink a, #Reviews2 .TA_cdswritereviewlg .widSSPSummary a, #Reviews2 .TA_cdswritereviewlg .widWRLLink a {
    text-decoration: none; }
  #Reviews .TA_cdswritereviewlg > ul, #Reviews2 .TA_cdswritereviewlg > ul {
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0; }
  #Reviews .TA_cdswritereviewlg .widWRL, #Reviews .TA_cdswritereviewlg .widWRLData, #Reviews2 .TA_cdswritereviewlg .widWRL, #Reviews2 .TA_cdswritereviewlg .widWRLData {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  #Reviews .TA_cdswritereviewlg .widWRL, #Reviews2 .TA_cdswritereviewlg .widWRL {
    width: 100% !important;
    max-width: none !important;
    background: white;
    border: 3px solid #00AF87;
    padding: 3px; }
  #Reviews .TA_cdswritereviewlg .widWRLData, #Reviews2 .TA_cdswritereviewlg .widWRLData {
    padding: 1.1764705882em !important;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    width: auto;
    height: auto;
    min-width: 0 !important;
    min-height: 0 !important;
    max-width: none !important;
    max-height: none !important; }
    #Reviews .TA_cdswritereviewlg .widWRLData form, #Reviews2 .TA_cdswritereviewlg .widWRLData form {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-direction: normal;
      -webkit-box-orient: vertical;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-flex: 1;
      -webkit-flex: 1 0 auto;
      -moz-box-flex: 1;
      -moz-flex: 1 0 auto;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      width: auto;
      height: auto;
      min-width: 0 !important;
      min-height: 0 !important;
      max-width: none !important;
      max-height: none !important; }
      #Reviews .TA_cdswritereviewlg .widWRLData form .widWRLReview, #Reviews2 .TA_cdswritereviewlg .widWRLData form .widWRLReview {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 auto;
        -moz-box-flex: 1;
        -moz-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        width: auto;
        height: auto;
        min-width: 0 !important;
        min-height: 0 !important;
        max-width: none !important;
        max-height: none !important; }
        #Reviews .TA_cdswritereviewlg .widWRLData form .widWRLReview textarea, #Reviews2 .TA_cdswritereviewlg .widWRLData form .widWRLReview textarea {
          -webkit-box-flex: 1;
          -webkit-flex: 1 0 auto;
          -moz-box-flex: 1;
          -moz-flex: 1 0 auto;
          -ms-flex: 1 0 auto;
          flex: 1 0 auto;
          width: auto;
          height: auto;
          min-width: 0 !important;
          min-height: 0 !important;
          max-width: none !important;
          max-height: none !important; }

#GuestbookMessages {
  position: relative; }
  #GuestbookMessages .wrapper_pagination {
    margin-left: auto;
    margin-right: auto; }
    .no-flexbox.no-flexboxtweener.no-flexboxlegacy #GuestbookMessages .wrapper_pagination:before, .no-flexbox.no-flexboxtweener.no-flexboxlegacy #GuestbookMessages .wrapper_pagination:after, .lt-ie9 #GuestbookMessages .wrapper_pagination:before, .lt-ie9 #GuestbookMessages .wrapper_pagination:after, .ie9 #GuestbookMessages .wrapper_pagination:before, .ie9 #GuestbookMessages .wrapper_pagination:after {
      content: " ";
      display: table; }
    .no-flexbox.no-flexboxtweener.no-flexboxlegacy #GuestbookMessages .wrapper_pagination:after, .lt-ie9 #GuestbookMessages .wrapper_pagination:after, .ie9 #GuestbookMessages .wrapper_pagination:after {
      clear: both; }

.message-guestbook {
  position: relative;
  z-index: 1;
  margin-bottom: 0.8823529412em;
  margin-top: 0.8823529412em;
  padding-top: 0.8823529412em;
  border-top: double 4px #dbdbdb; }
  .message-guestbook.reverse .has-image {
    padding-left: 1.1764705882em; }
  .message-guestbook.normal .has-image {
    padding-right: 1.1764705882em; }
  .message-guestbook .wrapper {
    position: relative;
    max-width: 100%; }
    .message-guestbook .wrapper > .post-info {
      max-width: 100%;
      margin-bottom: 1em; }
    .message-guestbook .wrapper.has-image {
      width: -moz-calc(100% - 17.6470588235em);
      width: -webkit-calc(100% - 17.6470588235em);
      width: calc(100% - 17.6470588235em); }
  .message-guestbook .photo-block {
    width: 17.6470588235em;
    position: relative;
    border: solid white 0.2352941176em;
    max-height: 23.5294117647em; }
    .message-guestbook .photo-block a {
      display: block;
      width: 100%;
      overflow: hidden; }
    .message-guestbook .photo-block img {
      width: 100%;
      height: 11.7647058824em;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover;";
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none; }
  .message-guestbook .post-info {
    color: #54ff9f;
    position: relative;
    font-size: 1.2941176471em;
    font-weight: bold;
    line-height: 1; }
    .message-guestbook .post-info > small {
      font-size: 0.85em;
      color: #5C84B0;
      position: relative; }
    .message-guestbook .post-info .post-author {
      position: relative;
      display: block; }
  .message-guestbook .description {
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0; }
  .message-guestbook .reply {
    width: 100%;
    background: white;
    font-size: 1.25em;
    border-color: #5C84B0;
    margin-bottom: 0;
    margin-top: 1em; }
    .message-guestbook .reply .author-reply {
      color: #5C84B0;
      font-style: italic; }
    .message-guestbook .reply .author-reply {
      font-style: italic; }
    .message-guestbook .reply .staff {
      font-variant: small-caps;
      font-style: italic; }

.guestbook-form-wrapper {
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  height: 100%; }

#FormGuestbook {
  margin-top: 1.5882352941em;
  width: 100%; }
  #FormGuestbook .file-input-wrapper {
    color: #555555;
    background-color: white;
    border-color: #e6e5e5; }
    #FormGuestbook .file-input-wrapper:hover, #FormGuestbook .file-input-wrapper:focus, #FormGuestbook .file-input-wrapper:active, #FormGuestbook .file-input-wrapper.active,
    .open > #FormGuestbook .file-input-wrapper.dropdown-toggle {
      color: #555555;
      background-color: white;
      border-color: #5C84B0; }
    #FormGuestbook .file-input-wrapper:active, #FormGuestbook .file-input-wrapper.active,
    .open > #FormGuestbook .file-input-wrapper.dropdown-toggle {
      background-image: none; }
    #FormGuestbook .file-input-wrapper.disabled, #FormGuestbook .file-input-wrapper.disabled:hover, #FormGuestbook .file-input-wrapper.disabled:focus, #FormGuestbook .file-input-wrapper.disabled:active, #FormGuestbook .file-input-wrapper.disabled.active, #FormGuestbook .file-input-wrapper[disabled], #FormGuestbook .file-input-wrapper[disabled]:hover, #FormGuestbook .file-input-wrapper[disabled]:focus, #FormGuestbook .file-input-wrapper[disabled]:active, #FormGuestbook .file-input-wrapper[disabled].active,
    fieldset[disabled] #FormGuestbook .file-input-wrapper,
    fieldset[disabled] #FormGuestbook .file-input-wrapper:hover,
    fieldset[disabled] #FormGuestbook .file-input-wrapper:focus,
    fieldset[disabled] #FormGuestbook .file-input-wrapper:active,
    fieldset[disabled] #FormGuestbook .file-input-wrapper.active {
      background-color: white;
      border-color: #e6e5e5; }
    #FormGuestbook .file-input-wrapper .badge {
      color: white;
      background-color: #555555; }
  #FormGuestbook .text-telephone {
    display: none; }

#LeaveReview {
  background-color: #a9a9a9;
  box-shadow: 0px -10px 20px -10px rgba(38, 38, 38, 0.5), inset 0px -10px 30px -10px rgba(38, 38, 38, 0.5);
  padding-bottom: 1.7647058824em; }

#BookingFragments {
  position: relative;
  margin-top: 3.5294117647em;
  padding-bottom: 3.5294117647em; }
  #BookingFragments.animated-element .booking-online, #BookingFragments.animated-element .phone-booking, #BookingFragments.animated-element .availability-booking {
    opacity: 0; }
  #BookingFragments.animated-element.has-animation .booking-online {
    animation: slide-right 1 both;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-duration: 1.5s; }
  #BookingFragments.animated-element.has-animation .phone-booking {
    animation: slide-left 1 both;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-duration: 1.5s; }
  #BookingFragments.animated-element.has-animation .availability-booking {
    animation: slide-top 1 both;
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-duration: 1.5s; }
  #BookingFragments:after {
    content: "";
    height: 2px;
    width: calc(100% - 30px);
    background-color: #DDDDDD;
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 0; }
  #BookingFragments .wrapper_box {
    margin: 0 -0.2941176471em; }
  #BookingFragments .booking-online, #BookingFragments .phone-booking, #BookingFragments .availability-booking {
    position: relative;
    display: flex;
    padding: 0 0.2941176471em;
    font-size: 18px; }
    @media (max-width: 991px) {
      #BookingFragments .booking-online, #BookingFragments .phone-booking, #BookingFragments .availability-booking {
        min-width: 100%; } }
    #BookingFragments .booking-online:before, #BookingFragments .phone-booking:before, #BookingFragments .availability-booking:before {
      content: "";
      border: 2px solid #5C84B0;
      position: absolute;
      top: 0.5882352941em;
      bottom: 0.5882352941em;
      left: 0.8823529412em;
      right: 0.8823529412em;
      pointer-events: none; }
    #BookingFragments .booking-online .wrapper, #BookingFragments .phone-booking .wrapper, #BookingFragments .availability-booking .wrapper {
      flex: 1 1 auto;
      padding: 2.3529411765em; }
      @media (max-width: 1199px) {
        #BookingFragments .booking-online .wrapper, #BookingFragments .phone-booking .wrapper, #BookingFragments .availability-booking .wrapper {
          font-size: 0.8405651243em; } }
    #BookingFragments .booking-online .icon, #BookingFragments .phone-booking .icon, #BookingFragments .availability-booking .icon {
      position: relative;
      display: block;
      font-size: 2em; }
      #BookingFragments .booking-online .icon .svg-embed, #BookingFragments .phone-booking .icon .svg-embed, #BookingFragments .availability-booking .icon .svg-embed {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1; }
  @media (max-width: 991px) {
    #BookingFragments .booking-online, #BookingFragments .availability-booking {
      margin-bottom: 0.8823529412em; } }
  #BookingFragments .booking-online .wrapper, #BookingFragments .availability-booking .wrapper {
    background-color: #003F7F;
    color: white; }
    #BookingFragments .booking-online .wrapper h3, #BookingFragments .booking-online .wrapper h2, #BookingFragments .availability-booking .wrapper h3, #BookingFragments .availability-booking .wrapper h2 {
      color: white; }
    #BookingFragments .booking-online .wrapper .btn, #BookingFragments .availability-booking .wrapper .btn {
      margin: 1.1764705882em 0;
      padding: 0.5882352941em 3.5294117647em; }
    #BookingFragments .booking-online .wrapper #Cards span, #BookingFragments .availability-booking .wrapper #Cards span {
      display: block;
      width: 2.6470588235em;
      height: 1.8823529412em;
      margin-left: 1em; }
      #BookingFragments .booking-online .wrapper #Cards span img, #BookingFragments .availability-booking .wrapper #Cards span img {
        width: 100%;
        height: auto; }
        #BookingFragments .booking-online .wrapper #Cards span img.ssl, #BookingFragments .availability-booking .wrapper #Cards span img.ssl {
          height: 2.5em; }
  #BookingFragments .availability-booking {
    margin-top: 0.8823529412em;
    background-color: #0398CE; }
    #BookingFragments .availability-booking .wrapper {
      background-color: #0398CE; }
    #BookingFragments .availability-booking:before {
      border-color: #003F7F; }
  #BookingFragments .phone-booking:before {
    border-color: white; }
  #BookingFragments .phone-booking .wrapper {
    background-color: #0398CE;
    color: #FFFFFF; }
    #BookingFragments .phone-booking .wrapper h2 {
      color: #FFFFFF; }
    #BookingFragments .phone-booking .wrapper .phone_number {
      font-family: 'QuattrocentoSans-Regular', sans-serif;
      font-size: 30.2985148151px; }

#ContactModule {
  margin-top: 3.5294117647em;
  margin-bottom: 3.5294117647em; }
  #ContactModule .icon {
    text-align: center;
    font-size: 2.0588235294em;
    color: #003F7F; }
  #ContactModule h2 {
    margin-bottom: 0; }
  #ContactModule h3 {
    margin-bottom: 0.990147543em;
    font-weight: 700; }
  #ContactModule .form-wrapper {
    margin-top: 1.7647058824em; }

.micromodal-slide .modal__container {
  background-color: #003F7F; }
  .micromodal-slide .modal__container #sb-modal-1-title {
    color: white !important; }
  .micromodal-slide .modal__container .modal__close {
    color: white;
    outline: none; }

#RestaurantBoxes {
  margin: 4.7058823529em 0 1.7647058824em 0; }
  @media (max-width: 1199px) {
    #RestaurantBoxes {
      margin-top: 2.3529411765em; } }
  #RestaurantBoxes .additional-gallery-wrapper {
    position: relative;
    padding-bottom: 1.7647058824em;
    z-index: 1; }
    #RestaurantBoxes .additional-gallery-wrapper.animated-element.has-animation .lodging {
      animation: foldingRight 1 both;
      animation-delay: 0.5s;
      animation-timing-function: cubic-bezier(0.075, 0.820, 0.165, 1.000);
      animation-duration: 1.5s; }
    #RestaurantBoxes .additional-gallery-wrapper > .container {
      position: relative; }
    #RestaurantBoxes .additional-gallery-wrapper .lodging {
      position: relative; }
      #RestaurantBoxes .additional-gallery-wrapper .lodging .wrapper_content {
        position: relative;
        background-color: #003F7F;
        color: #FFFFFF;
        padding: 5.8823529412em 2.9411764706em; }
        #RestaurantBoxes .additional-gallery-wrapper .lodging .wrapper_content .content_text {
          line-height: 1.6;
          font-size: 1.0588235294em; }
    #RestaurantBoxes .additional-gallery-wrapper .gallery-container {
      position: relative;
      margin-bottom: 0; }
      #RestaurantBoxes .additional-gallery-wrapper .gallery-container .main-image, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images {
        padding: 0; }
        #RestaurantBoxes .additional-gallery-wrapper .gallery-container .main-image .figure, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images .figure {
          position: relative;
          overflow: hidden;
          -webkit-box-flex: 1;
          -webkit-flex: 1 0 auto;
          -moz-box-flex: 1;
          -moz-flex: 1 0 auto;
          -ms-flex: 1 0 auto;
          flex: 1 0 auto;
          margin-bottom: 0; }
          #RestaurantBoxes .additional-gallery-wrapper .gallery-container .main-image .figure a, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images .figure a {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            top: 0; }
            #RestaurantBoxes .additional-gallery-wrapper .gallery-container .main-image .figure a img, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images .figure a img {
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
              font-family: "object-fit: cover;";
              -webkit-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -moz-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -o-transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
              transition: all 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -webkit-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -moz-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -ms-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -o-transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
              transition-delay: cubic-bezier(0.230, 1.000, 0.320, 1.000);
              -webkit-transform-origin: 25% 25%;
              -moz-transform-origin: 25% 25%;
              -ms-transform-origin: 25% 25%;
              transform-origin: 25% 25%; }
            #RestaurantBoxes .additional-gallery-wrapper .gallery-container .main-image .figure a .additional, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images .figure a .additional {
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
              background: rgba(0, 0, 0, 0.5);
              color: white;
              z-index: 1; }
              #RestaurantBoxes .additional-gallery-wrapper .gallery-container .main-image .figure a .additional span, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images .figure a .additional span {
                display: -webkit-box;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                -moz-align-items: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                -moz-justify-content: center;
                justify-content: center;
                font-size: 40.4489777376px;
                line-height: 1;
                text-shadow: 1px 1px 0 black;
                height: inherit; }
            #RestaurantBoxes .additional-gallery-wrapper .gallery-container .main-image .figure a:hover img, #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images .figure a:hover img {
              transform: scale(1.15); }
      #RestaurantBoxes .additional-gallery-wrapper .gallery-container .main-image {
        margin-right: 0.2941176471em; }
      #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images {
        margin-top: -0.1764705882em;
        margin-bottom: -0.1764705882em; }
        #RestaurantBoxes .additional-gallery-wrapper .gallery-container .small-images .figure {
          border-top: solid 0.1764705882em transparent;
          border-bottom: solid 0.1764705882em transparent;
          height: 33.3333333333%; }
    @media (max-width: 1199px) {
      #RestaurantBoxes .additional-gallery-wrapper {
        padding: 2.3529411765em 0; }
        #RestaurantBoxes .additional-gallery-wrapper .lodging .wrapper_content {
          padding: 1.7647058824em; }
          #RestaurantBoxes .additional-gallery-wrapper .lodging .wrapper_content .content_text {
            line-height: 1.6; } }

/*# sourceMappingURL=default.css.map */